import { Delete, Edit } from '@mui/icons-material';
import { Box, Chip, IconButton, Link } from '@mui/material';
import { canUserCreate, canUserDelete } from '../../utils/rights';
import { Restriction } from '../../enums/RouteEnums';
import { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import React from 'react';
import { IUser } from '../../models/user';
import CustomTable, { IHeadCell } from '../Common/Tables/CustomTable';
import Header from '../Common/Layout/Header';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  deleteUserMutation,
  deleteUserRoleMutation,
  getCompanyUsersQuery
} from '../../queries/user';
import { handleRequestError } from '../../utils/ui';
import Loader from '../Common/Global/Loader';
import { Actions } from '../../enums/ActionEnums';
import UserForm from './UserForm';
import { colors } from '../../utils/theme';

interface IProps {}

const UsersOverview: React.FunctionComponent<IProps> = ({}: IProps) => {
  const { state, dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const queryKey = ['users'];
  const { isLoading, data } = useQuery(queryKey, getCompanyUsersQuery, {
    onSuccess: ({ data }) => {
      setUsers(data);
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    }
  });
  const [users, setUsers] = useState<IUser[]>(data?.data || []);
  const [openForm, setOpenForm] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);

  const deleteUser = useMutation(deleteUserMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey),
    onSuccess: () => {
      dispatch({
        type: Actions.HideConfirmation
      });
    }
  });

  const deleteUserRole = useMutation(deleteUserRoleMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey),
    onSuccess: () => {
      dispatch({
        type: Actions.HideConfirmation
      });
    }
  });

  const handleRemove = (rowData: IUser) => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `Are you sure you want to delete ${rowData.firstName} ${rowData.lastName} user?`,
        agreeAction: handleDelete,
        params: rowData
      }
    });
  };

  const handleRemoveUserRole = (rowData: IUser, role: string) => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `Are you sure you want to delete role ${role} for user ${rowData.firstName} ${rowData.lastName} ?`,
        agreeAction: handleDeleteUserRole,
        params: [rowData, role]
      }
    });
  };

  const handleDelete = (rowData: IUser) => {
    if (!rowData.id) {
      return;
    }
    deleteUser.mutate(rowData.id);
  };

  const handleDeleteUserRole = (params: any[]) => {
    const rowData = params[0] as IUser;
    const role = params[1];
    if (!rowData.id) {
      return;
    }
    const id = rowData.id;
    deleteUserRole.mutate({ id, role });
  };

  const handleEdit = (rowData: IUser) => {
    setUser(rowData);
    setOpenForm(true);
  };

  const handleClose = () => {
    setUser(null);
    setOpenForm(false);
  };

  const customActions = ({ rowData }: { rowData: any }) => {
    const iconButtonProps = {
      height: '48px',
      width: '48px',
      marginRight: '16px'
    };
    return canUserDelete(state.user, Restriction.User) ? (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <IconButton onClick={() => handleEdit(rowData)} sx={{ ...iconButtonProps, marginRight: 0 }}>
          <Edit sx={{ color: 'error.main' }} />
        </IconButton>
        <IconButton
          onClick={() => {
            handleRemove(rowData);
          }}
          sx={{ ...iconButtonProps, marginRight: 0 }}
        >
          <Delete sx={{ color: 'error.main' }} />
        </IconButton>
      </Box>
    ) : null;
  };

  const renderUserRoles = (rowData: IUser) => {
    return (
      <Box sx={{ display: 'flex' }}>
        {rowData.roles.map((role, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', mr: '10px' }}>
            <Chip
              id={role}
              label={role.toString()}
              onDelete={() => {
                handleRemoveUserRole(rowData, role);
              }}
              sx={{
                '.MuiChip-deleteIcon': { color: '#ec1a58' },
                '.MuiChip-label': {
                  color: colors.gradients.pinkRgb
                },
                background: colors.pink.secondary
              }}
            />
          </Box>
        ))}
      </Box>
    );
  };

  const userColumns = [
    {
      title: 'First Name',
      field: 'firstName',
      render: (data: IUser) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {data.firstName}
        </Link>
      )
    },
    {
      title: 'Last Name',
      field: 'lastName',
      render: (data: IUser) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {data.lastName}
        </Link>
      )
    },
    {
      title: 'Email',
      field: 'email',
      render: (data: IUser) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {data.email}
        </Link>
      )
    },
    {
      title: 'Roles',
      field: 'roles',
      render: (rowData: IUser) => renderUserRoles(rowData)
    }
  ] as IHeadCell[];

  return (
    <Box>
      <Box sx={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
        <Box>
          <Header text="Users" />
          <Header text="Add or edit user" isSubHeader={true} />
        </Box>
      </Box>
      <CustomTable
        toolbarTitle={'Users'}
        data={users}
        columns={userColumns}
        showSearch
        Actions={customActions}
        {...(canUserCreate(state.user, Restriction.User) && {
          createButtonProps: {
            id: 'add-user-button',
            onClick: () => setOpenForm(true),
            text: '+ Create User',
            disabled: false
          }
        })}
      />
      {openForm && <UserForm userInput={user} onClose={() => handleClose()} />}
      <Loader loading={isLoading || deleteUser.isLoading} />
    </Box>
  );
};

export default UsersOverview;

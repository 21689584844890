import React, { forwardRef, useContext, useEffect, useState } from 'react';
import { Box, Button, Divider, IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import './index.css';
import FilterCardAttribute from './FilterCardAttribute';
import { Edit, Delete } from '@mui/icons-material';
import { colors } from '../../../utils/theme';
import { SegmentCriteria } from '../../../models/segmets';
import { IFilterCard } from '../../../models/card';
import FilterCardForm from './FilterCardForm';
import { DeleteFilterCardMutation } from '../../../queries/filterCard';
import { getBrandId, handleRequestError } from '../../../utils/ui';
import { AppContext } from '../../../AppContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { Actions } from '../../../enums/ActionEnums';

interface IProps {
  id: number;
  name: string;
  color: string;
  assignedUserIds: string[] | null;
  criterias: SegmentCriteria[];
  onFilter: (criterias: SegmentCriteria[]) => void;
}

const FilterCard: React.FunctionComponent<IProps> = forwardRef(function FilterCard(props, ref) {
  const { id, name, color, assignedUserIds, criterias, onFilter } = props;
  const { dispatch, state } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const queryKey = [QueryKey.FilterCards, state.user.data?.email, brandId];

  const queryClient = useQueryClient();
  const [selected, setSelected] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [filterCardData, setFilterCardData] = useState<IFilterCard | null>(null);

  useEffect(() => {
    if (selected) {
      onFilter(criterias);
    } else {
      onFilter([]);
    }
  }, [selected]);

  const handleEdit = () => {
    setFilterCardData({ id, name, color, assignedUserIds, criterias });
    setOpenForm(true);
  };

  const handleClose = () => {
    setFilterCardData(null);
    setOpenForm(false);
  };

  const deleteFilterCard = useMutation(DeleteFilterCardMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey),
    onSuccess: () => {
      dispatch({
        type: Actions.HideConfirmation
      });
    }
  });

  const handleRemove = () => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `You are about to delete filter ${name}. Once a filter is deleted, it can't be restored. Are you sure you want to continue?`,
        agreeAction: handleDelete
      }
    });
  };

  const handleDelete = () => {
    deleteFilterCard.mutate(id as number);
  };

  const handleSelected = () => {
    setSelected(!selected);
  };

  return (
    <Box
      {...props}
      id={props.id.toString()}
      ref={ref}
      className={`filter-card filled-card ${selected ? 'filled-card-selected' : ''}`}
      sx={{ flexDirection: 'column', backgroundColor: 'white' }}
    >
      <Box
        className="filter-card-header"
        sx={{
          background: color
        }}
      >
        <Box sx={{ fontWeight: 600, fontSize: '16px' }}>{name}</Box>
        {/* <IconButton
          onClick={() => alert('refreshed')}
          sx={{ color: 'white', width: '28px', height: '28px' }}
        >
          <RefreshIcon />
        </IconButton> */}
      </Box>
      <Box
        onClick={handleSelected}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: '12px',
          gap: '12px',
          cursor: 'pointer'
        }}
      >
        {criterias.map((criteria, index) => (
          <FilterCardAttribute
            key={`${criteria.name}-${index}`}
            name={criteria.name}
            value={criteria.value}
            operator={criteria.operator}
          />
        ))}
      </Box>
      <Divider sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          display: 'flex',
          mt: '8px',
          mb: '8px',
          px: '5px',
          justifyContent: 'space-between'
        }}
      >
        <Button
          variant="text"
          sx={{ height: '24px', color: colors.gradients.pinkRgb }}
          onClick={handleEdit}
        >
          <Edit sx={{ width: '16px', height: '16px', mr: '5px' }} />
          Edit
        </Button>
        <Button
          variant="text"
          sx={{ height: '24px', color: colors.gradients.pinkRgb }}
          onClick={handleRemove}
        >
          <Delete sx={{ width: '16px', height: '16px', mr: '5px' }} />
          Delete
        </Button>
      </Box>
      {openForm && <FilterCardForm cardInput={filterCardData} onClose={handleClose} />}
    </Box>
  );
});

export default FilterCard;

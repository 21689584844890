import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, InputLabel } from '@mui/material';
import { getBorderColor, getBorderColorOnHover } from '../../../utils/ui';
import dayjs from 'dayjs';

interface IProps {
  id: string;
  label: string;
  value: Date | null;
  minDate?: Date;
  maxDate?: Date;
  onChange: (value: Date | null) => void;
}

const DateComponent: React.FunctionComponent<IProps> = ({
  id,
  label,
  value,
  onChange,
  minDate,
  maxDate
}: IProps) => {
  const handleDateChange = (input: Date | null) => {
    const date = input ? dayjs(input).startOf('date').toDate() : input;
    onChange(date);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputLabel htmlFor={id} sx={{ marginBottom: '4px' }}>
          {label}
        </InputLabel>
        <DatePicker
          format="YYYY/MM/DD"
          value={dayjs(value) as unknown as Date}
          {...(minDate && { minDate: dayjs(minDate) as unknown as Date })}
          {...(maxDate && { maxDate: dayjs(maxDate) as unknown as Date })}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              variant: 'outlined',
              sx: {
                svg: { color: 'info.main' },
                height: '46px',
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  height: '46px',
                  '& fieldset': {
                    borderColor: getBorderColor(value)
                  },
                  '&:hover fieldset': {
                    borderColor: getBorderColorOnHover(null)
                  }
                }
              }
            }
          }}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateComponent;

import { AppConsts, Role } from '../enums/AppConsts';
import { Restriction } from '../enums/RouteEnums';
import { UserType } from '../models/appContext';

type optionTypes = {
  [key: string]: {
    create: string[];
    read?: string[];
    update: string[];
    delete: string[];
  };
};

const crudRights: optionTypes = {
  Brand: {
    create: [Role.Owner],
    update: [Role.Owner],
    delete: [Role.Owner]
  },
  User: {
    create: [Role.Owner],
    read: [Role.Owner],
    update: [Role.Owner],
    delete: [Role.Owner]
  },
  Lead: {
    create: [Role.MarketingLead],
    read: [Role.MarketingLead],
    update: [Role.MarketingLead],
    delete: [Role.MarketingLead]
  }
};

const restrictedTypes = Object.values(Restriction);

const canPerformAction = (userRoles: string[], allowedRoles: string[]): boolean => {
  const result =
    allowedRoles.length === 0 ? true : userRoles?.some((r) => allowedRoles.includes(r));
  return result ?? false;
};

export const canUserDelete = (user: UserType, item: string | undefined): boolean => {
  if (item && restrictedTypes.includes(item as Restriction)) {
    const allowedRoles = crudRights[item]?.delete || [];
    return canPerformAction(user.data?.roles as string[], allowedRoles);
  }
  return true;
};

export const canUserCreate = (user: UserType, item: string): boolean => {
  const allowedRoles = crudRights[item]?.create || [];
  return canPerformAction(user.data?.roles as string[], allowedRoles);
};

export const canUserRead = (user: UserType, item: string): boolean => {
  const allowedRoles = crudRights[item]?.read || [];
  return canPerformAction(user.data?.roles as string[], allowedRoles);
};

export const canUserUpdate = (user: UserType, item: string): boolean => {
  const allowedRoles = crudRights[item]?.update || [];
  return canPerformAction(user.data?.roles as string[], allowedRoles);
};

export const baseUrl = process.env.BASE_SERVER_ADDRESS;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);

export const getHttpHeaders = () => ({
  headers: { Authorization: `Bearer ${getToken()}` }
});

import { Actions } from '../enums/ActionEnums';
import { ActionType } from '../models/appContext';

export const brandReducer = (state: string, action: ActionType): string => {
  switch (action.type) {
    case Actions.SetSelectedBrand:
      return action.payload.brand;
    default:
      return state;
  }
};

import axios from 'axios';
import { baseUrl, getHttpHeaders } from '../utils/rights';
import {
  CreatePromotionGroup,
  IPromoStage,
  IPromotionGroup,
  PromoTestEmail,
  PromoTestSms,
  PromotionModel
} from '../models/promotion';

export const PromotionsGroupsQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/Promotion/GetGroups/${brandId}`, getHttpHeaders());
};

export const PromotionEmailOptionsQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/Promotion/GetEmailOptions/${brandId}`, getHttpHeaders());
};
export const TemplateDetailsQuery = async ({ brandId, id }: { brandId: number; id: string }) => {
  return await axios.get(`${baseUrl}/Promotion/GetTemplate/${brandId}/${id}`, getHttpHeaders());
};

export const SendPromoTestEmail = async (input: PromoTestEmail) => {
  return await axios.post(
    `${baseUrl}/promotion/SendEmailPreview/${input.brandId}`,
    input,
    getHttpHeaders()
  );
};

export const SendTestSms = async (input: PromoTestSms) => {
  return await axios.post(
    `${baseUrl}/promotion/SendSmsPreview/${input.brandId}`,
    input,
    getHttpHeaders()
  );
};

export const CreatePromotionGroupMutation = async (input: CreatePromotionGroup) => {
  return await axios.post(
    `${baseUrl}/promotion/createGroup/${input.brandId}`,
    { input: input.name },
    getHttpHeaders()
  );
};

export const UpdatePromotionGroupMutation = async (input: IPromotionGroup) => {
  return await axios.put(
    `${baseUrl}/promotion/updateGroup/${input.id}`,
    { input: input.name },
    getHttpHeaders()
  );
};

export const DeletePromotionGroupMutation = async (id: number) => {
  return await axios.delete(`${baseUrl}/promotion/deletegroup/${id}`, getHttpHeaders());
};

export const CreatePromo = async ({
  brandId,
  input
}: {
  brandId: number;
  input: PromotionModel;
}) => {
  return await axios.post(`${baseUrl}/promotion/create/${brandId}`, input, getHttpHeaders());
};

export const DeletePromotion = async (id: number) => {
  return await axios.delete(`${baseUrl}/promotion/delete/${id}`, getHttpHeaders());
};

export const UpdatePromotion = async ({ id, input }: { id: number; input: PromotionModel }) => {
  return await axios.put(`${baseUrl}/promotion/update/${id}`, input, getHttpHeaders());
};

export const UpdatePromotionStage = async (input: IPromoStage) => {
  return await axios.put(`${baseUrl}/promotion/stageupdate/${input.id}`, input, getHttpHeaders());
};

import React, { useContext, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccountDetails from '../Account/AccountDetails/AccountDetails';
import BrandsOverview from '../Brand/BrandsOverview';
import { colors } from '../../utils/theme';
import TabPanel from '../Common/Layout/TabPanel';
import { AppContext } from '../../AppContext';
import { canUserRead } from '../../utils/rights';
import { Restriction } from '../../enums/RouteEnums';
import UsersOverview from '../User/UsersOverview';

export default function SettingsComponent() {
  const { state } = useContext(AppContext);
  const [value, setValue] = useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className="nav-page">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: colors.grey.thread
        }}
      >
        <Tabs
          sx={{
            '& .MuiTabs-indicator': {
              backgroundColor: colors.gradients.pinkRgb,
              height: 3
            },
            '& .MuiTab-root.Mui-selected': {
              color: colors.gradients.pinkRgb
            },
            '& .MuiTab-root[aria-selected=false]': {
              color: (t) => t.palette.grey[300]
            }
          }}
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Account" id="simple-tab-1" aria-controls="simple-tabpanel-1" />
          <Tab label="Brands" id="simple-tab-2" aria-controls="simple-tabpanel-2" />
          {canUserRead(state.user, Restriction.User) && (
            <Tab label="Users" id="simple-tab-3" aria-controls="simple-tabpanel-3" />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AccountDetails />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BrandsOverview />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UsersOverview />
      </TabPanel>
    </Box>
  );
}

import React from 'react';
import { Box } from '@mui/material';
import { JourneyStepType } from '../../../../enums/EntityEnums';
import { Groups, SpeakerPhone, Message } from '@mui/icons-material';
import './index.css';
import { colors } from '../../../../utils/theme';

interface IProps {
  type: JourneyStepType | null;
}

const StepIcon: React.FunctionComponent<IProps> = ({ type }: IProps) => {
  const getBackground = () => {
    switch (type) {
      case JourneyStepType.TargetAudience:
        return '#FDF3F8';
      case JourneyStepType.Wait:
        return '#F2F1F9';
      default:
        return '#EBEFFC';
    }
  };

  const getIcon = () => {
    switch (type) {
      case JourneyStepType.TargetAudience:
        return <Groups sx={{ color: colors.gradients.pinkRgb }} />;
      case JourneyStepType.Wait:
        return <SpeakerPhone sx={{ color: colors.primary.main }} />;
      default:
        return <Message sx={{ color: '#3364E1' }} />;
    }
  };

  return (
    <Box
      sx={{
        width: '35px',
        height: '35px',
        background: getBackground(),
        borderRadius: type === JourneyStepType.Wait ? '100px' : '10px'
      }}
      className="journey-step-icon"
    >
      {getIcon()}
    </Box>
  );
};

export default StepIcon;

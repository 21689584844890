import React from "react";
import { Box } from "@mui/material";

interface IProps {
  text: string;
  color: string | undefined;
  sx?: SxProps;
}

const ColoredText: React.FunctionComponent<IProps> = ({
  text,
  color,
  sx,
}: IProps) => {
  return (
    <>
      {text ? (
        <Box
          sx={{
            backgroundColor: color,
            borderRadius: "8px",
            color: "white",
            fontFamily: "Inter",
            fontStyle: "normal",
            ...sx,
          }}
        >
          {text}
        </Box>
      ) : (
        <Box></Box>
      )}
    </>
  );
};

export default ColoredText;

import { useContext, useEffect, useState } from 'react';
import { IUser } from '../../models/user';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../../AppContext';
import DialogBox from '../Common/Layout/DialogBox';
import Loader from '../Common/Global/Loader';
import { Box, FormControlLabel, InputLabel, Switch, Tooltip, alpha, styled } from '@mui/material';
import MainButton from '../Common/Buttons/MainButton';
import CancelButton from '../Common/Buttons/CancelButton';
import React from 'react';
import Header from '../Common/Layout/Header';
import InputComponent from '../Common/Fields/InputComponent';
import { colors } from '../../utils/theme';
import { IRole } from '../../enums/AppConsts';
import { createUserMutation, updateUserMutation } from '../../queries/user';
import { Actions } from '../../enums/ActionEnums';
import { handleRequestError } from '../../utils/ui';
import { Info } from '@mui/icons-material';

interface IProps {
  onClose: () => void;
  userInput: IUser | null;
}

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: colors.pink.main,
    '&:hover': {
      backgroundColor: alpha(colors.pink.main, theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colors.pink.main
  }
}));

export type IErrors = {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  roles: string[] | null;
};

const defaultErrorsObj: IErrors = {
  firstName: null,
  lastName: null,
  email: null,
  roles: null
};

const errorTexts = {
  firstName: 'First name too short!',
  lastName: 'Last name too short!',
  email: 'Email not valid'
};

const rolesData = Object.values(IRole);

const UserForm: React.FunctionComponent<IProps> = ({ onClose, userInput }: IProps) => {
  const [firstName, setFirstName] = useState(userInput?.firstName || '');
  const [lastName, setLastName] = useState(userInput?.lastName || '');
  const [email, setEmail] = useState(userInput?.email || '');
  const [roles, setRoles] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<{
    [key: string]: boolean;
  }>(() => {
    const initialRoles: { [key: string]: boolean } = userInput?.roles
      ? userInput.roles.reduce((obj, role) => {
          obj[role] = false;
          return obj;
        }, {})
      : [];
    rolesData.forEach((role: string) => {
      initialRoles[role] = userInput ? userInput?.roles?.indexOf(role) !== -1 : false;
    });
    return initialRoles;
  });
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const queryKey = ['users'];

  useEffect(() => {
    const newRoles = Object.keys(userRoles).filter((key) => userRoles[key]);
    setRoles(newRoles as string[]);
  }, [userRoles]);

  const createUser = useMutation(createUserMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `User ${data.firstName} ${data.lastName} created`
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey)
  });

  const updateUser = useMutation(updateUserMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `User ${data.firstName} ${data.lastName} updated`
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey)
  });

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      firstName: value && value.length > 0 ? null : errorTexts.firstName
    });
    setFirstName(value);
  };

  const handleLastNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      lastName: value && value.length > 0 ? null : errorTexts.lastName
    });
    setLastName(value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      email: value && value.length > 0 ? null : errorTexts.email
    });
    setEmail(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserRoles({
      ...userRoles,
      [event.target.name]: event.target.checked
    });
  };

  const handleSave = (type: 'create' | 'update') => {
    if (type === 'create') {
      createUser.mutate({ firstName, lastName, email, roles });
    } else {
      updateUser.mutate({
        id: userInput?.id as string,
        firstName,
        lastName,
        email,
        roles
      });
    }
  };

  const handleSubmit = () => {
    const trimmedFirstName = firstName ? firstName.trim() : '';
    const trimmedLastName = lastName ? lastName.trim() : '';
    const trimmedEmail = email ? email.trim() : '';
    const errors = {
      firstName: null,
      lastName: null,
      email: null
    } as IErrors;
    let hasErrors = false;
    if (trimmedFirstName.length === 0) {
      hasErrors = true;
      errors.firstName = errorTexts.firstName;
    }
    if (trimmedLastName.length === 0) {
      hasErrors = true;
      errors.lastName = errorTexts.lastName;
    }
    if (trimmedEmail.length === 0) {
      hasErrors = true;
      errors.email = errorTexts.email;
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      handleSave(userInput === null ? 'create' : 'update');
    }
  };

  return (
    <DialogBox width={800} onClose={onClose} title={userInput ? 'Edit User' : 'Create User'}>
      <Header text={userInput ? 'Edit User' : 'Create User'} isSubHeader={true} />
      <Box
        sx={{
          mb: '24px',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          gap: '20px'
        }}
      >
        <InputComponent
          id="first-name"
          label="First Name"
          errorMsg={formErrors.firstName}
          value={firstName}
          onChange={handleFirstNameChange}
          containerStyles={{ gridColumn: 'span 1' }}
        />
        <InputComponent
          id="last-name"
          label="Last Name"
          errorMsg={formErrors.lastName}
          value={lastName}
          onChange={handleLastNameChange}
          containerStyles={{ gridColumn: 'span 1' }}
        />
        <InputComponent
          id="email"
          label="Email"
          errorMsg={formErrors.email}
          value={email}
          onChange={handleEmailChange}
          containerStyles={{ gridColumn: 'span 2' }}
        />
        <Box sx={{ gridColumn: 'span 2' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <InputLabel>{'Roles'}</InputLabel>
            <Tooltip title="info text" placement="top">
              <Info />
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {rolesData.map((role, index) => (
            <Box key={index}>
              <FormControlLabel
                control={
                  <PinkSwitch
                    id={role}
                    checked={userRoles[role] || false}
                    onChange={handleChange}
                    name={role}
                  />
                }
                label={role}
                labelPlacement="end"
              />
            </Box>
          ))}
        </Box>
      </Box>
      <Box className="form-actions">
        <Box className="form-group-buttons">
          <CancelButton
            id="user-cancel"
            text="Cancel"
            onClick={onClose}
            sx={{ height: '36px', width: '48px' }}
          />
          <MainButton
            id="user-create"
            text={userInput ? 'Update' : 'Create'}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
      <Loader loading={createUser.isLoading || updateUser.isLoading} />
    </DialogBox>
  );
};

export default UserForm;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { skipTake } from '../../../utils/types';
import Paging from '../Other/Paging';
import { DeleteOutlineOutlined, EditOutlined } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DeletePlayerKanbanStage } from '../../../queries/players';
import { getBrandId, handleRequestError } from '../../../utils/ui';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { IKanbanStage } from '../../../models/global';
import { PlayerKanbanStage } from '../../../models/player';

interface KanbanCardProps {
  item: unknown;
  index: number;
}

interface IProps {
  column: {
    icon: React.ReactNode;
    title: string;
    items: unknown[];
    id: number;
    isDeletable: boolean;
    orderNumber: number;
  };
  renderCard: ({ item, index }: KanbanCardProps) => React.ReactNode;
  handleKanbanStageEdit: (kanbanStage: PlayerKanbanStage) => void;
}

const iconButtonProps = {
  height: '18px',
  width: '18px'
};

const KanbanStageItems: React.FunctionComponent<IProps> = ({
  column,
  renderCard,
  handleKanbanStageEdit
}: IProps) => {
  const itemsPerPage = 25;
  const scroll = useRef(null);
  const { items } = column;
  const pages = Math.ceil(items.length / itemsPerPage);
  const [page, setPage] = useState(1);
  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const queryClient = useQueryClient();
  const [pageItems, setPageItems] = useState(
    skipTake(items, (page - 1) * itemsPerPage, itemsPerPage)
  );

  const deleteStage = useMutation(DeletePlayerKanbanStage, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: 'Kanban stage deleted'
        }
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKey.KanbanStages, brandId]);
      queryClient.invalidateQueries([QueryKey.Players]);
    }
  });

  useEffect(() => {
    setPageItems(skipTake(items, (page - 1) * itemsPerPage, itemsPerPage));
  }, [items]);

  const handlePageChange = (_: unknown, value: number) => {
    setPage(value);
    setPageItems(skipTake(items, (value - 1) * itemsPerPage, itemsPerPage));
    if (scroll && scroll.current) {
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      scroll.current.scrollTop = 0;
    }
  };

  const handleDelete = (stage: IKanbanStage) => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `Are you sure you want to delete ${stage.title} stage`,
        agreeAction: () => {
          dispatch({
            type: Actions.HideConfirmation
          });
          deleteStage.mutate(stage.id);
        }
      }
    });
  };

  return (
    <Box className="kanban-stage">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '4px' }}>
          {column.icon}
          <Box
            sx={{
              fontWeight: 700,
              fontSize: '16px',
              color: (t) => t.palette.primary.main
            }}
          >
            {column.title}
          </Box>
          <Box className="data-badge">{column.items.length}</Box>
          {column.isDeletable === true ? (
            <Box
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ml: '20px' }}
            >
              <IconButton
                onClick={() => handleKanbanStageEdit(column)}
                sx={{ ...iconButtonProps, marginRight: '20px' }}
              >
                <EditOutlined sx={{ color: 'primary.main' }} />
              </IconButton>
              <IconButton
                onClick={() => handleDelete(column)}
                sx={{ ...iconButtonProps, marginRight: 0 }}
              >
                <DeleteOutlineOutlined sx={{ color: 'error.main' }} />
              </IconButton>
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box
        ref={scroll}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '18px',
          overflow: 'auto',
          scrollBehavior: 'smooth',
          scrollbarWidth: 'thin',
          pr: '14px',
          scrollbarColor: 'rgb(236, 26, 88) white'
        }}
      >
        {pageItems.map((item, index) => (
          <Box key={`card-${index}`}>{renderCard({ item, index })}</Box>
        ))}
      </Box>
      {pages > 1 && <Paging page={page} pages={pages} handlePageChange={handlePageChange} />}
    </Box>
  );
};

export default KanbanStageItems;

import React, { useContext, useState } from 'react';
import {
  Avatar,
  Box,
  FormControlLabel,
  IconButton,
  InputLabel,
  Switch,
  Tooltip,
  alpha,
  styled
} from '@mui/material';
import {
  AccountCircle,
  Edit,
  Email,
  Info,
  Lock,
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import { colors } from '../../../utils/theme';
import { AppContext } from '../../../AppContext';
import Header from '../../Common/Layout/Header';
import InputComponent from '../../Common/Fields/InputComponent';
import { IRole } from '../../../enums/AppConsts';
import './index.css';
import CancelButton from '../../Common/Buttons/CancelButton';
import MainButton from '../../Common/Buttons/MainButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Actions } from '../../../enums/ActionEnums';
import { handleRequestError } from '../../../utils/ui';
import ChangePassword from '../ChangePassword';
import { updateUserAccountMutation } from '../../../queries/user';

export type IErrors = {
  firstName: string | null;
  lastName: string | null;
  password: string | null;
};

const defaultErrorsObj: IErrors = {
  firstName: null,
  lastName: null,
  password: null
};

const errorTexts = {
  firstName: 'First name too short!',
  lastName: 'Last name too short!',
  password: 'Password not valid!'
};
const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: colors.pink.main,
    '&:hover': {
      backgroundColor: alpha(colors.pink.main, theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colors.pink.main
  }
}));
const rolesData = Object.values(IRole);
const AccountDetails: React.FunctionComponent = () => {
  const [visiblePass, setVisiblePass] = useState(false);
  const { state, dispatch } = useContext(AppContext);
  const [firstName, setFirstName] = useState(state.user.data?.firstName || '');
  const [lastName, setLastName] = useState(state.user.data?.lastName || '');
  const [email] = useState(state.user.data?.email || '');
  const [openChangePasswordForm, setOpenChangePasswordForm] = useState(false);
  const [password] = useState('');
  const [nameEditable, setNameEditable] = useState(true);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [passwordEditable, setPasswordEditable] = useState(true);
  const [showButtons, setShowButtons] = useState(false);
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const queryClient = useQueryClient();
  const queryKey = ['users'];
  const [userRoles] = useState<{
    [key: string]: boolean;
  }>(() => {
    const initialRoles: { [key: string]: boolean } = state.user.data?.roles
      ? state.user.data?.roles.reduce((obj, role: string) => {
          obj[role] = false;
          return obj;
        }, {})
      : [];
    rolesData.forEach((role: string) => {
      initialRoles[role] = state.user.data ? state.user.data?.roles?.indexOf(role) !== -1 : false;
    });
    return initialRoles;
  });
  const updateUser = useMutation(updateUserAccountMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `User ${data.firstName} ${data.lastName} updated`
        }
      });
      setShowButtons(false);
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey)
  });

  const handleCancel = () => {
    setFirstName(state.user.data?.firstName as string);
    setLastName(state.user.data?.lastName as string);
    setNameEditable(true);
    setPasswordEditable(true);
    setShowButtons(false);
  };

  const handleClose = () => {
    setOpenChangePasswordForm(false);
  };

  const handleNameEdit = () => {
    setNameEditable(false);
    setShowButtons(true);
  };

  const handleFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      firstName: value && value.length > 0 ? null : errorTexts.firstName
    });
    setFirstName(value);
  };

  const handleLastNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      lastName: value && value.length > 0 ? null : errorTexts.lastName
    });
    setLastName(value);
  };

  const handleSave = () => {
    updateUser.mutate({ firstName, lastName, email });
  };

  const handleSubmit = () => {
    const trimmedFirstName = firstName ? firstName.trim() : '';
    const trimmedLastName = lastName ? lastName.trim() : '';
    const errors = {
      firstName: null,
      lastName: null,
      password: null
    } as IErrors;
    let hasErrors = false;
    if (trimmedFirstName.length === 0) {
      hasErrors = true;
      errors.firstName = errorTexts.firstName;
    }
    if (trimmedLastName.length === 0) {
      hasErrors = true;
      errors.lastName = errorTexts.lastName;
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      handleSave();
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedImage(file);
    setShowButtons(true);
  };

  return (
    <Box sx={{ mt: '24px' }}>
      <Box>
        <Header text="Account Settings" />
        <Header text="Edit your account settings" isSubHeader={true} />
      </Box>
      <Box className="account-details-container">
        <InputLabel sx={{ gridColumn: 'span 2' }}>{'Profile Picture'}</InputLabel>
        <Box className="account-details-item">
          <Avatar sx={{ bgcolor: '#D3137D', width: '80px', height: '80px' }}>
            {selectedImage && (
              <img
                src={URL.createObjectURL(selectedImage)}
                alt="Selected Image"
                style={{ height: '90px', width: '90px' }}
              />
            )}
          </Avatar>
          <IconButton color="primary" aria-label="upload picture" component="label">
            <input hidden accept="image/*" type="file" onChange={handleImageChange} />
            <h1 className="account-details-update-image-button">Update Image</h1>
          </IconButton>
        </Box>
        <Box className="account-details-item">
          <InputComponent
            id="employee-firstName"
            label="Employee First Name"
            disabled={nameEditable}
            errorMsg={formErrors.firstName}
            value={firstName}
            onChange={handleFirstNameChange}
            containerStyles={{ width: '344px' }}
            startIcon={<AccountCircle sx={{ zIndex: 2 }} />}
          />
          <IconButton onClick={handleNameEdit}>
            <Edit className="account-details-item-edit-button" sx={{ color: 'error.main' }} />
            <h1
              className="account-details-item-edit-button-text"
              style={{
                color: colors.pink.main
              }}
            >
              Edit
            </h1>
          </IconButton>
        </Box>
        <Box className="account-details-item">
          <InputComponent
            id="employee-lastName"
            label="Employee Last Name"
            disabled={nameEditable}
            errorMsg={formErrors.lastName}
            value={lastName}
            onChange={handleLastNameChange}
            containerStyles={{ width: '344px' }}
            startIcon={<AccountCircle sx={{ zIndex: 2 }} />}
          />
        </Box>
        <Box className="account-details-item">
          <InputComponent
            id="employee-email"
            label="Email"
            disabled
            value={email}
            onChange={() => {
              true;
            }}
            containerStyles={{ width: '344px' }}
            startIcon={<Email sx={{ zIndex: 2 }} />}
          />
        </Box>
        <Box className="account-details-item">
          <InputComponent
            id="employee-password"
            type={visiblePass ? 'text' : 'password'}
            label="Password"
            disabled={passwordEditable}
            value={password}
            containerStyles={{ width: '344px' }}
            startIcon={<Lock sx={{ zIndex: 2 }} />}
            endIcon={
              <IconButton
                aria-label="toggle new password visibility"
                onClick={() => setVisiblePass(!visiblePass)}
                sx={{ marginRight: '-8px' }}
              >
                {visiblePass ? (
                  <VisibilityOff sx={{ zIndex: 2 }} />
                ) : (
                  <Visibility sx={{ zIndex: 2 }} />
                )}
              </IconButton>
            }
          />
          <IconButton
            onClick={() => {
              setOpenChangePasswordForm(true);
            }}
          >
            <Edit className="account-details-item-edit-button" sx={{ color: 'error.main' }} />
            <h1
              className="account-details-item-edit-button-text"
              style={{
                color: colors.pink.main
              }}
            >
              Edit
            </h1>
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <InputLabel sx={{ gridColumn: 'span 2' }}>{'Roles'}</InputLabel>
          <Tooltip title="info text" placement="top">
            <Info />
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {rolesData.map((role, index) => (
            <Box key={index}>
              <FormControlLabel
                control={<PinkSwitch id={role} checked={userRoles[role] || false} name={role} />}
                label={role}
                disabled
                labelPlacement="end"
              />
            </Box>
          ))}
        </Box>
        {showButtons && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '10px'
            }}
          >
            <CancelButton
              id="user-cancel"
              text="Cancel"
              onClick={handleCancel}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton
              id="user-create"
              text={'Update'}
              onClick={handleSubmit}
              sx={{ height: '36px', width: '48px' }}
            />
          </Box>
        )}
      </Box>
      {openChangePasswordForm && <ChangePassword onClose={handleClose} />}
    </Box>
  );
};

export default AccountDetails;

import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

interface IProps {
  text: string;
  isSubHeader?: boolean;
  additionalStyle?: SxProps;
}

const headerStyle: SxProps<Theme> = {
  fontWeight: 600,
  fontSize: '24px',
  lineHeight: '34px',
  color: (theme) => theme.palette.primary.main
};

const subHeaderStyle: SxProps<Theme> = {
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '20px',
  marginBottom: '24px',
  color: (theme) => theme.palette.grey[50]
};

const Header: React.FunctionComponent<IProps> = ({ text, isSubHeader, additionalStyle }) => {
  return (
    <Box
      sx={
        isSubHeader
          ? { ...subHeaderStyle, ...additionalStyle }
          : { ...headerStyle, ...additionalStyle }
      }
    >
      {text}
    </Box>
  );
};

export default Header;

import { Actions } from '../enums/ActionEnums';
import { ActionType } from '../models/appContext';
import { Journey } from '../models/journeys';

export const journeyReducer = (state: Journey | null, action: ActionType) => {
  switch (action.type) {
    case Actions.SetJourney:
      return action.payload.journey;
    case Actions.ResetJourney:
      return null;
    default:
      return state;
  }
};

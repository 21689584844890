import React from 'react';
import { Box, ListItem, SvgIcon } from '@mui/material';
import { IRouteModel } from '../../Routes';
import { SvgIconComponent } from '@mui/icons-material';
import { colors } from '../../utils/theme';

interface IProps {
  route: IRouteModel;
  selected: IRouteModel | null;
  open: boolean;
  userRoles: (string | null)[] | undefined;
  handleSelectedChanged(route: IRouteModel): void;
}

const ListItemLink: React.FunctionComponent<IProps> = ({
  route,
  selected,
  handleSelectedChanged,
  open,
  userRoles
}: IProps) => {
  const hasAccess = route.requiredRole ? userRoles?.includes(route.requiredRole) : true;
  if (!hasAccess) {
    return null;
  }

  const itemClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, route: IRouteModel) => {
    e.stopPropagation();
    handleSelectedChanged(route);
  };

  const isSelected = () => {
    return selected && selected == route;
  };

  return (
    <ListItem
      button
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => itemClick(e, route)}
      sx={{
        backgroundColor: isSelected() ? 'common.white' : 'none',
        boxShadow: isSelected() ? '0px 10px 15px rgba(125, 118, 226, 0.08)' : 'none',
        marginBottom: '8px',
        borderRadius: '8px',
        '&:hover': {
          backgroundColor: isSelected() ? 'common.white' : 'none'
        },
        display: 'flex',
        alignItems: 'center',
        padding: '12px 16px'
      }}
    >
      <Box
        className="centralize"
        sx={{
          background: isSelected() ? colors.gradients.ping : colors.white,
          borderRadius: '8px',
          marginRight: open ? '8px' : '0px',
          padding: '4px'
        }}
      >
        {
          <SvgIcon
            component={route.icon as SvgIconComponent}
            sx={{
              color: isSelected() ? 'common.white' : colors.gradients.pinkRgb
            }}
          />
        }
      </Box>
      {open && (
        <Box
          sx={{
            color: isSelected() ? colors.gradients.pinkRgb : 'primary.main',
            fontWeight: 700,
            fontSize: '14px',
            lineHeight: '20px'
          }}
        >
          {route.name}
        </Box>
      )}
    </ListItem>
  );
};

export default ListItemLink;

import React, { useState } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import InputComponent from "../../../Common/Fields/InputComponent";
import SelectComponent from "../../../Common/Fields/SelectComponent";
import PromoGroupForm from "../PromoGroupForm";
import "./index.css";
import { IPromotionGroup } from "../../../../models/promotion";

interface IProps {
  name: string;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  group: string;
  handleGroupChage: (e: SelectChangeEvent<string[]>) => void;
  groups: IPromotionGroup[];
}

const PromoDetails: React.FunctionComponent<IProps> = ({
  name,
  onNameChange,
  group,
  handleGroupChage,
  groups,
}: IProps) => {
  const [openForm, setOpenForm] = useState(false);

  return (
    <Box className="step-container">
      <Box className="step-title">Promotion Details</Box>
      <InputComponent
        id="promo-name"
        label="Promotion Name"
        value={name}
        onChange={onNameChange}
      />
      <SelectComponent
        label="Promotion Group"
        value={group}
        isSingle
        onChange={handleGroupChage}
        options={groups.map((g) => g.name)}
        hideNoneValue
        onCreateClick={() => setOpenForm(true)}
      />
      {openForm && <PromoGroupForm onClose={() => setOpenForm(false)} />}
    </Box>
  );
};

export default PromoDetails;

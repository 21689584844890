import { Box, IconButton, Avatar, Tabs, Tab, Button } from '@mui/material';
import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { West } from '@mui/icons-material';
import headerImage from '../../assets/TOPBG.png';
import { colors } from '../../utils/theme';
import TabPanel from '../Common/Layout/TabPanel';
import PlayerCardsOverviewTab from './PlayerCardsOverviewTab';
import './playerCardDetails.css';
import PlayerCardsNotesTab from './PlayerCardsNotesTab';

const PlayerCardDetails: React.FunctionComponent = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const firstName = location.state.playerCard.firstName;
  const lastName = location.state.playerCard.lastName;
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  const handleBack = () => {
    navigate(location.state.path);
  };

  const navigateToNotesTab = () => {
    setActiveTab(1);
  };

  return (
    <Box className="card-details">
      <Box sx={{ position: 'relative', textAlign: 'center' }}>
        <Box component="img" src={headerImage} alt="headerImage" className="header-image" />
        <Box className="content-container">
          <Box className="header">
            <IconButton onClick={handleBack} className="back-button">
              <West className="back-icon" />
            </IconButton>
            <span className="title">Players Cards</span>
          </Box>
          <Box className="player-info">
            <Box className="avatar-container">
              <Avatar sx={{ background: '#D3137D', width: '72px', height: '72px' }}>
                {`${Array.from(firstName)[0] ?? ''}${Array.from(lastName)[0] ?? ''}`}
              </Avatar>
            </Box>
            <Box className="name-container">
              <span className="name">{`${firstName} ${lastName}`}</span>
            </Box>
          </Box>
          <Box className="card-grid">
            <Box className="card"></Box>
            <Box className="card"></Box>
            <Box className="card"></Box>
            <Box className="double-card"></Box>
          </Box>
          <Box className="tabs-container">
            <Box className="tabs">
              <Tabs
                value={activeTab}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{ style: { display: 'none' } }}
              >
                <Tab
                  label={
                    <Button
                      variant="contained"
                      className={`tab-button${activeTab === 0 ? '-selected' : ''}`}
                    >
                      Overview
                    </Button>
                  }
                  id="simple-tab-1"
                  aria-controls="simple-tabpanel-1"
                />
                <Tab
                  label={
                    <Button
                      variant="contained"
                      className={`tab-button${activeTab === 1 ? '-selected' : ''}`}
                    >
                      Notes
                    </Button>
                  }
                  id="simple-tab-2"
                  aria-controls="simple-tabpanel-2"
                />
                <Tab
                  label={
                    <Button
                      variant="contained"
                      className={`tab-button${activeTab === 2 ? '-selected' : ''}`}
                    >
                      Actions
                    </Button>
                  }
                  id="simple-tab-3"
                  aria-controls="simple-tabpanel-3"
                />
              </Tabs>
            </Box>
            <TabPanel value={activeTab} index={0}>
              <PlayerCardsOverviewTab navigateToNotesTab={navigateToNotesTab} />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <PlayerCardsNotesTab />
            </TabPanel>
            <TabPanel value={activeTab} index={2}></TabPanel>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PlayerCardDetails;

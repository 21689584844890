import React, { useContext, useEffect, useState } from 'react';
import { Box, Slide, Theme, useMediaQuery, useTheme } from '@mui/material';
import InfoBox from '../Common/Layout/InfoBox/InfoBox';
import { formatNumber, getBrandId, handleRequestError } from '../../utils/ui';
import { AppContext } from '../../AppContext';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';
import { BrandMailingHistoryQuery, DashboardKpiQuery } from '../../queries/kpi';
import { DashboardKpi } from '../../models/kpi';
import ColumnChart from '../Charts/ColumnChart';
import { BrandBonusesHistoryQuery } from '../../queries/kpi';
import { BrandBonusesHistory } from '../../models/brand';
import {
  AccountBalanceRounded,
  PaymentRounded,
  SupervisorAccountRounded,
  TableRowsRounded
} from '@mui/icons-material';
import { BarLoader } from 'react-spinners';
import StackedChart from '../Charts/StackedChart';
import { ChartData } from '../../models/chart';

const index: React.FunctionComponent = () => {
  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const theme = useTheme<Theme>();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const dashboardKpiQuery = useQuery({
    queryKey: [QueryKey.DashboardKpi, brandId],
    queryFn: () => DashboardKpiQuery(brandId),
    onSuccess: ({ data }) => {
      setKpiData(data);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const brandBonusesQuery = useQuery({
    queryKey: [QueryKey.BrandBonusesHistory, brandId],
    queryFn: () => BrandBonusesHistoryQuery({ brandId }),
    onSuccess: ({ data }) => {
      setBrandBonusesHistory(data);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const brandMailingQuery = useQuery({
    queryKey: [QueryKey.BrandMailingHistory, brandId],
    queryFn: () => BrandMailingHistoryQuery({ brandId }),
    onSuccess: ({ data }) => {
      setMailHistory(data);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const [brandBonusesHistory, setBrandBonusesHistory] = useState<BrandBonusesHistory[]>(
    brandBonusesQuery.data?.data || []
  );

  const [mailsHistory, setMailHistory] = useState<ChartData>(brandMailingQuery.data?.data || null);

  const formatBonusValue = (value: number) => {
    if (value) {
      value = parseFloat((Number(value) / 1000).toFixed(2));
    }
    return value;
  };

  const [kpiData, setKpiData] = useState<DashboardKpi | null>(dashboardKpiQuery.data?.data || null);

  useEffect(() => {
    if (dashboardKpiQuery.data?.data) {
      setKpiData(dashboardKpiQuery.data.data);
    }
    if (brandMailingQuery.data?.data) {
      setMailHistory(brandMailingQuery.data.data);
    }
    if (brandBonusesQuery.data?.data) {
      setBrandBonusesHistory(brandBonusesQuery.data.data);
    }
  }, [dashboardKpiQuery.data, brandMailingQuery.data, brandBonusesQuery.data]);

  return (
    <Box className="nav-page">
      <Box display="flex" flexDirection="column" gap="24px">
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: {
              xs: 'repeat(1 minmax(0, 1fr))',
              sm: 'repeat(2, minmax(0, 1fr))',
              md: 'repeat(4, minmax(0, 1fr))'
            },
            gap: '24px 12px'
          }}
        >
          <Slide direction="right" in>
            <Box>
              <InfoBox
                title="MTD Deposits"
                percentValue={kpiData?.depositsPercentageChange ?? 0}
                trend={
                  kpiData && kpiData.depositsPercentageChange < 0 ? 'decreasing' : 'increasing'
                }
                value={formatNumber(kpiData?.depositsCurrentMonth ?? 0)}
                valueLegend={`SPLM: ${formatNumber(kpiData?.depositsPreviousMonth ?? 0)}`}
                loading={dashboardKpiQuery.isLoading}
                hasIcon
                iconBackground="rgba(236, 26, 88, 1)"
                icon={<AccountBalanceRounded className="info-icon" sx={{ fontSize: '46px' }} />}
              />
            </Box>
          </Slide>
          <Slide direction="right" in timeout={500}>
            <Box>
              <InfoBox
                title="MTD ADPU"
                percentValue={kpiData?.averageDepositsPercentageChange ?? 0}
                trend={
                  kpiData && kpiData.averageDepositsPercentageChange < 0
                    ? 'decreasing'
                    : 'increasing'
                }
                value={formatNumber(kpiData?.averageDepositsCurrentMonth ?? 0)}
                valueLegend={`SPLM: ${formatNumber(kpiData?.averageDepositsPreviousMonth ?? 0)}`}
                loading={dashboardKpiQuery.isLoading}
                hasIcon
                iconBackground="rgba(236, 26, 88, 1)"
                icon={<TableRowsRounded className="info-icon" sx={{ fontSize: '46px' }} />}
              />
            </Box>
          </Slide>
          <Slide direction="right" in timeout={1000}>
            <Box>
              <InfoBox
                title="Approval Rate"
                percentValue={kpiData?.approvalRateDepositsPercentageChange ?? 0}
                trend={
                  kpiData && kpiData.approvalRateDepositsPercentageChange < 0
                    ? 'decreasing'
                    : 'increasing'
                }
                value={formatNumber(kpiData?.approvalRateDepositsCurrentMonth ?? 0) + '%'}
                valueLegend={`SPLM: ${formatNumber(
                  kpiData?.approvalRateDepositsPreviousMonth ?? 0
                )}%`}
                hasIcon
                iconBackground="rgba(232, 62, 57, 1)"
                icon={
                  <PaymentRounded
                    className="info-icon"
                    sx={{ fontSize: '46px', color: 'rgba(255, 142, 138, 0.5)' }}
                  />
                }
                loading={dashboardKpiQuery.isLoading}
              />
            </Box>
          </Slide>
          <Slide direction="right" in timeout={1500}>
            <Box>
              <InfoBox
                title="MTD Depositors"
                percentValue={kpiData?.depositorsPercentageChange ?? 0}
                trend={
                  kpiData && kpiData.depositorsPercentageChange < 0 ? 'decreasing' : 'increasing'
                }
                value={(kpiData?.depositorsCurrentMonth ?? 0).toString()}
                valueLegend={`SPLM: ${kpiData?.depositorsPreviousMonth ?? 0}`}
                loading={dashboardKpiQuery.isLoading}
                hasIcon
                iconBackground="rgba(242, 143, 63, 1)"
                icon={
                  <SupervisorAccountRounded
                    className="info-icon"
                    sx={{ fontSize: '46px', color: 'rgba(255, 181, 122, 0.5)' }}
                  />
                }
              />
            </Box>
          </Slide>
        </Box>
        {/* <Box display="flex" gap="24px">
          <Box className="data-box">
            <Box className="data-box-title">
              <Box>Deposit amount per method</Box>
              <Box>Action</Box>
            </Box>
          </Box>
          <Box className="data-box">
            <Box className="data-box-title">
              <Box>Deposit amount per Status</Box>
              <Box>Action</Box>
            </Box>
            <BarChart />
          </Box>
        </Box> */}
        <Box display="flex" gap="24px" flexDirection={isMobileScreen ? 'column' : 'row'}>
          <Box className="data-box">
            <Box className="data-box-title">
              <Box>Bonuses</Box>
              <Box>
                {brandBonusesQuery.isLoading && (
                  <BarLoader
                    color="rgb(236,26,88)"
                    height={2}
                    speedMultiplier={1}
                    cssOverride={{ top: '20px' }}
                  />
                )}
              </Box>
            </Box>
            <ColumnChart
              series={{
                name: 'Bonuses',
                data: brandBonusesHistory.map((bbh) => formatBonusValue(bbh.monthlyTotal))
              }}
              labels={brandBonusesHistory.map((bbh) => bbh.month)}
            />
          </Box>
          <Box className="data-box">
            <Box className="data-box-title">
              <Box>Mail stats</Box>
              {/* <Box>Action</Box> */}
            </Box>
            {/* <LineChart
              series={{
                name: 'High - 2013',
                data: [28, 29, 33, 36, 32, 32, 33]
              }}
              labels={[]}
              markSymbol="$"
            /> */}
            <StackedChart labels={mailsHistory?.labels ?? []} series={mailsHistory?.items ?? []} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default index;

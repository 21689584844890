import React from 'react';
import { Box, IconButton } from '@mui/material';
import { colors } from '../../../../utils/theme';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import dayjs from 'dayjs';

interface IProps {
  id?: string;
  value: Date | null;
  onChange: (value: Date) => void;
}

const CustomTimeComponent: React.FunctionComponent<IProps> = ({ id, onChange, value }: IProps) => {
  const display = (input: 'hour' | 'minute') => {
    if (value) {
      const item = new Date(value);
      const placeHolder = input === 'hour' ? item.getHours() : item.getMinutes();
      return ('0' + placeHolder).slice(-2);
    } else {
      return '';
    }
  };

  const handleChange = (input: 'hour' | 'minute', direction: 'up' | 'down') => {
    const tomorrow = dayjs().add(1, 'day').set('minute', 0).toDate();
    const ref = value ? dayjs(value).toDate() : tomorrow;
    let date;
    if (input === 'hour') {
      if (direction === 'up') {
        date = dayjs(ref)
          .set('hour', ref.getHours() + 1)
          .toDate();
      } else {
        date = dayjs(ref)
          .set('hour', ref.getHours() - 1)
          .toDate();
      }
    } else {
      if (direction === 'up') {
        date = dayjs(ref)
          .set('minute', ref.getMinutes() + 10)
          .toDate();
      } else {
        date = dayjs(ref)
          .set('minute', ref.getMinutes() - 10)
          .toDate();
      }
    }
    onChange(date);
  };

  return (
    <Box id={id} sx={{ display: 'flex', position: 'relative', bottom: '6px', gap: '8px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '4px'
        }}
      >
        <IconButton
          sx={{ width: '18px', height: '18px' }}
          onClick={() => handleChange('hour', 'up')}
        >
          <KeyboardArrowUp />
        </IconButton>
        <Box
          key={`hour-${new Date().toISOString()}`}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '52px',
            height: '46px',
            border: `1px solid ${colors.info.main}`,
            borderRadius: '100px',
            cursor: 'default'
          }}
        >
          {display('hour')}
        </Box>
        <IconButton
          sx={{ width: '18px', height: '18px' }}
          onClick={() => handleChange('hour', 'down')}
        >
          <KeyboardArrowDown />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>:</Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '4px'
        }}
      >
        <IconButton
          sx={{ width: '18px', height: '18px' }}
          onClick={() => handleChange('minute', 'up')}
        >
          <KeyboardArrowUp />
        </IconButton>
        <Box
          key={`minute-${new Date().toISOString()}`}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '52px',
            height: '46px',
            border: `1px solid ${colors.info.main}`,
            borderRadius: '100px'
          }}
        >
          {display('minute')}
        </Box>
        <IconButton
          sx={{ width: '18px', height: '18px' }}
          onClick={() => handleChange('minute', 'down')}
        >
          <KeyboardArrowDown />
        </IconButton>
      </Box>
    </Box>
  );
};

export default CustomTimeComponent;

import React from 'react';
import { Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

interface IProps {
  noBorder?: boolean;
  value?: string;
  selected?: boolean;
  clickable?: boolean;
  day?: string;
  hour?: number;
  onClick?: (day: string, hour: number) => void;
}

const Cell: React.FunctionComponent<IProps> = ({
  noBorder = false,
  value = '',
  selected = false,
  clickable = true,
  onClick,
  day,
  hour
}: IProps) => {
  return (
    <Box
      onClick={() => (onClick ? onClick(day ?? '', hour ?? -1) : true)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: noBorder ? 'none' : '1px solid #F2F1F9',
        borderRadius: '5px',
        color: '#ACAABA',
        fontSize: '14px',
        fontWeight: 600,
        textAlign: 'center',
        background: selected ? '#FBE7F2' : 'white',
        height: '23px',
        cursor: clickable ? 'pointer' : 'default'
      }}
    >
      {selected ? <CheckIcon sx={{ width: '14px', height: '14', color: 'red' }} /> : value}
    </Box>
  );
};

export default Cell;

import React, { useContext, useEffect, useState } from 'react';
import { Box, Grow, Link } from '@mui/material';
import FilterCard from './FilterCard';
import CustomTable, { IHeadCell } from '../../Common/Tables/CustomTable';
import { displayText, getBrandId, getUserCurrencySymbol } from '../../../utils/ui';
import EmptyCard from './EmptyCard';
import { AppContext } from '../../../AppContext';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import {
  GetAllAssignedFilterCardsQuery,
  GetFilterCardsQuery,
  GetFilterCardsWithoutAssignedQuery
} from '../../../queries/filterCard';
import { IFilterCard } from '../../../models/card';
import { SegmentCriteria } from '../../../models/segmets';
import { IPlayer } from '../../../models/player';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes } from '../../../enums/RouteEnums';
import moment from 'moment';

interface IProps {
  openForm: () => void;
  players: IPlayer[];
  selectedCardsOption: string;
  isLoading: boolean;
}

const CardsView: React.FunctionComponent<IProps> = ({
  openForm,
  players,
  selectedCardsOption,
  isLoading
}: IProps) => {
  const { state } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const [filteredPlayers, setFilteredPlayers] = useState(players);
  const navigate = useNavigate();
  useEffect(() => {
    setFilteredPlayers(players ?? []);
  }, [players]);

  const filterCardsQuery = useQuery({
    queryKey: [QueryKey.FilterCards, state.user.data?.email, brandId],
    queryFn: () => GetFilterCardsQuery(brandId),
    onSuccess: ({ data }) => {
      setCards(data);
    }
  });
  const [cards, setCards] = useState<IFilterCard[]>(filterCardsQuery.data?.data || []);

  const allAssignedFilterCardsQuery = useQuery({
    queryKey: [QueryKey.AssignedFilterCards, state.user.data?.email, brandId],
    queryFn: () => GetAllAssignedFilterCardsQuery(brandId),
    onSuccess: ({ data }) => {
      setCards(data);
    }
  });

  const filterCardsWithoutAssignedQuery = useQuery({
    queryKey: [QueryKey.FilterCardsWithoutAssigned, state.user.data?.email, brandId],
    queryFn: () => GetFilterCardsWithoutAssignedQuery(brandId),
    onSuccess: ({ data }) => {
      setCards(data);
    }
  });

  // let operatorValue = '';
  // if (c.operator === '>') {
  //   operatorValue = '<';
  // } else if (c.operator === '<') {
  //   operatorValue = '>';
  // } else if (c.operator === '=') {
  //   operatorValue = '=';
  // } else if (c.operator === '>=') {
  //   operatorValue = '<=';
  // } else if (c.operator === '<=') {
  //   operatorValue = '>=';
  // }

  const takeOperatorValue = (value: string) => {
    let operatorValue = '';
    if (value === '>') {
      operatorValue = '<';
    } else if (value === '<') {
      operatorValue = '>';
    } else if (value === '=') {
      operatorValue = '=';
    } else if (value === '>=') {
      operatorValue = '<=';
    } else if (value === '<=') {
      operatorValue = '>=';
    }
    return operatorValue;
  };

  const handleDetails = (rowData: IPlayer) => {
    const path = AuthRoutes.PlayersCards;
    navigate(`${path}/${rowData.id}`, {
      state: { playerCard: rowData, path: AuthRoutes.Management }
    });
  };

  const handleFilter = (criterias: SegmentCriteria[]) => {
    let operatorValue = '';
    if (criterias.length > 0) {
      let allPlayers = players.map((player) => ({ ...player }));
      criterias.forEach((c) => {
        if (c.name.toLowerCase() === 'email') {
          allPlayers = allPlayers.filter((p) => p.email === c.value);
        }
        if (c.name.toLowerCase() === 'country') {
          allPlayers = allPlayers.filter((p) => p.country === c.value);
        }
        if (c.name.toLowerCase() === 'full name') {
          allPlayers = allPlayers.filter(
            (p) =>
              `${p.firstName.toLowerCase()} ${p.lastName.toLowerCase()}` ===
              c.value.toString().toLowerCase()
          );
        }
        if (c.name.toLowerCase() === 'registered') {
          const daysToAdd = parseInt(c.value.toString()) * -1;
          const currentDate = moment().format('YYYY-MM-DD');
          const newDate = moment(currentDate).add(daysToAdd, 'days').format('YYYY-MM-DD');
          operatorValue = takeOperatorValue(c.operator);
          allPlayers = allPlayers.filter((p) => {
            if (p.registered) {
              const registerDate = moment(p.registered.split('T')[0], 'YYYY-MM-DD');
              switch (operatorValue) {
                case '>':
                  return registerDate.isAfter(newDate);
                case '<':
                  return registerDate.isBefore(newDate);
                case '=':
                  return registerDate.isSame(newDate);
                case '>=':
                  return registerDate.isSameOrAfter(newDate);
                case '<=':
                  return registerDate.isSameOrBefore(newDate);
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase() === 'last login') {
          const daysToAdd = parseInt(c.value.toString()) * -1;
          const currentDate = moment().format('YYYY-MM-DD');
          const newDate = moment(currentDate).add(daysToAdd, 'days').format('YYYY-MM-DD');
          operatorValue = takeOperatorValue(c.operator);
          allPlayers = allPlayers.filter((p) => {
            if (p.lastLogin != null) {
              const loginDate = moment(p.lastLogin?.split('T')[0], 'YYYY-MM-DD');
              switch (operatorValue) {
                case '>':
                  return loginDate.isAfter(newDate);
                case '<':
                  return loginDate.isBefore(newDate);
                case '=':
                  return loginDate.isSame(newDate);
                case '>=':
                  return loginDate.isSameOrAfter(newDate);
                case '<=':
                  return loginDate.isSameOrBefore(newDate);
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase() === 'last login abs') {
          const newDate = moment(c.value).startOf('day');
          operatorValue = takeOperatorValue(c.operator);
          allPlayers = allPlayers.filter((p) => {
            if (p.lastLogin !== null) {
              const loginDate = moment(p.lastLogin);
              switch (operatorValue) {
                case '>':
                  return loginDate.isBefore(newDate);
                case '<':
                  return loginDate.isAfter(newDate);
                case '=':
                  return loginDate.isSame(newDate);
                case '>=':
                  return loginDate.isSameOrBefore(newDate);
                case '<=':
                  return loginDate.isSameOrAfter(newDate);
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase() === 'registered abs') {
          const newDate = moment(c.value).startOf('day');
          operatorValue = takeOperatorValue(c.operator);
          allPlayers = allPlayers.filter((p) => {
            if (p.registered !== null) {
              const registerDate = moment(p.registered);
              switch (operatorValue) {
                case '>':
                  return registerDate.isBefore(newDate);
                case '<':
                  return registerDate.isAfter(newDate);
                case '=':
                  return registerDate.isSame(newDate);
                case '>=':
                  return registerDate.isSameOrBefore(newDate);
                case '<=':
                  return registerDate.isSameOrAfter(newDate);
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase() === 'first deposit date') {
          const daysToAdd = parseInt(c.value.toString()) * -1;
          const currentDate = moment().format('YYYY-MM-DD');
          const newDate = moment(currentDate).add(daysToAdd, 'days').format('YYYY-MM-DD');
          operatorValue = takeOperatorValue(c.operator);
          allPlayers = allPlayers.filter((p) => {
            if (p.firstDepositDate != null) {
              const firstDepositDate = moment(p.firstDepositDate?.split('T')[0], 'YYYY-MM-DD');
              switch (operatorValue) {
                case '>':
                  return firstDepositDate.isAfter(newDate);
                case '<':
                  return firstDepositDate.isBefore(newDate);
                case '=':
                  return firstDepositDate.isSame(newDate);
                case '>=':
                  return firstDepositDate.isSameOrAfter(newDate);
                case '<=':
                  return firstDepositDate.isSameOrBefore(newDate);
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase() === 'first deposit date abs') {
          const newDate = moment(c.value).startOf('day');
          operatorValue = takeOperatorValue(c.operator);
          allPlayers = allPlayers.filter((p) => {
            if (p.firstDepositDate !== null) {
              const firstDepositDate = moment(p.firstDepositDate);
              switch (operatorValue) {
                case '>':
                  return firstDepositDate.isBefore(newDate);
                case '<':
                  return firstDepositDate.isAfter(newDate);
                case '=':
                  return firstDepositDate.isSame(newDate);
                case '>=':
                  return firstDepositDate.isSameOrBefore(newDate);
                case '<=':
                  return firstDepositDate.isSameOrAfter(newDate);
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase() === 'last deposit date') {
          const daysToAdd = parseInt(c.value.toString()) * -1;
          const currentDate = moment().format('YYYY-MM-DD');
          const newDate = moment(currentDate).add(daysToAdd, 'days').format('YYYY-MM-DD');
          operatorValue = takeOperatorValue(c.operator);
          allPlayers = allPlayers.filter((p) => {
            if (p.lastDepositDate != null) {
              const lastDepositDate = moment(p.lastDepositDate?.split('T')[0], 'YYYY-MM-DD');
              switch (operatorValue) {
                case '>':
                  return lastDepositDate.isAfter(newDate);
                case '<':
                  return lastDepositDate.isBefore(newDate);
                case '=':
                  return lastDepositDate.isSame(newDate);
                case '>=':
                  return lastDepositDate.isSameOrAfter(newDate);
                case '<=':
                  return lastDepositDate.isSameOrBefore(newDate);
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase() === 'last deposit date abs') {
          const newDate = moment(c.value).startOf('day');
          operatorValue = takeOperatorValue(c.operator);
          allPlayers = allPlayers.filter((p) => {
            if (p.firstDepositDate !== null) {
              const lastDepositDate = moment(p.lastDepositDate);
              switch (operatorValue) {
                case '>':
                  return lastDepositDate.isBefore(newDate);
                case '<':
                  return lastDepositDate.isAfter(newDate);
                case '=':
                  return lastDepositDate.isSame(newDate);
                case '>=':
                  return lastDepositDate.isSameOrBefore(newDate);
                case '<=':
                  return lastDepositDate.isSameOrAfter(newDate);
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase().includes('total deposit amount')) {
          const numericValue = parseFloat(c.value.toString());

          allPlayers = allPlayers.filter((p) => {
            if (typeof p.totalDepositAmount === 'number') {
              switch (c.operator) {
                case '>':
                  return p.totalDepositAmount > numericValue;
                case '<':
                  return p.totalDepositAmount < numericValue;
                case '=':
                  return p.totalDepositAmount === numericValue;
                case '>=':
                  return p.totalDepositAmount >= numericValue;
                case '<=':
                  return p.totalDepositAmount <= numericValue;
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase().includes('total deposit count')) {
          const numericValue = parseInt(c.value.toString());
          allPlayers = allPlayers.filter((p) => {
            if (typeof p.totalDepositCount === 'number') {
              switch (c.operator) {
                case '>':
                  return p.totalDepositCount > numericValue;
                case '<':
                  return p.totalDepositCount < numericValue;
                case '=':
                  return p.totalDepositCount === numericValue;
                case '>=':
                  return p.totalDepositCount >= numericValue;
                case '<=':
                  return p.totalDepositCount <= numericValue;
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLowerCase().includes('real money balance')) {
          const numericValue = parseFloat(c.value.toString());

          allPlayers = allPlayers.filter((p) => {
            if (typeof p.realMoneyBalance === 'number') {
              switch (c.operator) {
                case '>':
                  return p.realMoneyBalance > numericValue;
                case '<':
                  return p.realMoneyBalance < numericValue;
                case '=':
                  return p.realMoneyBalance === numericValue;
                case '>=':
                  return p.realMoneyBalance >= numericValue;
                case '<=':
                  return p.realMoneyBalance <= numericValue;
                default:
                  return false;
              }
            }
          });
        }
        if (c.name.toLocaleLowerCase().includes('average deposit amount')) {
          allPlayers = allPlayers.filter((p) => {
            switch (c.operator) {
              case '>':
                return (
                  p.totalDepositCount &&
                  p.totalDepositAmount &&
                  p.totalDepositCount > 0 &&
                  p.totalDepositAmount / p.totalDepositCount > Number(c.value)
                );
              case '>=':
                return (
                  p.totalDepositCount &&
                  p.totalDepositAmount &&
                  p.totalDepositCount > 0 &&
                  p.totalDepositAmount / p.totalDepositCount >= Number(c.value)
                );
              case '<':
                return (
                  p.totalDepositCount &&
                  p.totalDepositAmount &&
                  p.totalDepositCount > 0 &&
                  p.totalDepositAmount / p.totalDepositCount < Number(c.value)
                );
              case '<=':
                return (
                  p.totalDepositCount &&
                  p.totalDepositAmount &&
                  p.totalDepositCount > 0 &&
                  p.totalDepositAmount / p.totalDepositCount <= Number(c.value)
                );
              case '=':
                return (
                  p.totalDepositCount &&
                  p.totalDepositAmount &&
                  p.totalDepositCount > 0 &&
                  p.totalDepositAmount / p.totalDepositCount == Number(c.value)
                );

              default:
                break;
            }
          });
        }
      });
      setFilteredPlayers(allPlayers);
    } else {
      setFilteredPlayers(players);
    }
  };
  // useEffect(() => {
  //   filterCardsWithoutAssignedQuery.refetch();
  //   allAssignedFilterCardsQuery.refetch();
  //   filterCardsQuery.refetch();
  // }, [state.selectedBrand, filterCardsQuery.data?.data]);

  useEffect(() => {
    // if (selectedCardsOption === FilterCardEnums.MyFilterCards) {
    //   setCards(filterCardsWithoutAssignedQuery.data?.data || []);
    // } else if (selectedCardsOption === FilterCardEnums.MyAssignedFilterCards) {
    //   setCards(allAssignedFilterCardsQuery.data?.data || []);
    // } else if (selectedCardsOption === FilterCardEnums.All) {
    //   setCards(filterCardsQuery.data?.data || []);
    // }
    setCards(filterCardsQuery.data?.data || []);
  }, [selectedCardsOption, cards]);
  const columns = [
    {
      title: 'First Name',
      field: 'firstName',
      render: (data: IPlayer) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {data.firstName}
        </Link>
      )
    },
    {
      title: 'Last Name',
      field: 'lastName',
      render: (data: IPlayer) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {data.lastName}
        </Link>
      )
    },
    {
      title: 'Email',
      field: 'email',
      render: (data: IPlayer) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {data.email}
        </Link>
      )
    },
    {
      title: 'VIP Manager',
      field: 'vipManager',
      render: (data: IPlayer) => displayText(data.vipManager)
    },
    {
      title: 'VIP Status',
      field: 'vipStatus',
      render: (data) => (
        <Box className="vip-status-badge" sx={{ width: '120px' }}>
          {data.vipStatus ?? 'not set'}
        </Box>
      )
    },
    {
      title: 'Lifecycle stage',
      field: 'stage',
      render: (data) => <Box className="data-badge">{data.stage}</Box>,
      sorting: true
    },
    {
      title: 'Withdrawal Amount',
      field: 'withdrawalAmount',
      render: (data: IPlayer) =>
        displayText(data.withdrawalAmount, 'money', getUserCurrencySymbol(data.currency)),
      sorting: true
    }
    // {
    //   title: 'Bonuses',
    //   field: 'bonuses'
    // }
  ] as IHeadCell[];

  const emptyCardsCount = 6 - cards.length;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '80px' }}>
      <Box className="row-even-space">
        {cards.map((card, index) => (
          <Grow in timeout={index * 800} key={`card-${index}`}>
            <FilterCard
              name={card.name}
              color={card.color}
              criterias={card.criterias}
              id={card.id}
              assignedUserIds={card.assignedUserIds}
              onFilter={handleFilter}
            />
          </Grow>
        ))}
        {Array.from({ length: emptyCardsCount }).map((_, index) => (
          <Grow key={`empty-card-${index}`} in timeout={(cards.length + index) * 800}>
            <EmptyCard disabled={index !== 0} openForm={openForm} />
          </Grow>
        ))}
      </Box>
      <CustomTable
        toolbarTitle={'Players'}
        columns={columns}
        data={filteredPlayers}
        showSearch
        handleDetails={handleDetails}
        rowsPerPage={10}
        loading={isLoading}
      />
    </Box>
  );
};

export default CardsView;

import React, { useContext, useState } from 'react';
import DialogBox from '../Common/Layout/DialogBox';
import { Email } from '../../models/email';
import EmailContent from '../CampaignManager/Promotions/PromoStepper/EmailContent';
import { Box, Divider } from '@mui/material';
import CancelButton from '../Common/Buttons/CancelButton';
import MainButton from '../Common/Buttons/MainButton';
import { CreateEmailEventMutation, UpdateEmailEventMutation } from '../../queries/email';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../../AppContext';
import { getBrandDisplayName, getBrandId, handleRequestError } from '../../utils/ui';
import { Actions } from '../../enums/ActionEnums';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';
import Loader from '../Common/Global/Loader';
import InputComponent from '../Common/Fields/InputComponent';

interface IProps {
  onClose: () => void;
  email: Email | null;
  isPreview: boolean;
}

const EmailForm: React.FunctionComponent<IProps> = ({ onClose, email, isPreview }: IProps) => {
  const { dispatch, state } = useContext(AppContext);
  const queryClient = useQueryClient();

  const fnKey = QueryKey.EmailEvents;
  const brandId = getBrandId(state.selectedBrand);
  const brand = getBrandDisplayName(state.selectedBrand);
  const defaultEmail = {
    id: -1,
    eventDisplayName: '',
    triggerName: '',
    eventName: '',
    description: '',
    isCustom: true,
    brand
  } as Email;

  const [emailInput, setEmailInput] = useState<Email>(email ?? defaultEmail);

  const setSender = (input: number | null) => {
    setEmailInput({ ...emailInput, sender: input });
  };

  const setTemplate = (input: string | null) => {
    setEmailInput({ ...emailInput, template: input });
  };

  const updateEvent = useMutation(UpdateEmailEventMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      const updatedEvent = data as Email;
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${updatedEvent.eventDisplayName} updated`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([fnKey, brandId])
  });

  const createEvent = useMutation(CreateEmailEventMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      const newEvent = data as Email;
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${newEvent.eventDisplayName} updated`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([fnKey, brandId])
  });

  const handleSave = () => {
    if (email) {
      updateEvent.mutate(emailInput);
    } else {
      createEvent.mutate(emailInput);
    }
  };

  return (
    <DialogBox
      width={770}
      onClose={onClose}
      title={
        email
          ? isPreview
            ? ''
            : `Edit ${email.eventDisplayName} email settings`
          : 'Add custom email event'
      }
    >
      {(email === null || email.isCustom) && (
        <Box>
          <Box
            sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, minmax(0, 1fr))', gap: '24px' }}
          >
            <InputComponent
              id="email-display-name"
              label="Event Name"
              value={emailInput.eventDisplayName}
              onChange={(e) =>
                setEmailInput({ ...emailInput, eventDisplayName: e.currentTarget.value })
              }
            />
            <InputComponent
              id="email-name"
              label="Custom trigger"
              value={emailInput.triggerName ?? ''}
              onChange={(e) => setEmailInput({ ...emailInput, triggerName: e.currentTarget.value })}
            />
            <InputComponent
              id="email-description"
              containerStyles={{ gridColumn: 'span 2' }}
              label="Description"
              value={emailInput.description}
              onChange={(e) => setEmailInput({ ...emailInput, description: e.currentTarget.value })}
            />
          </Box>
          <Divider sx={{ my: '24px' }} />
        </Box>
      )}
      <EmailContent
        emailSender={emailInput?.sender ?? null}
        setEmailSender={setSender}
        template={emailInput?.template ?? ''}
        setTemplate={setTemplate}
        isPreview={isPreview}
      />
      <Box className="form-actions" sx={{ mt: '24px' }}>
        <Box className="form-group-buttons">
          <CancelButton
            id="segment-cancel"
            text="Cancel"
            onClick={onClose}
            sx={{ height: '36px', width: '48px' }}
          />
          <MainButton id="event-save" text="Save" onClick={handleSave} />
        </Box>
      </Box>
      <Loader loading={updateEvent.isLoading} />
    </DialogBox>
  );
};

export default EmailForm;

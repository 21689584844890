import React, { useReducer, createContext, Dispatch } from 'react';
import { RoleModel } from './models/account';
import { ActionType, ConfirmationType, MessageType, UserType } from './models/appContext';
import { mainReducer } from './reducers/mainReducer';
import { getRoutes, IRouteModel } from './Routes';
import { Journey, JourneyGroup, JourneyStage } from './models/journeys';

export type InitialStateType = {
  user: UserType;
  message: MessageType;
  routes: IRouteModel[];
  roles: RoleModel[];
  confirmation: ConfirmationType;
  selectedBrand: string;
  journeyGroups: JourneyGroup[];
  selectedJourney: Journey | null;
  selectedJourneyStage: JourneyStage | null;
};

const InitialState = {
  user: { data: null, token: null },
  message: {
    open: false,
    text: null,
    severity: null,
    autoHide: 3000,
    subText: null
  },
  roles: [],
  confirmation: { open: false },
  selectedBrand: '',
  routes: getRoutes(),
  journeyGroups: [],
  selectedJourney: null,
  selectedJourneyStage: null
} as InitialStateType;

const AppContext = createContext<{
  state: InitialStateType;
  dispatch: Dispatch<ActionType>;
}>({
  state: InitialState,
  dispatch: () => null
});

interface IProps {
  children: React.ReactNode;
}

const AppProvider: React.FunctionComponent<IProps> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, InitialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export { AppProvider, AppContext };

import { SvgIconComponent } from '@mui/icons-material';
import { Box, Collapse, ListItem, SvgIcon } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { IRouteModel } from '../../Routes';
import { colors } from '../../utils/theme';

interface IProps {
  route: IRouteModel;
  selected: IRouteModel | null;
  subSelected: IRouteModel | null;
  open: boolean;
  userRoles: (string | null)[] | undefined;
  handleSelectedChanged(route: IRouteModel, subRoute: IRouteModel): void;
}

const ListGroupLink: React.FunctionComponent<IProps> = ({
  route,
  selected,
  subSelected,
  handleSelectedChanged,
  open,
  userRoles
}) => {
  const hasAccess = route.requiredRole ? userRoles?.includes(route.requiredRole) : true;
  if (!hasAccess) {
    return null;
  }

  const [openSubRoutes, setOpenSubRoutes] = useState(false);

  useEffect(() => {
    setOpenSubRoutes(isSelected() ? true : false);
  }, [selected]);

  const isSelected = () => {
    return selected && selected == route;
  };
  const onParentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setOpenSubRoutes(!openSubRoutes);
  };

  const onChildClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, subRoute: IRouteModel) => {
    e.stopPropagation();
    handleSelectedChanged(route, subRoute);
  };

  return (
    <Box
      sx={{
        backgroundColor: isSelected() ? 'common.white' : 'none',
        boxShadow: isSelected() ? '0px 10px 15px rgba(125, 118, 226, 0.08)' : 'none',
        borderRadius: '8px',
        marginBottom: '8px'
      }}
    >
      <ListItem
        button
        onClick={onParentClick}
        sx={{
          backgroundColor: 'none',
          borderRadius: '8px',
          '&:hover': {
            backgroundColor: 'none'
          },
          display: 'flex',
          alignItems: 'center',
          padding: '12px 16px'
        }}
      >
        <Box
          className="centralize"
          sx={{
            background: isSelected() ? colors.gradients.ping : colors.white,
            borderRadius: '8px',
            marginRight: open ? '8px' : '0px',
            padding: '4px'
          }}
        >
          {
            <SvgIcon
              component={route.icon as SvgIconComponent}
              sx={{
                color: isSelected() ? 'common.white' : colors.gradients.pinkRgb
              }}
            />
          }
        </Box>
        {open && (
          <Box
            sx={{
              color: isSelected() ? colors.gradients.pinkRgb : 'primary.main',
              fontWeight: 700,
              fontSize: '14px',
              lineHeight: '20px'
            }}
          >
            {route.name}
          </Box>
        )}
      </ListItem>
      {open && (
        <Collapse in={openSubRoutes} timeout="auto">
          {route.subRoutes?.map((r, index) => {
            const hasAccess = r.requiredRole ? userRoles?.includes(r.requiredRole) : true;
            if (!hasAccess) {
              return null;
            }
            return (
              <ListItem
                button
                key={index}
                sx={{
                  marginBottom: '8px',
                  paddingLeft: '56px',
                  paddingY: '8px',
                  color: r === subSelected ? colors.gradients.pinkRgb : 'primary.main',
                  fontWeight: 700,
                  fontSize: '12px',
                  lineHeight: '16px',
                  cursor: 'pointer'
                  //   borderRadius: '8px'
                }}
                onClick={(e) => onChildClick(e, r)}
              >
                {r.name}
              </ListItem>
            );
          })}
        </Collapse>
      )}
    </Box>
  );
};

export default ListGroupLink;

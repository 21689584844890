import React, { useState, useRef, useContext } from 'react';
import {
  alpha,
  Box,
  Button,
  FormControlLabel,
  SelectChangeEvent,
  styled,
  Switch,
  Tooltip,
  Typography
} from '@mui/material';
import DialogBox from '../../Common/Layout/DialogBox';
import InputComponent from '../../Common/Fields/InputComponent';
import SelectComponent from '../../Common/Fields/SelectComponent';
import SegmentGroupForm from './SegmentGroupForm';
import CancelButton from '../../Common/Buttons/CancelButton';
import AddButton from '../../Common/Buttons/AddButton';
import { Segment, SegmentCriteria, SegmentGroup, SegmentModel } from '../../../models/segmets';
import MainButton from '../../Common/Buttons/MainButton';
import { Info, PublishedWithChanges } from '@mui/icons-material';
import SegmentAttribute from './SegmentAttribute';
import { colors } from '../../../utils/theme';
import { ChannelOperators } from '../../../models/fakeDb';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CalculateSegmentMutation,
  CreateSegmentMutation,
  UpdateSegmentMutation
} from '../../../queries/segment';
import { AppContext } from '../../../AppContext';
import { getBrandDisplayName, getBrandId, handleRequestError } from '../../../utils/ui';
import Loader from '../../Common/Global/Loader';
import { Actions } from '../../../enums/ActionEnums';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { IPlayer } from '../../../models/player';
import PlayersSegmentUpload from '../../LeadManagement/PlayersSegmentUpload';

export const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: colors.pink.main,
    '&:hover': {
      backgroundColor: alpha(colors.pink.main, theme.palette.action.hoverOpacity)
    }
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: colors.pink.main
  }
}));

interface IProps {
  segment?: Segment | null;
  groups: SegmentGroup[];
  onClose: () => void;
  createGroup: (name: string) => void;
  players?: IPlayer[] | [];
}
const gap = '24px';

const initialCriteria = {
  name: '',
  operator: '',
  value: ''
} as SegmentCriteria;

const SegmentForm: React.FunctionComponent<IProps> = ({
  groups,
  onClose,
  createGroup,
  segment,
  players
}: IProps) => {
  const queryClient = useQueryClient();
  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const [openForm, setOpenForm] = useState(false);
  const [name, setName] = useState(segment?.name ?? '');
  const [group, setGroup] = useState(segment?.segmentGroup.name ?? '');
  const [description, setDescription] = useState(segment?.description ?? '');
  const [criterias, setCriterias] = useState<SegmentCriteria[]>(
    segment?.criterias ?? [initialCriteria]
  );
  const [calculated, setCalculated] = useState<number | null>(null);
  const [blockedAnyChannels, setBlockedAnyChannels] = useState(segment?.blockChannels ?? false);
  const [blockedChannels, setBlockedChannels] = useState<string[]>(segment?.blockedChannels ?? []);
  const [showUpload, setShowUpload] = useState(false);
  const hasPlayersToUpload = players && players?.length > 0;

  const addEmptyCriteria = () => {
    setCalculated(null);
    setCriterias([...criterias, initialCriteria]);
  };

  const removeCriteria = (index: number) => {
    setCalculated(null);
    const items = [...criterias];
    items.splice(index, 1);
    setCriterias(items);
  };

  const handleCriteriaChange = (index: number, criteria: SegmentCriteria) => {
    const items = [...criterias];
    items[index] = criteria;
    setCriterias(items);
  };

  const getMinHeight = () => {
    const res = criterias.length * 85 > 350 ? 350 : criterias.length * 85;
    return `${res}px`;
  };

  const hasInvalidCriteria = () => {
    return criterias.some((cr) => cr.name.length === 0 || cr.operator.length === 0);
  };
  const isInvalid = () => {
    const result =
      name.length === 0 ||
      group.length === 0 ||
      (hasInvalidCriteria() && !hasPlayersToUpload) ||
      (blockedAnyChannels && blockedChannels.length === 0);
    return result;
  };

  const handleChannelChange = (event: SelectChangeEvent<typeof blockedChannels>) => {
    const {
      target: { value }
    } = event;
    setBlockedChannels(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const createSegment = useMutation(CreateSegmentMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Segment ${data.name} created`
        }
      });
      queryClient.setQueryData<SegmentGroup[]>([QueryKey.SegmentGroups, brandId], (oldData) =>
        oldData
          ? {
              ...oldData,
              //@ts-ignore
              data: oldData.data.map((sg) =>
                sg.id === data.segmentGroup.id ? { ...sg, segments: [data, ...sg.segments] } : sg
              )
            }
          : oldData
      );
      onClose();
    },
    onError: ({ response }) => handleRequestError(dispatch, response),
    onSettled: () => queryClient.invalidateQueries([QueryKey.SegmentGroups, brandId])
  });

  const updateSegment = useMutation(UpdateSegmentMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Segment ${data.name} updated`
        }
      });
      onClose();
    },
    onError: ({ response }) => handleRequestError(dispatch, response),
    onSettled: () => queryClient.invalidateQueries([QueryKey.SegmentGroups, brandId])
  });

  const calculateSegment = useMutation(CalculateSegmentMutation, {
    onSuccess: ({ data }) => setCalculated(data as number),
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const handleSave = () => {
    const playersEmails = players?.map((p) => p.email);
    const input = {
      name,
      description,
      criterias,
      segmentGroupId: groups.find((g) => g?.name === group)?.id,
      blockChannels: blockedAnyChannels,
      blockedChannels,
      playersEmails
    } as SegmentModel;
    if (segment) {
      updateSegment.mutate({ id: segment.id, segment: input });
    } else {
      createSegment.mutate({ brandId, input });
    }
  };

  const scroll = useRef(null);

  const handleGroupChage = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;
    if (value) {
      setGroup(value as string);
    }
  };

  return (
    <DialogBox
      width={800}
      onClose={onClose}
      title={segment ? 'Edit Segment' : 'Create New Segment'}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: gap
        }}
      >
        <Box sx={{ display: 'flex', columnGap: gap }}>
          <InputComponent
            id="segment-name"
            label="Segment Name"
            value={name}
            onChange={(event) => setName(event.currentTarget.value)}
            containerStyles={{ flex: 1 }}
          />
          <SelectComponent
            label="Group"
            value={group}
            isSingle
            onChange={handleGroupChage}
            options={groups.map((g) => g.name)}
            containerStyles={{ flex: 1 }}
            hideNoneValue
            onCreateClick={() => setOpenForm(true)}
          />
        </Box>
        <InputComponent
          id="segment-description"
          label="Description"
          value={description}
          onChange={(event) => setDescription(event.currentTarget.value)}
        />
        {!hasPlayersToUpload && (
          <Box
            ref={scroll}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap,
              minHeight: getMinHeight(),
              maxHeight: '350px',
              overflow: 'auto',
              scrollBehavior: 'smooth',
              scrollbarWidth: 'thin'
            }}
          >
            {criterias.map((c, i) => (
              <SegmentAttribute
                key={i}
                index={i}
                segmentCriteria={c}
                onChange={handleCriteriaChange}
                hideDelete={criterias.length === 1}
                remove={removeCriteria}
              />
            ))}
          </Box>
        )}
        {hasPlayersToUpload && (
          <Button
            sx={{
              color: colors.gradients.pinkRgb,
              flexWrap: 'nowrap',
              p: 0,
              width: '127px',
              '&: hover': {
                textDecoration: 'underline',
                backgroundColor: 'white'
              }
            }}
            onClick={() => setShowUpload(true)}
          >
            {`${players.length} players uploaded`}
          </Button>
        )}
        {!hasPlayersToUpload && (
          <Button
            sx={{
              color: colors.gradients.pinkRgb,
              width: '77px',
              p: '0px',
              '&: hover': {
                textDecoration: 'underline',
                backgroundColor: 'white'
              }
            }}
            onClick={addEmptyCriteria}
            disabled={criterias.length === 8}
          >
            + Add Filter
          </Button>
        )}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '45px',
            height: '50px',
            position: 'relative',
            bottom: '10px'
          }}
        >
          <Box className="form-group-buttons">
            <FormControlLabel
              control={
                <PinkSwitch
                  defaultChecked
                  checked={blockedAnyChannels}
                  onChange={(event) => {
                    setBlockedAnyChannels(event.target.checked);
                    setBlockedChannels([]);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Block channels"
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                color: (t) => t.palette.primary.main
              }}
            />
            <Tooltip title="info text" placement="top">
              <Info />
            </Tooltip>
          </Box>
          {blockedAnyChannels && (
            <SelectComponent
              label="Select Channels"
              isSingle={false}
              value={blockedChannels}
              options={ChannelOperators}
              onChange={handleChannelChange}
              containerStyles={{ width: '300px' }}
            />
          )}
        </Box>
        <Box className="form-actions">
          <Box className="form-group-buttons">
            <CancelButton
              id="segment-cancel"
              text="Cancel"
              onClick={onClose}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton
              id="segment-create"
              text={segment ? 'Save' : 'Create'}
              disabled={isInvalid()}
              onClick={handleSave}
            />
          </Box>
          {!hasPlayersToUpload && (
            <Box className="form-group-buttons">
              <AddButton
                id="segment-calculate"
                text="Calculate"
                sx={{ width: '105px' }}
                icon={
                  <PublishedWithChanges
                    className={calculateSegment.isLoading ? 'rotate-icon' : ''}
                    sx={{ width: '16px', height: '16px' }}
                  />
                }
                disabled={hasInvalidCriteria()}
                onClick={() =>
                  calculateSegment.mutate({
                    brand: getBrandDisplayName(state.selectedBrand),
                    criterias
                  })
                }
              />
              {calculated && (
                <Box
                  sx={{
                    display: 'flex',
                    gap: '5px',
                    lineHeight: '16px',
                    alignItems: 'center'
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '12px',
                      color: (t) => t.palette.grey[50]
                    }}
                  >
                    Total:
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '12px',
                      color: (t) => t.palette.primary.main
                    }}
                  >
                    {calculated}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {openForm && (
        <SegmentGroupForm
          onClose={(saved, item) => {
            if (saved && item) {
              createGroup(item);
            }
            setOpenForm(false);
          }}
        />
      )}
      {showUpload && (
        <PlayersSegmentUpload
          onClose={() => {
            setShowUpload(false);
          }}
          players={players as IPlayer[]}
        />
      )}
      <Loader loading={createSegment.isLoading || updateSegment.isLoading} />
    </DialogBox>
  );
};

export default SegmentForm;

import { Box } from '@mui/material';
import React from 'react';
import { colors } from '../../../utils/theme';

interface IProps {
  name: string;
  value: string;
  operator: string;
}

const FilterCardAttribute: React.FunctionComponent<IProps> = ({
  name,
  value,
  operator
}: IProps) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
        lineHeight: '16.8px'
      }}
    >
      <Box sx={{ fontWeight: 400, fontSize: '12px', color: colors.primary.main, width: '100%' }}>
        {name}
      </Box>
      <Box
        sx={{
          fontWeight: 400,
          fontSize: '12px',
          color: colors.primary.main,
          width: '100%',
          textAlign: 'center'
        }}
      >
        {operator}
      </Box>
      <Box sx={{ fontWeight: 700, fontSize: '12px', color: colors.primary.main }}>{value}</Box>
    </Box>
  );
};

export default FilterCardAttribute;

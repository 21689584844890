import React, { useContext } from 'react';
import { DeleteOutlineOutlined, EditOutlined, Refresh } from '@mui/icons-material';
import { Box, FormControlLabel, IconButton, Switch, Typography } from '@mui/material';
import { Segment, SegmentGroup } from '../../../models/segmets';
import CustomTable, { IHeadCell } from '../../Common/Tables/CustomTable';
import { colors } from '../../../utils/theme';
import { displayText, getBrandId, handleRequestError } from '../../../utils/ui';
import moment from 'moment';
import StatusIdicator from '../../Common/Layout/StatusIdicator';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  DeleteSegmentMutation,
  SetIsSegmentActiveMutation,
  SyncGroupMutation
} from '../../../queries/segment';
import Loader from '../../Common/Global/Loader';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';

interface IProps {
  segmentGroup: SegmentGroup;
  openForm: (segment?: Segment) => void;
}

const SegmentsOverview: React.FunctionComponent<IProps> = ({ segmentGroup, openForm }: IProps) => {
  const { dispatch, state } = useContext(AppContext);
  const queryClient = useQueryClient();

  const brandId = getBrandId(state.selectedBrand);

  const syncGroupMutation = useMutation(SyncGroupMutation, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${segmentGroup.name} group synced`
        }
      });
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.SegmentGroups, brandId])
  });

  const deleteSegmentMutation = useMutation(DeleteSegmentMutation, {
    onSuccess: ({ data }) => {
      const segment = data as Segment;
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Successfully deleted ${segment.name} segment`
        }
      });
      queryClient.setQueryData<SegmentGroup[]>([QueryKey.SegmentGroups, brandId], (oldData) =>
        oldData
          ? {
              ...oldData,
              //@ts-ignore
              data: oldData.data.map((sg) =>
                sg.id === segmentGroup.id
                  ? {
                      ...sg,
                      segments: segmentGroup.segments.filter((s) => s.id !== segment.id)
                    }
                  : sg
              )
            }
          : oldData
      );
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.SegmentGroups, brandId])
  });

  const deleteSegment = (segment: Segment) => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        agreeAction: () => {
          dispatch({
            type: Actions.HideConfirmation
          });
          deleteSegmentMutation.mutate(segment.id);
        },
        text: `Are you sure you want to delete ${segment.name} segment?`
      }
    });
  };

  const isActiveMutation = useMutation(SetIsSegmentActiveMutation, {
    onSuccess: ({ data }) => {
      const segment = data as Segment;
      const resultText = segment.isActive ? 'activated' : 'deactivated';
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${segment.name} segment ${resultText}`
        }
      });
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.SegmentGroups, brandId])
  });

  const customActions = ({ rowData }: { rowData: Segment }) => {
    const iconButtonProps = {
      height: '48px',
      width: '48px',
      marginRight: '16px'
    };
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        {rowData.skipSync === false ? (
          <IconButton onClick={() => openForm(rowData)} sx={{ ...iconButtonProps, marginRight: 0 }}>
            <EditOutlined sx={{ color: 'primary.main' }} />
          </IconButton>
        ) : null}
        <IconButton
          onClick={() => deleteSegment(rowData)}
          sx={{ ...iconButtonProps, marginRight: 0 }}
        >
          <DeleteOutlineOutlined sx={{ color: 'error.main' }} />
        </IconButton>
      </Box>
    );
  };

  const colums = [
    {
      title: 'Segment Name',
      field: 'name'
    },
    {
      title: 'Description',
      field: 'description'
    },
    {
      title: 'Members',
      field: 'members',
      type: 'numeric'
    },
    {
      title: 'State',
      render: (data: Segment) => (
        <FormControlLabel
          control={
            <Switch
              checked={data.isActive}
              color="success"
              onChange={() =>
                isActiveMutation.mutate({
                  id: data.id,
                  isActive: !data.isActive
                })
              }
            />
          }
          label={data.isActive ? 'Active' : 'Inactive'}
        />
      )
    },
    {
      title: 'Last sync date',
      field: 'syncDateTime',
      render: (data: Segment) =>
        data.syncDateTime
          ? displayText(moment(data.syncDateTime).format('YYYY/MM/DD HH:mm'))
          : displayText('Not Synced yet')
    },
    {
      title: 'Last Sync Status',
      render: (data: Segment) => (
        <Box>
          <StatusIdicator
            type={data.syncDateTime ? (data.lastSyncSuccess ? 'success' : 'fail') : 'info'}
          />
          {data.syncDateTime ? (data.lastSyncSuccess ? 'Success' : 'Fail') : 'Not Synced'}
        </Box>
      )
    }
  ] as IHeadCell[];

  const getSyncButton = () => {
    return (
      <IconButton
        sx={{ color: colors.gradients.pinkRgb }}
        onClick={() => syncGroupMutation.mutate(segmentGroup.id)}
      >
        <Typography variant="button">
          <Refresh
            className={syncGroupMutation.isLoading ? 'rotate-icon' : ''}
            sx={{
              width: '20px',
              height: '20px',
              position: 'relative',
              top: '5px'
            }}
          />
          Sync
        </Typography>
      </IconButton>
    );
  };

  return (
    <Box>
      <CustomTable
        toolbarTitle={segmentGroup.name}
        columns={colums}
        data={segmentGroup.segments}
        Actions={customActions}
        showSearch
        filters={getSyncButton()}
        createButtonProps={{
          id: 'add-user-button',
          onClick: () => openForm(),
          text: '+ Add Segment',
          disabled: false
        }}
      />
      <Loader loading={deleteSegmentMutation.isLoading || isActiveMutation.isLoading} />
    </Box>
  );
};

export default SegmentsOverview;

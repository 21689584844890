import { Email } from '@mui/icons-material';
import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { ForgetPassQuery } from '../../queries/account';
import { getIconColor } from '../../utils/ui';
import { isValidEmail } from '../../utils/validators';
import CancelButton from '../Common/Buttons/CancelButton';
import MainButton from '../Common/Buttons/MainButton';
import InputComponent from '../Common/Fields/InputComponent';
import DialogBox from '../Common/Layout/DialogBox';
import ModalHeader from '../Common/Layout/ModalHeader';

interface IProps {
  handleClose: () => void;
}

type IErrors = {
  email: string | null;
};

const defaultErrorsObj: IErrors = {
  email: null
};

const ErrorTexts = {
  email: 'Email not valid'
};

const ForgotPasswordPage: React.FunctionComponent<IProps> = ({ handleClose }) => {
  const { dispatch } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const { mutate, isLoading } = useMutation(ForgetPassQuery);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      email: isValidEmail(value) ? null : ErrorTexts.email
    });
    setEmail(value);
  };

  const handleSend = () => {
    if (isValidEmail(email)) {
      mutate(email);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: 'Email with reset password link was sent'
        }
      });
      handleClose();
    }
  };

  const close = () => {
    if (!isLoading) {
      handleClose();
    }
  };

  return (
    <DialogBox
      width={600}
      title="Forgot Password?"
      subTitle="Enter your e-mail address and we will send you an e-mail with a link to reset the password for your account."
      onClose={close}
    >
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
        {/* <ModalHeader
          title="Forgot Password?"
          subtitle="Enter your e-mail address and we will send you an e-mail with a link to reset the password for your account."
          onClose={close}
        /> */}
        <InputComponent
          id="forgot-password-email"
          label="Email"
          placeholder="Enter your email address"
          value={email}
          onChange={handleEmailChange}
          disabled={isLoading}
          errorMsg={formErrors.email}
          startIcon={
            <Email
              sx={{
                fill: getIconColor(email),
                zIndex: 2
              }}
            />
          }
        />
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <CancelButton id="forgot-password-cancel" text="Cancel" onClick={handleClose} />
          <MainButton
            id="forgot-password-send"
            text="Send"
            onClick={handleSend}
            disabled={!isValidEmail(email)}
          />
        </Box>
      </Box>
    </DialogBox>
  );
};

export default ForgotPasswordPage;

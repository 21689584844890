import { Box } from '@mui/material';
import React from 'react';
import { colors } from '../../../utils/theme';

interface IProps {
  type: 'success' | 'fail' | 'info';
}

const StatusIdicator: React.FunctionComponent<IProps> = ({ type }: IProps) => {
  const getColor = () => {
    if (type === 'success') {
      return colors.green.second;
    } else if (type === 'fail') {
      return colors.error.second;
    } else {
      return 'blue';
    }
  };

  return (
    <Box
      sx={{
        width: '12px',
        height: '12px',
        backgroundColor: getColor(),
        display: 'inline-block',
        borderRadius: '50px',
        marginRight: '4px'
      }}
    ></Box>
  );
};

export default StatusIdicator;

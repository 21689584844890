import { Actions } from '../enums/ActionEnums';
import { RoleModel } from '../models/account';
import { ActionType } from '../models/appContext';

export const rolesReducer = (state: RoleModel[], action: ActionType): RoleModel[] => {
  switch (action.type) {
    case Actions.SetRoles:
      return action.payload;
    default:
      return state;
  }
};

import React from "react";
import { Box } from "@mui/material";
import IgaStep from "./IgaStep";

interface IProps {
  steps: string[];
  activeStep: number;
  children: React.ReactNode;
}

const IgaStepper: React.FunctionComponent<IProps> = ({
  steps,
  activeStep,
  children,
}: IProps) => {
  const getStepState = (
    index: number
  ): "completed" | "active" | "following" => {
    if (index === activeStep) {
      return "active";
    } else if (index < activeStep) {
      return "completed";
    } else {
      return "following";
    }
  };
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(12, minmax(0, 1fr))",
        gap: "24px",
      }}
    >
      <Box sx={{ gridColumn: "span 2" }}>
        {steps.map((step, index) => (
          <IgaStep
            key={index}
            title={step}
            state={getStepState(index)}
            isLast={index + 1 === steps.length}
          />
        ))}
      </Box>
      <Box sx={{ gridColumn: "span 10" }}>{children}</Box>
    </Box>
  );
};

export default IgaStepper;

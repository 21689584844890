import { Avatar, Box, Typography, IconButton } from "@mui/material";
import React, { useContext } from "react";
import MainButton from "../Common/Buttons/MainButton";
//import image from "../../assets/76634.jpg";
import { Delete } from "@mui/icons-material";
import { CategoryEnums } from "../../enums/CategoryEnums";
import { deleteCommentMutation } from "../../queries/comment";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AppContext } from "../../AppContext";
import { handleRequestError } from "../../utils/ui";
import { Actions } from "../../enums/ActionEnums";
import { QueryKey } from "../../enums/HttpRequestKeyEnums";
import ColoredText from "../Common/Other/ColoredText";
import { colors } from "../../utils/theme";

type IProps = {
  id: number;
  author: string;
  dateTime: Date;
  category: string;
  content: string;
};

const Comment: React.FunctionComponent<IProps> = ({
  id,
  author,
  dateTime,
  category,
  content,
}: IProps) => {
  let backGroundColor;
  switch (category) {
    case CategoryEnums.General:
      backGroundColor = "blue";
      break;
    case CategoryEnums.FollowUp:
      backGroundColor = "orange";
      break;
    case CategoryEnums.Important:
      backGroundColor = colors.gradients.pinkRgb;
      break;
  }
  const queryKey = [QueryKey.Comments];
  const { dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const deleteComment = useMutation(deleteCommentMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
      dispatch({
        type: Actions.HideConfirmation,
      });
    },
    onSettled: () => queryClient.invalidateQueries(queryKey),
    onSuccess: () => {
      dispatch({
        type: Actions.HideConfirmation,
      });
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: "success",
          text: `Comment deleted successfully!`,
        },
      });
    },
  });

  const handleRemove = () => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `Are you sure you want to delete this comment?`,
        agreeAction: handleDelete,
        params: { id, author },
      },
    });
  };

  const handleDelete = () => {
    deleteComment.mutate({ id, author });
  };

  const FullNameToInitials = (author: string) => {
    const initials = author
      .split(" ")
      .map((word: string) => word.charAt(0))
      .join("");

    return initials;
  };

  const formatDate = (dateTime: Date) => {
    const dt = new Date(dateTime);
    const day = dt.getDate().toString().padStart(2, "0");
    const month = (dt.getMonth() + 1).toString().padStart(2, "0");
    const year = dt.getFullYear().toString();
    const hour = dt.getHours().toString().padStart(2, "0");
    const minutes = dt.getMinutes().toString().padStart(2, "0");

    const formattedDateTime = `${day}.${month}.${year} | ${hour}:${minutes}`;
    return formattedDateTime;
  };

  const iconButtonProps = {
    height: "48px",
    width: "48px",
    marginRight: "16px",
  };
  return (
    <Box sx={{ p: "24px" }}>
      <Box
        sx={{ display: "flex", gap: "24px", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box>
            <Avatar sx={{ bgcolor: "#D3137D", width: "48px", height: "48px" }}>
              {FullNameToInitials(author)}
            </Avatar>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "14",
                  fontWeight: "600",
                  lineHeight: "140%",
                  textAlign: "left",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  mb: 0,
                }}
                color="primary.main"
                gutterBottom
              >
                {author}
              </Typography>
            </Box>
            <Box>
              {" "}
              <Typography
                sx={{
                  fontSize: "12",
                  fontWeight: "400",
                  lineHeight: "140%",
                  textAlign: "left",
                  fontStyle: "normal",
                  fontFamily: "Inter",
                  color: "#ACAABA",
                  mb: 0,
                }}
                gutterBottom
              >
                {formatDate(dateTime)}
                <Box>
                  <ColoredText
                    text={category}
                    color={backGroundColor}
                    sx={{
                      padding: "4px 6px",
                      fontWeight: "600",
                      lineHeight: "16px",
                      textTransform: "capitalize",
                      display: "inline-flex",
                      fontSize: "12px",
                    }}
                  />
                </Box>
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", mt: "-12px" }}>
          <IconButton
            onClick={() => {
              handleRemove();
            }}
            sx={{ ...iconButtonProps, marginRight: 0 }}
          >
            <Delete sx={{ color: "error.main" }} />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <Typography
          sx={{
            fontSize: "16",
            fontWeight: "400",
            lineHeight: "140%",
            textAlign: "left",
            fontStyle: "normal",
            fontFamily: "Inter",
            color: "#ACAABA",
            mt: "14px",
          }}
          color="primary.main"
          gutterBottom
        >
          {content}
        </Typography>
      </Box>
    </Box>
  );
};

export default Comment;

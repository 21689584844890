import React, { forwardRef } from 'react';
import { Box, Divider, IconButton } from '@mui/material';
import './index.css';
import { Delete, Edit, Visibility } from '@mui/icons-material';
import { colors } from '../../../../utils/theme';
import { Segment, SegmentGroup } from '../../../../models/segmets';

interface IProps {
  group: SegmentGroup;
  segments: Segment[];
  showDetails: (id: number) => void;
  handleDelete: (id: number) => void;
  handleEdit: (id: number) => void;
}

const SegmentGroup: React.FunctionComponent<IProps> = forwardRef(function SegmentGroup(props, ref) {
  const buttonSize = '24px';
  const iconButtonSize = '30px';
  const { group, showDetails, handleEdit, handleDelete } = props;
  return (
    <Box {...props} ref={ref} className="segment-group-box hover-box-border">
      <Box className="segment-group-box-name">{group.name}</Box>
      <Box className="count-badge">{props.segments.length} Segments</Box>
      <Box sx={{ mt: 'auto' }}>
        <Divider />
        <Box className="segment-group-box-actions">
          <IconButton
            sx={{
              width: iconButtonSize,
              height: iconButtonSize,
              color: colors.gradients.pinkRgb
            }}
            onClick={() => handleDelete(group.id)}
          >
            <Delete sx={{ width: buttonSize, height: buttonSize }} />
          </IconButton>
          <IconButton
            sx={{
              width: iconButtonSize,
              height: iconButtonSize,
              color: colors.gradients.pinkRgb
            }}
            onClick={() => handleEdit(group.id)}
          >
            <Edit sx={{ width: buttonSize, height: buttonSize }} />
          </IconButton>
          <IconButton
            sx={{
              width: iconButtonSize,
              height: iconButtonSize,
              color: colors.gradients.pinkRgb
            }}
            onClick={() => showDetails(group.id)}
          >
            <Visibility sx={{ width: buttonSize, height: buttonSize }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
});

export default SegmentGroup;

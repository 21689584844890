import { Box, Link } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import Header from '../Common/Layout/Header';
import CustomTable, { IHeadCell } from '../Common/Tables/CustomTable';
import { IPlayer } from '../../models/player';
import { useQuery } from '@tanstack/react-query';
import { GetBrandPlayersQuery, GetPlayersPartialQuery } from '../../queries/players';
import { getBrandDisplayName, getBrandId, handleRequestError } from '../../utils/ui';
import { AppContext } from '../../AppContext';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthRoutes } from '../../enums/RouteEnums';
import Loader from '../Common/Global/Loader';

const PlayerCardsOverview: React.FunctionComponent = () => {
  const { state, dispatch } = useContext(AppContext);
  const brand = getBrandDisplayName(state.selectedBrand);
  const brandId = getBrandId(state.selectedBrand);
  const location = useLocation();
  const navigate = useNavigate();
  const [refetchPartial, setRefetchPartial] = useState(true);
  const playersPartialQuery = useQuery({
    queryKey: [QueryKey.PlayersPartial, brandId],
    queryFn: () => GetPlayersPartialQuery(brand),
    onSuccess: ({ data }) => {
      setPlayers(data);
    },
    refetchInterval: 1 * 60 * 1000,
    enabled: refetchPartial,
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    }
  });
  const partialCount = playersPartialQuery.data?.data.length === 1000;
  const playersQuery = useQuery({
    queryKey: [QueryKey.Players, brandId],
    queryFn: () => GetBrandPlayersQuery(brand),
    onSuccess: ({ data }) => {
      setPlayers(data);
      setRefetchPartial(false);
    },
    enabled: partialCount,
    refetchInterval: 1 * 60 * 1000,
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    }
  });
  const [players, setPlayers] = useState<IPlayer[]>(
    playersQuery.data?.data || playersPartialQuery.data?.data || []
  );

  useEffect(() => {
    if (playersPartialQuery.data?.data) {
      setPlayers(playersPartialQuery.data.data);
    }
    if (playersQuery.data?.data) {
      setPlayers(playersQuery.data.data);
    }
  }, [playersQuery.data, playersPartialQuery.data]);

  const handleDetails = (rowData: IPlayer) => {
    navigate(`${location.pathname}/${rowData.id}`, {
      state: { playerCard: rowData, path: AuthRoutes.PlayersCards }
    });
  };

  const userColumns = [
    {
      title: 'ID Number',
      field: 'id',
      render: (data: IPlayer) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {data.id}
        </Link>
      ),
      customFilterAndSearch: (filter: string, data: IPlayer) =>
        data.id.toLowerCase().includes(filter.toLowerCase())
    },
    {
      title: 'Full Name',
      field: 'fullName',
      render: (data: IPlayer) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {`${data.firstName} ${data.lastName}`}
        </Link>
      ),
      customFilterAndSearch: (filter: string, data: IPlayer) =>
        `${data.firstName} ${data.lastName}`.toLowerCase().includes(filter.toLowerCase())
    },
    {
      title: 'Email',
      field: 'email'
    }
  ] as IHeadCell[];

  return (
    <Box className="nav-page">
      <Box sx={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
        <Box>
          <Header text="Search Players" />
          <Header text="Type a user ID and check his card" isSubHeader={true} />
        </Box>
      </Box>
      <CustomTable
        toolbarTitle={'Player Cards'}
        handleDetails={handleDetails}
        data={players}
        columns={userColumns}
        showSearch
        loading={playersQuery.isLoading}
      />
      <Loader loading={playersPartialQuery.isLoading} />
    </Box>
  );
};

export default PlayerCardsOverview;

import React from 'react';
import { Button, SxProps } from '@mui/material';

interface IProps {
  id: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  isSubmit?: boolean;
  sx?: SxProps;
}

const MainButton: React.FunctionComponent<IProps> = ({
  id,
  text,
  onClick,
  disabled = false,
  isSubmit = false,
  sx
}) => {
  return (
    <Button
      id={id}
      {...(isSubmit && { type: 'submit' })}
      fullWidth
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      {...(sx && { sx: { ...sx } })}
    >
      {text}
    </Button>
  );
};

export default MainButton;

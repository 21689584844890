import React, { useContext } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { Box, Grid, IconButton } from '@mui/material';
import { Close, CheckCircleOutline, WarningAmber, GppBad, Info } from '@mui/icons-material';
import { colors } from '../../../utils/theme';

type Colors = {
  [key: string]: string;
};
const messageColors: Colors = {
  success: colors.green.second,
  error: colors.error.second,
  warning: colors.warning.main,
  info: colors.info.main
};

const messageBackgrounds: Colors = {
  success: '#E6F7D0',
  error: '#FBCDCD',
  warning: '#f7bc54',
  info: '#E8F5FF'
};

export default function CustomizedSnackbars() {
  const { state, dispatch } = useContext(AppContext);

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch({ type: Actions.HideMessage });
  };

  const getIcon = () => {
    switch (state.message.severity) {
      case 'success':
        return <CheckCircleOutline sx={{ color: messageColors[state.message.severity] }} />;
      case 'warning':
        return <WarningAmber sx={{ color: messageColors[state.message.severity] }} />;
      case 'error':
        return <GppBad sx={{ color: messageColors[state.message.severity] }} />;
      default:
        return <Info sx={{ color: messageColors['info'] }} />;
    }
  };
  return (
    <Snackbar
      open={state.message.open}
      autoHideDuration={state.message.autoHide}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          p: '16px',
          background: 'white',
          boxShadow: '0px 10px 30px rgba(3, 30, 68, 0.1)',
          borderRadius: '8px',
          borderLeft: `4px solid ${messageColors[state.message.severity ?? 'info']}`,
          gap: '10px'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50px',
              width: '32px',
              height: '32px',
              backgroundColor: messageBackgrounds[state.message.severity ?? 'info']
            }}
          >
            {getIcon()}
          </Box>
          <Box sx={{ fontWeight: 700, fontSize: '14px', display: 'flex', alignItems: 'center' }}>
            {state.message.text}
          </Box>
        </Box>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'relative',
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <Close />
        </IconButton>
      </Box>
    </Snackbar>
  );
}

import React, { useContext, useState } from 'react';
import DialogBox from '../../Common/Layout/DialogBox';
import IgaStepper from '../../Common/Layout/Stepper/IgaStepper';
import { Box, SelectChangeEvent } from '@mui/material';
import CancelButton from '../../Common/Buttons/CancelButton';
import MainButton from '../../Common/Buttons/MainButton';
import PromoDetails from './PromoStepper/PromoDetails';
import { IPromotion, IPromotionGroup, PromotionModel } from '../../../models/promotion';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { Segment } from '../../../models/segmets';
import { CreatePromo, UpdatePromotion } from '../../../queries/promotion';
import { SegmentGroupsQuery } from '../../../queries/segment';
import { getBrandId, getActiveSegments, handleRequestError } from '../../../utils/ui';
import Loader from '../../Common/Global/Loader';
import SegmentGroup from '../Segments/SegmentGroup/SegmentGroup';
import ContentStep from './PromoStepper/ContentStep';
import SegmentsStep from './PromoStepper/SegmentsStep';
import TimeFrameStep from './PromoStepper/TimeFrameStep';

dayjs.extend(utc);

interface IProps {
  promotion?: IPromotion;
  onClose: () => void;
  groups: IPromotionGroup[];
}

const PromotionForm: React.FunctionComponent<IProps> = ({ promotion, onClose, groups }: IProps) => {
  const queryClient = useQueryClient();
  const steps = ['Details', 'Segments', 'Content', 'Time frame'];
  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState<string>(promotion?.name ?? '');
  const [groupName, setGroupName] = useState(promotion?.group?.name ?? '');
  const [channel, setChannel] = useState(promotion?.channel ?? '');
  const [emailSender, setEmailSender] = useState<number | null>(promotion?.emailSenderId ?? null);
  const [template, setTemplate] = useState<string | null>(promotion?.emailTemplateId ?? null);
  const [smsText, setSmsText] = useState(promotion?.smsText ?? null);
  const [startDate, setStartDate] = useState(promotion?.startDate ?? null);
  const [endDate, setEndDate] = useState(promotion?.endDate ?? null);
  const [hasTimePolicy, setHasTimePolicy] = useState(promotion?.hasTimePolicy ?? false);
  const [timeZone, setTimeZone] = useState(promotion?.timeZone ?? null);
  const [dateHours, setDateHours] = useState(promotion?.dateHours ?? []);
  const [hasFrequency, setHasFrequency] = useState(promotion?.hasFrequency ?? false);
  const [frequencyTime, setFrequencyTime] = useState(promotion?.frequencyTime ?? null);
  const [frequencyType, setFrequencyType] = useState<string | null>(
    promotion?.frequencyType ?? null
  );
  const [frequencyPerUser, setFrequencyPerUser] = useState<number | null>(
    promotion?.frequencyPerUser ?? null
  );
  const [overrideBlockedSegments, setOverrideBlockedSegments] = useState(
    promotion?.overrideBlockedSegments ?? false
  );
  const [includedSegmentIds, setIncludedSegmentIds] = useState(promotion?.includedSegmentIds ?? []);
  const [excludedSegmentIds, setExcludedSegmentIds] = useState(promotion?.excludedSegmentIds ?? []);

  const segmentsQuery = useQuery({
    queryKey: [QueryKey.SegmentGroups, brandId],
    queryFn: () => SegmentGroupsQuery(brandId),
    onSuccess: ({ data }) => {
      const segments = [] as Segment[];
      (data as SegmentGroup[]).map((sg) => segments.push(...sg.segments));
      setSegments(getActiveSegments(data as SegmentGroup[]));
    }
  });

  const createPromo = useMutation(CreatePromo, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Created ${data.name} promotion`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.Promotions, brandId])
  });

  const updatePromo = useMutation(UpdatePromotion, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Updated ${data.name} promotion`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.Promotions, brandId])
  });

  const [segments, setSegments] = useState<Segment[]>(
    getActiveSegments((segmentsQuery.data?.data as SegmentGroup[]) || [])
  );

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
  };

  const handleGroupChage = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;
    if (value) {
      setGroupName(value as string);
    }
  };

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <PromoDetails
            name={name}
            onNameChange={handleNameChange}
            group={groupName}
            handleGroupChage={handleGroupChage}
            groups={groups}
          />
        );
      case 1:
        return (
          <SegmentsStep
            includedIds={includedSegmentIds}
            setIncludedIds={setIncludedSegmentIds}
            excludedIds={excludedSegmentIds}
            setExcludedIds={setExcludedSegmentIds}
            segments={segments}
          />
        );
      case 2:
        return (
          <ContentStep
            channel={channel}
            setChannel={setChannel}
            emailSender={emailSender}
            setEmailSender={setEmailSender}
            template={template}
            setTemplate={setTemplate}
            smsText={smsText}
            setSmsText={setSmsText}
          />
        );
      case 3:
        return (
          <TimeFrameStep
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            hasTimePolicy={hasTimePolicy}
            setHasTimePolicy={setHasTimePolicy}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
            dateHours={dateHours}
            setDateHours={setDateHours}
            hasFrequency={hasFrequency}
            setHasFrequency={setHasFrequency}
            frequencyTime={frequencyTime}
            setFrequencyTime={setFrequencyTime}
            frequencyType={frequencyType}
            setFrequencyType={setFrequencyType}
            frequencyPerUser={frequencyPerUser}
            setFrequencyPerUser={setFrequencyPerUser}
            overrideBlockedSegments={overrideBlockedSegments}
            setOverrideBlockedSegments={setOverrideBlockedSegments}
          />
        );
      default:
        return <h3>Step not found</h3>;
    }
  };

  const stepIsValid = (): boolean => {
    switch (activeStep) {
      case 0:
        return name.length > 2 && groupName.length > 2;
      case 1:
        return true;
      case 2:
        if (channel === 'Email') {
          return emailSender !== null && template !== null;
        } else {
          return smsText !== null && smsText.length > 2;
        }
      case 3:
        return startDate !== null && endDate !== null && timeZone !== null;
      default:
        return false;
    }
  };

  const handleNextClicked = () => {
    if (activeStep + 1 === steps.length) {
      const input = {
        name,
        channel,
        groupId: groups.find((g) => g.name === groupName)?.id,
        emailSenderId: emailSender,
        emailTemplateId: template,
        smsText,
        startDate: dayjs(startDate).set('second', 0).utc(true).format(),
        endDate: dayjs(endDate).set('second', 0).utc(true).format(),
        hasTimePolicy,
        timeZone,
        hasFrequency,
        dateHours,
        frequencyTime,
        frequencyType,
        frequencyPerUser,
        overrideBlockedSegments,
        includedSegmentIds,
        excludedSegmentIds
      } as PromotionModel;
      if (promotion) {
        updatePromo.mutate({ id: promotion.id, input });
      } else {
        createPromo.mutate({ brandId, input });
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  return (
    <DialogBox
      width={1400}
      onClose={onClose}
      title={promotion ? 'Edit Promotion' : 'Create New Promotion'}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <IgaStepper steps={steps} activeStep={activeStep}>
          {getStepContent()}
        </IgaStepper>
        <Box className="form-actions" sx={{ mt: '16px' }}>
          <Box className="form-group-buttons">
            <CancelButton
              id="promo-back"
              text="Back"
              onClick={() => setActiveStep(activeStep - 1)}
              disabled={activeStep === 0}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton
              id="promo-next"
              text={activeStep + 1 === steps.length ? 'Save' : 'Next'}
              onClick={handleNextClicked}
              disabled={!stepIsValid()}
            />
          </Box>
        </Box>
      </Box>
      <Loader loading={createPromo.isLoading || updatePromo.isLoading} />
    </DialogBox>
  );
};

export default PromotionForm;

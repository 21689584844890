import React from 'react';
import { Button, SxProps } from '@mui/material';
import { colors } from '../../../utils/theme';

interface IProps {
  id: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  sx?: SxProps;
}

const CancelButton: React.FunctionComponent<IProps> = ({
  id,
  text,
  onClick,
  disabled = false,
  sx
}) => {
  return (
    <Button
      id={id}
      fullWidth
      sx={{
        border: `1px solid ${colors.primary.main}`,
        color: colors.primary.main,
        background: colors.white,
        '&:hover': {
          border: `1px solid ${colors.primary.main}`,
          backgroundColor: colors.primary.main,
          color: colors.white
        },
        ...sx
      }}
      color="info"
      disabled={disabled}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default CancelButton;

import React, { useState } from 'react';
import DialogBox from '../../../Common/Layout/DialogBox';
import Header from '../../../Common/Layout/Header';
import { Box } from '@mui/material';
import CancelButton from '../../../Common/Buttons/CancelButton';
import MainButton from '../../../Common/Buttons/MainButton';
import InputComponent from '../../../Common/Fields/InputComponent';
import { isValidEmail } from '../../../../utils/validators';

interface IProps {
  onClose: () => void;
  handleSend: (email: string) => void;
}

const TestEmailForm: React.FunctionComponent<IProps> = ({ onClose, handleSend }: IProps) => {
  const [email, setEmail] = useState('');
  return (
    <DialogBox width={600} onClose={onClose} title="Send Test Email">
      <Header isSubHeader text="Enter the email address that you want to send the email preview" />
      <Box>
        <InputComponent
          id="email"
          label="Email"
          value={email}
          onChange={(event) => setEmail(event.currentTarget.value)}
        />

        <Box className="form-actions" sx={{ mt: '24px' }}>
          <Box className="form-group-buttons">
            <CancelButton
              id="segment-cancel"
              text="Cancel"
              onClick={onClose}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton
              id="segment-create"
              text="Send"
              onClick={() => handleSend(email)}
              disabled={!isValidEmail(email)}
            />
          </Box>
        </Box>
      </Box>
    </DialogBox>
  );
};

export default TestEmailForm;

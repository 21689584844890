import axios from 'axios';
import { AppConsts } from '../enums/AppConsts';
import { IDeleteUserRole, INewUser, IUpdateUserAccount, IUser } from '../models/user';

const baseUrl = process.env.BASE_SERVER_ADDRESS;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);

const getHeaders = () => ({
  headers: { Authorization: `Bearer ${getToken()}` }
});

export const getCompanyUsersQuery = async () => {
  return await axios.get(`${baseUrl}/User`, getHeaders());
};

export const createUserMutation = async (data: INewUser) => {
  return await axios.post(`${baseUrl}/User/create`, data, getHeaders());
};

export const deleteUserMutation = async (id: string) => {
  return await axios.delete(`${baseUrl}/User/delete/${id}`, getHeaders());
};

export const updateUserMutation = async (data: IUser) => {
  return await axios.post(`${baseUrl}/User/update/${data.id}`, data, getHeaders());
};

export const updateUserAccountMutation = async (data: IUpdateUserAccount) => {
  return await axios.post(`${baseUrl}/User/updateaccount`, data, getHeaders());
};

export const deleteUserRoleMutation = async (data: IDeleteUserRole) => {
  return await axios.delete(`${baseUrl}/User/deleteUserRole/${data.id}/${data.role}`, getHeaders());
};

import React, { useContext, useState } from 'react';
import DialogBox from '../../Common/Layout/DialogBox';
import { Box, Button, ListItemText, MenuItem, SelectChangeEvent, Typography } from '@mui/material';
import InputComponent from '../../Common/Fields/InputComponent';
import SelectComponent from '../../Common/Fields/SelectComponent';
import { SegmentCriteria } from '../../../models/segmets';
import SegmentAttribute from '../../CampaignManager/Segments/SegmentAttribute';
import { colors } from '../../../utils/theme';
import CancelButton from '../../Common/Buttons/CancelButton';
import MainButton from '../../Common/Buttons/MainButton';
import AddButton from '../../Common/Buttons/AddButton';
import { PublishedWithChanges } from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CalculateSegmentMutation } from '../../../queries/segment';
import { getBrandDisplayName, getBrandId, handleRequestError } from '../../../utils/ui';
import { AppContext } from '../../../AppContext';
import { IUser } from '../../../models/user';
import { getCompanyUsersQuery } from '../../../queries/user';
import { ICard, IFilterCard } from '../../../models/card';
import { canUserCreate } from '../../../utils/rights';
import { Restriction } from '../../../enums/RouteEnums';
import { UpdateFilterCardMutation, createFilterCardMutation } from '../../../queries/filterCard';
import { Actions } from '../../../enums/ActionEnums';
import Loader from '../../Common/Global/Loader';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';

interface IProps {
  onClose: () => void;
  cardInput?: IFilterCard | null;
}

export type IErrors = {
  name: string | null;
  selectedColor: string | null;
  criterias: string | null;
};

const defaultErrorsObj: IErrors = {
  name: null,
  selectedColor: null,
  criterias: null
};

const errorTexts = {
  name: 'Card name too short!',
  selectedColor: 'Invalid color!',
  criterias: 'Invalid criteria!'
};

const gap = '24px';

const colorOptions = [
  { value: '#F28F3F', label: 'Yellow' },
  { value: '#E83E39', label: 'Orange' },
  { value: colors.pink.main, label: 'Coral' },
  { value: colors.info.main, label: 'Pink' },
  { value: '#3364E1', label: 'Blue' },
  { value: colors.pink.background, label: 'Light Pink' }
];
const initialCriteria = { name: '', operator: '', value: '' } as SegmentCriteria;

const FilterCardForm: React.FunctionComponent<IProps> = ({ onClose, cardInput }: IProps) => {
  const [name, setName] = useState(cardInput?.name || '');
  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [colorTypes] = useState(colorOptions);
  const queryClient = useQueryClient();
  const queryKeyUsers = [QueryKey.Users];
  const queryKeyFilterCards = [QueryKey.FilterCards, state.user.data?.email, brandId];
  const { data } = useQuery(queryKeyUsers, getCompanyUsersQuery, {
    onSuccess: ({ data }) => {
      setUsers(data);
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    }
  });
  const [users, setUsers] = useState<IUser[]>(data?.data || []);
  const [calculated, setCalculated] = useState<number | null>(null);
  const [criterias, setCriterias] = useState<SegmentCriteria[]>(
    cardInput?.criterias ?? [initialCriteria]
  );
  const [color, setColor] = useState(
    colorOptions.find((co) => co.value === cardInput?.color)?.label || ''
  );
  const [assignedUsers, setAssignedUsers] = useState(cardInput?.assignedUserIds || []);

  const createFilterCard = useMutation(createFilterCardMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `FilterCard ${data.name} created`
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKeyFilterCards)
  });

  const updateFilterCard = useMutation(UpdateFilterCardMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `FilterCard ${data.name} updated`
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKeyFilterCards)
  });

  const getMinHeight = () => {
    const res = criterias.length * 85 > 350 ? 350 : criterias.length * 85;
    return `${res}px`;
  };

  const handleCardNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      name: value && value.length > 0 ? null : errorTexts.name
    });
    setName(value);
  };

  const handleColorChange = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;
    setFormErrors({
      ...formErrors,
      selectedColor: value && value.length > 0 ? null : errorTexts.selectedColor
    });
    if (value) {
      setColor(value as string);
    }
  };

  const handleUserChange = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;
    if (value) {
      setAssignedUsers(value as []);
    }
  };

  const addEmptyCriteria = () => {
    setCalculated(null);
    setCriterias([...criterias, initialCriteria]);
  };

  const removeCriteria = (index: number) => {
    setCalculated(null);
    const items = [...criterias];
    items.splice(index, 1);
    setCriterias(items);
  };

  const handleCriteriaChange = (index: number, criteria: SegmentCriteria) => {
    const items = [...criterias];
    items[index] = criteria;
    setFormErrors({
      ...formErrors,
      criterias: criteria && criteria.name.length > 0 ? null : errorTexts.criterias
    });
    setCriterias(items);
  };

  const hasInvalidCriteria = () => {
    return criterias.some(
      (cr) => cr.name.length === 0 || cr.operator.length === 0 || cr.value.length === 0
    );
  };

  const calculateSegment = useMutation(CalculateSegmentMutation, {
    onSuccess: ({ data }) => setCalculated(data as number),
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const handleSave = (type: 'create' | 'update') => {
    const ids = users.filter((u) => assignedUsers.includes(u.email)).map((u) => u.id);
    const data = {
      name,
      color: colorOptions.find((co) => co.label === color)?.value,
      criterias,
      assignedUserIds: ids
    } as ICard;

    if (type === 'create') {
      createFilterCard.mutate({ brandId, data });
    } else {
      updateFilterCard.mutate({ cardId: cardInput?.id as number, data });
    }
  };

  const handleSubmit = () => {
    const trimmedCardName = name ? name.trim() : '';
    const errors = {
      name: null,
      selectedColor: null,
      criterias: null
    } as IErrors;
    let hasErrors = false;
    if (trimmedCardName.length === 0) {
      hasErrors = true;
      errors.name = errorTexts.name;
    }
    if (color.length === 0) {
      hasErrors = true;
      errors.selectedColor = errorTexts.selectedColor;
    }
    if (criterias.length === 0) {
      hasErrors = true;
      errors.criterias = errorTexts.criterias;
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      handleSave(cardInput === undefined ? 'create' : 'update');
    }
  };

  return (
    <DialogBox
      width={800}
      onClose={onClose}
      title={cardInput ? 'Edit Card Filter' : 'Create New Card Filter'}
      subTitle="Add one or more rules and set color"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: gap
        }}
      >
        <Box sx={{ display: 'flex', columnGap: gap }}>
          <InputComponent
            id="card-name"
            label="Card Name"
            errorMsg={formErrors.name}
            value={name}
            onChange={handleCardNameChange}
            containerStyles={{ flex: 1 }}
          />
          <SelectComponent
            label="Color"
            placeholder="Select"
            value={color}
            errorMsg={formErrors.selectedColor}
            isSingle
            onChange={handleColorChange}
            customOptions={colorTypes?.map((colorOption) => (
              <MenuItem
                key={colorOption.label}
                value={colorOption.label}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: colors.pink.background
                  },
                  '&:hover': { backgroundColor: colors.pink.background },
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Box
                  sx={{
                    width: '16px',
                    height: '16px',
                    backgroundColor: colorOption.value || 'transparent',
                    border: `1px solid ${colors.grey}`,
                    borderRadius: '2px',
                    mr: '8px'
                  }}
                />
                <ListItemText primary={colorOption.label} />
              </MenuItem>
            ))}
            containerStyles={{ flex: 1 }}
            hideNoneValue
          />
        </Box>
        {canUserCreate(state.user, Restriction.Lead) ? (
          <SelectComponent
            label="Assign To"
            placeholder="Select User"
            value={assignedUsers}
            isSingle={false}
            onChange={handleUserChange}
            options={users
              .filter((u) => u.roles.includes('Marketing') && u.email !== state.user.data?.email)
              .map((u) => u.email)}
            containerStyles={{ flex: 1 }}
            hideNoneValue
          />
        ) : null}
        <Box
          ref={scroll}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap,
            minHeight: getMinHeight(),
            maxHeight: '350px',
            overflow: 'auto',
            scrollBehavior: 'smooth',
            scrollbarWidth: 'thin'
          }}
        >
          {criterias.map((c, i) => (
            <SegmentAttribute
              key={i}
              index={i}
              segmentCriteria={c}
              onChange={handleCriteriaChange}
              hideDelete={criterias.length === 1}
              remove={removeCriteria}
            />
          ))}
        </Box>
        <Button
          sx={{
            color: colors.gradients.pinkRgb,
            width: '77px',
            whiteSpace: 'nowrap',
            ml: '16px',
            p: '0px',
            '&: hover': { textDecoration: 'underline', backgroundColor: 'white' }
          }}
          onClick={addEmptyCriteria}
          disabled={criterias.length === 8}
        >
          + Add Sub-Filter
        </Button>
        <Box className="form-actions">
          <Box className="form-group-buttons">
            <CancelButton
              id="filter-card-cancel"
              text="Cancel"
              onClick={onClose}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton
              id="filter-card-create"
              text={cardInput ? 'Save' : 'Create'}
              // disabled={isInvalid()}
              onClick={handleSubmit}
            />
          </Box>
          <Box className="form-group-buttons">
            <AddButton
              id="filter-card-calculate"
              text="Calculate"
              sx={{ width: '105px' }}
              icon={
                <PublishedWithChanges
                  className={calculateSegment.isLoading ? 'rotate-icon' : ''}
                  sx={{ width: '16px', height: '16px' }}
                />
              }
              disabled={hasInvalidCriteria()}
              onClick={() =>
                calculateSegment.mutate({
                  brand: getBrandDisplayName(state.selectedBrand),
                  criterias
                })
              }
            />
            {calculated && (
              <Box sx={{ display: 'flex', gap: '5px', lineHeight: '16px', alignItems: 'center' }}>
                <Typography
                  sx={{ fontWeight: 400, fontSize: '12px', color: (t) => t.palette.grey[50] }}
                >
                  Total:
                </Typography>
                <Typography
                  sx={{ fontWeight: 700, fontSize: '12px', color: (t) => t.palette.primary.main }}
                >
                  {calculated}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Loader loading={createFilterCard.isLoading || updateFilterCard.isLoading} />
    </DialogBox>
  );
};

export default FilterCardForm;

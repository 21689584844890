import React, { useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { Avatar, Box, IconButton, Menu, MenuItem } from '@mui/material';
import { PlayerKanbanItem } from '../../../../models/global';
import { MoreVertOutlined, Delete, Edit } from '@mui/icons-material';
import './index.css';
import { colors } from '../../../../utils/theme';
import { getUserCurrencySymbol } from '../../../../utils/ui';

interface IProps {
  item: PlayerKanbanItem;
  index: number;
}

const PlayerKanbanCard: React.FunctionComponent<IProps> = ({ item, index }: IProps) => {
  const [anchorElBrand, setAnchorElBrand] = useState<null | HTMLElement>(null);

  return (
    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Box className="kanban-card hover-box-border" sx={{ height: '180px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Box
                  sx={{
                    fontWeight: 600,
                    color: colors.primary.main,
                    fontSize: '14px'
                  }}
                >
                  {item.email}
                </Box>
                <Box className="kanban-card-secondary-text">{item.id}</Box>
              </Box>
              {/* <Box className="vip-status-badge">{item.vipStatus}</Box> */}
            </Box>
            <Box sx={{ display: 'flex', gap: '50px' }}>
              <Box>
                <Box className="kanban-card-secondary-text">Deposits</Box>
                <Box className="kanban-card-text">
                  {getUserCurrencySymbol(item.currency)}
                  {item.deposit?.toFixed(2)}
                </Box>
              </Box>
              <Box>
                <Box className="kanban-card-secondary-text">Withdraws</Box>
                <Box className="kanban-card-text">
                  {getUserCurrencySymbol(item.currency)}
                  {item.withdraw?.toFixed(2)}
                </Box>
              </Box>
              {/* <Box>
                <Box className="kanban-card-secondary-text">Bets</Box>
                <Box className="kanban-card-text">
                  {getUserCurrencySymbol(item.currency)}
                  {item.bets?.toFixed(2)}
                </Box>
              </Box> */}
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar
                  sx={{ bgcolor: '#F28F3F', width: '32px', height: '32px', fontSize: '16px' }}
                >
                  {item.firstName ? item.firstName[0] : ''}
                  {item.lastName ? item.lastName[0] : ''}
                </Avatar>
                <Box className="kanban-card-secondary-text" sx={{ ml: '10px' }}>
                  {item.name}
                </Box>
              </Box>
              <IconButton
                onClick={(e) => setAnchorElBrand(e.currentTarget)}
                sx={{ width: '24px', height: '24px' }}
              >
                <MoreVertOutlined sx={{ color: (t) => t.palette.primary.main }} />
              </IconButton>
              <Menu
                sx={{ mt: '28px' }}
                id="menu-brands"
                anchorEl={anchorElBrand}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                keepMounted
                open={Boolean(anchorElBrand)}
                onClose={() => setAnchorElBrand(null)}
                MenuListProps={{ sx: { p: '4px 0', minWidth: '160px' } }}
              >
                <MenuItem
                  key={`details-${index}`}
                  onClick={() => {
                    setAnchorElBrand(null);
                    item.handleEdit(item.id);
                  }}
                >
                  <Box className="kanban-item-action-row">
                    <Edit className="kanban-action-icon" /> edit
                  </Box>
                </MenuItem>
                <MenuItem
                  key={`delete-${index}`}
                  onClick={() => {
                    setAnchorElBrand(null);
                    item.handleDelete(item.id);
                  }}
                >
                  <Box className="kanban-item-action-row">
                    <Delete className="kanban-action-icon" /> delete
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

export default PlayerKanbanCard;

import React from 'react';
import DialogBox from '../Common/Layout/DialogBox';
import { IPlayer } from '../../models/player';
import CustomTable, { IHeadCell } from '../Common/Tables/CustomTable';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface IProps {
  onClose: () => void;
  players: IPlayer[];
}

const PlayersSegmentUpload: React.FunctionComponent<IProps> = ({ onClose, players }: IProps) => {
  const columns = [
    {
      title: 'Id',
      field: 'id'
    },
    {
      title: 'First Name',
      field: 'firstName'
    },
    {
      title: 'Last Name',
      field: 'lastName'
    },
    {
      title: 'Email',
      field: 'email'
    },
    {
      title: 'Username',
      field: 'username'
    },
    {
      title: 'Country',
      field: 'country'
    }
  ] as IHeadCell[];

  return (
    <DialogBox width={1100} onClose={onClose} title="Players Preview">
      <CustomTable
        columns={columns}
        data={players}
        toolbarTitle="Players added to segment"
        showSearch
      />
    </DialogBox>
  );
};

export default PlayersSegmentUpload;

import React, { useState } from 'react';
import { Box } from '@mui/material';
import CancelButton from '../../Common/Buttons/CancelButton';
import InputComponent from '../../Common/Fields/InputComponent';
import DialogBox from '../../Common/Layout/DialogBox';
import { SegmentGroup } from '../../../models/segmets';
import MainButton from '../../Common/Buttons/MainButton';

interface IProps {
  group?: SegmentGroup;
  onClose: (saved: boolean, newItem?: string) => void;
}

const SegmentGroupForm: React.FunctionComponent<IProps> = ({ group, onClose }: IProps) => {
  const [name, setName] = useState(group?.name ?? '');
  const [errorText, setErrorText] = useState<string | null>(null);

  return (
    <DialogBox
      width={800}
      onClose={() => onClose(false)}
      title={group ? 'Edit Segment Group' : 'Create New Group'}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <InputComponent
          id="segment-group-name"
          label="Group Name"
          value={name}
          onChange={(event) => {
            setErrorText(event.currentTarget.value.length > 2 ? null : 'name too short');
            setName(event.currentTarget.value);
          }}
          errorMsg={errorText}
          containerStyles={{ flex: 1 }}
        />
        <Box className="form-actions">
          <Box className="form-group-buttons">
            <CancelButton
              id="segment-group-cancel"
              text="Cancel"
              onClick={() => onClose(false)}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton
              id="segment-group-create"
              text={group ? 'Save' : 'Create'}
              disabled={name.length < 3}
              onClick={() => onClose(true, name)}
            />
          </Box>
        </Box>
      </Box>
    </DialogBox>
  );
};

export default SegmentGroupForm;

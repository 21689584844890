import React, { useContext, useState } from 'react';
import { Box, FormControlLabel } from '@mui/material';
import DialogBox from '../../Common/Layout/DialogBox';
import InputComponent from '../../Common/Fields/InputComponent';
import { Journey, JourneyGroup, JourneyModel } from '../../../models/journeys';
import SelectComponent from '../../Common/Fields/SelectComponent';
import CancelButton from '../../Common/Buttons/CancelButton';
import MainButton from '../../Common/Buttons/MainButton';
import JourneyGroupForm from './JourneyGroupForm';
import DateComponent from '../../Common/Fields/DateComponent';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getBrandId, handleRequestError } from '../../../utils/ui';
import { CreateJourneyMutation, UpdateJourneyMutation } from '../../../queries/journey';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { PinkSwitch } from '../Segments/SegmentForm';
import Loader from '../../Common/Global/Loader';

dayjs.extend(utc);

interface IProps {
  onClose: () => void;
  groups: JourneyGroup[];
  journey?: Journey | null;
}

const JourneyForm: React.FunctionComponent<IProps> = ({ onClose, groups, journey }: IProps) => {
  const [name, setName] = useState(journey?.name ?? '');
  const [group, setGroup] = useState(journey?.group.name ?? '');
  const [openForm, setOpenForm] = useState(false);
  const [startDate, setStartDate] = useState(journey?.startDate ?? null);
  const [endDate, setEndDate] = useState(journey?.endDate ?? null);
  const [isActive, setIsActive] = useState(journey?.isActive ?? true);
  const { dispatch, state } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const queryClient = useQueryClient();

  const today = dayjs().set('minute', 0).toDate();
  const createJourney = useMutation(CreateJourneyMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Created ${data.name} journey`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.JourneyGroups, brandId])
  });
  const updateJourney = useMutation(UpdateJourneyMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      const updatedJourney = data as JourneyModel;
      if (state.selectedJourney) {
        dispatch({
          type: Actions.SetJourney,
          payload: {
            journey: {
              id: state.selectedJourney.id,
              ...data,
              group: state.journeyGroups.find((jg) => jg.id === updatedJourney.groupId)
            }
          }
        });
      }
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Updated ${data.name} journey`
        }
      });
      onClose();
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.JourneyGroups, brandId])
  });

  const handleSubmit = () => {
    const input = {
      name,
      startDate: dayjs(startDate).startOf('day').utc(true).format(),
      endDate: endDate && dayjs(endDate).endOf('day').utc(true).format(),
      groupId: groups.find((g) => g.name === group)?.id,
      isActive
    } as JourneyModel;
    if (journey) {
      updateJourney.mutate({ id: journey.id, input });
    } else {
      createJourney.mutate({ brandId, input });
    }
  };

  return (
    <DialogBox
      width={800}
      onClose={onClose}
      title={journey ? 'Edit Journey' : 'Create New Journey'}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          gap: '24px',
          mb: '24px'
        }}
      >
        <InputComponent
          id="journey-name"
          label="Journey Name"
          value={name}
          onChange={(event) => setName(event.currentTarget.value)}
        />
        <SelectComponent
          label="Journey Group"
          value={group}
          isSingle
          onChange={(e) => {
            const {
              target: { value }
            } = e;
            setGroup(value as string);
          }}
          options={groups.map((g) => g.name)}
          containerStyles={{ flex: 1 }}
          hideNoneValue
          onCreateClick={() => setOpenForm(true)}
        />
        <DateComponent
          id="start-date"
          label="Start Date"
          value={startDate}
          onChange={setStartDate}
          minDate={today}
          {...(endDate && { maxDate: endDate })}
        />
        <DateComponent
          id="end-date"
          label="End Date"
          value={endDate}
          onChange={setEndDate}
          minDate={startDate ?? today}
        />
        <FormControlLabel
          control={
            <PinkSwitch
              checked={isActive}
              onChange={(event) => {
                setIsActive(event.target.checked);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          }
          label="Is Active"
          sx={{
            fontWeight: 400,
            fontSize: '16px',
            color: (t) => t.palette.primary.main
          }}
        />
      </Box>
      <Box className="form-actions">
        <Box className="form-group-buttons">
          <CancelButton
            id="journey-cancel"
            text="Cancel"
            onClick={onClose}
            sx={{ height: '36px', width: '48px' }}
          />
          <MainButton
            id="journey-create"
            text={journey ? 'Save' : 'Create'}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
      {openForm && <JourneyGroupForm onClose={() => setOpenForm(false)} />}
      <Loader loading={createJourney.isLoading || updateJourney.isLoading} />
    </DialogBox>
  );
};

export default JourneyForm;

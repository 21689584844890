import axios from "axios";
import { AppConsts } from "../enums/AppConsts";
import { INewCommentModel } from "../models/comment";
import { getHttpHeaders } from "../utils/rights";

const baseUrl = process.env.BASE_SERVER_ADDRESS;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);

const getHeaders = () => ({
  headers: { Authorization: `Bearer ${getToken()}` },
});

export const GetCommentsQuery = async (playerId: string) => {
  return await axios.get(
    `${baseUrl}/Comment/GetAll/${playerId}`,
    getHttpHeaders()
  );
};

export const addCommentMutation = async (data: INewCommentModel) => {
  return await axios.post(`${baseUrl}/Comment/Create`, data, getHeaders());
};

export const deleteCommentMutation = async (data: {
  id: number;
  author: string;
}) => {
  return await axios.delete(
    `${baseUrl}/Comment/Delete/${data.id}?author=${encodeURIComponent(
      data.author
    )}`,
    getHeaders()
  );
};

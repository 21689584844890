import ReactApexChart from 'react-apexcharts';
import { ChartSerie } from '../../models/chart';
import React from 'react';

interface IProps {
  series: ChartSerie;
  markSymbol?: string;
  labels: string[];
}

const ColumnChart: React.FunctionComponent<IProps> = ({
  series,
  markSymbol = '',
  labels
}: IProps) => {
  const calculateColumnWidth = (itemCount: number) => {
    const minColumnWidth = 40; // Set your minimum column width (in pixels)
    const maxColumnWidth = 85; // Set your maximum column width (in pixels)
    const maxItems = 12; // Set the maximum number of items for the desired appearance

    // Calculate the width percentage
    const widthPercentage = Math.min((maxColumnWidth * itemCount) / maxItems, maxColumnWidth);

    // Ensure the width is not less than the minimum width
    return Math.max(widthPercentage, minColumnWidth);
  };

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        dataLabels: {
          position: 'top'
        },
        columnWidth: `${calculateColumnWidth(series.data.length)}%`
      }
    },
    colors: '#ED693C',
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 0,
            color: '#F28F3F',
            opacity: 1
          },
          {
            offset: 5,
            color: '#F28F3F',
            opacity: 1
          },
          {
            offset: 54,
            color: '#ED693C',
            opacity: 1
          },
          {
            offset: 100,
            color: '#E83E39',
            opacity: 1
          }
        ]
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: number) {
        return `${markSymbol}${val}K`;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304758']
      }
    },
    xaxis: {
      categories: labels,
      labels: {
        rotate: -45,
        tickPlacement: 'on',
        rotateAlways: true,
        style: {
          colors: '#ACAABA',
          fontSize: '12px',
          fontWeight: 400
        }
      },
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      },
      tooltip: {
        enabled: true
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#ACAABA',
          fontSize: '10px',
          fontWeight: 400
        }
      }
    }
  };

  return <ReactApexChart options={options} series={[series]} type="bar" height={350} />;
};

export default ColumnChart;

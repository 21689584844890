import { Brand } from './brand';
import { JourneyGroup } from './journeys';
import { SegmentGroup } from './segmets';
export const data = [
  {
    id: '1',
    Task: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent.',
    // Assigned_To: 'Beltran',
    // Assignee: 'Romona',
    // Status: 'To-do',
    // Priority: 'Low',
    Due_Date: '25-May-2020'
  },
  {
    id: '2',
    Task: 'Fix Styling',
    // Assigned_To: 'Dave',
    // Assignee: 'Romona',
    // Status: 'To-do',
    // Priority: 'Low',
    Due_Date: '26-May-2020'
  },
  {
    id: '3',
    Task: 'Handle Door Specs',
    // Assigned_To: 'Roman',
    // Assignee: 'Romona',
    // Status: 'To-do',
    // Priority: 'Low',
    Due_Date: '27-May-2020'
  },
  {
    id: '4',
    Task: 'morbi',
    // Assigned_To: 'Gawen',
    // Assignee: 'Kai',
    // Status: 'Done',
    // Priority: 'High',
    Due_Date: '23-Aug-2020'
  },
  {
    id: '5',
    Task: 'proin',
    // Assigned_To: 'Bondon',
    // Assignee: 'Antoinette',
    // Status: 'In Progress',
    // Priority: 'Medium',
    Due_Date: '05-Jan-2021'
  }
];

export const columnsFromBackend = {
  ['sadasdas']: {
    title: 'To-do',
    items: data
  },
  ['qwieouwqoieu']: {
    title: 'In Progress',
    items: []
  },
  ['fdkljhgdkf']: {
    title: 'Done',
    items: []
  }
};

export const dummyJourneyGroups = [
  {
    id: 1,
    name: 'Very cool journeys',
    journeysAmount: 1,
    journeys: [
      {
        id: 33,
        name: 'my first journey',
        isActive: true,
        startDate: new Date().toUTCString(),
        endDate: new Date().toUTCString(),
        group: {
          id: 1,
          name: 'Very cool journeys',
          journeysAmount: 1
        }
      }
    ]
  }
] as JourneyGroup[];

export const dummyBrands = [
  {
    id: 1,
    name: 'Brand name 1'
  },
  {
    id: 2,
    name: 'Brand name 2'
  },
  {
    id: 3,
    name: 'Brand name 3'
  }
] as Brand[];

export const SegmentCriteriaNames = [
  'Full name',
  'Vip manager',
  'Vip status',
  'Country',
  'Email',
  'First Deposit Date',
  'Total Deposit Amount',
  'Withdrawal Amount',
  'Total GGR',
  'Last Deposit date',
  'Last bet range',
  'Lead product (based on turnover)',
  'Lifetime NGR',
  'Real money balance'
];

export const comments = [
  {
    fullname: 'Plamen Uzunov',
    dateTime: '11/12/2023 | 14:00',
    category: 'Follow Up',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    fullname: 'Petur Pavlov',
    dateTime: '11/12/2023 | 14:00',
    category: 'General',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    fullname: 'Reni Petrova',
    dateTime: '11/12/2023 | 14:00',
    category: 'Important',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    fullname: 'Stoyan Alexandrov',
    dateTime: '11/12/2023 | 14:00',
    category: 'Follow Up',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    fullname: 'Dima Terziev',
    dateTime: '11/12/2023 | 14:00',
    category: 'Follow Up',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  },
  {
    fullname: 'Hristian Tenev',
    dateTime: '11/12/2023 | 14:00',
    category: 'Follow Up',
    content:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
  }
];

//GGR = bets - wins
//NGR = bets - wins - bonus

export const ConditionalOperators = ['>', '>=', '<', '<=', '='];

export const ChannelOperators = ['Email', 'Sms'];

export const playerTemplateHeaders =
  'id,username,firstName,lastName,country,email,registered,lastLogin';

export const segmentPlayersTemplateHeaders = 'email';

export const kpisDummyData = [
  {
    title: 'MTD Deposits',
    percentValue: 4.16,
    trend: 'increasing',
    value: '10.674',
    valueLegend: 'SPLM: 1,598,385'
  },
  {
    title: 'MTD Withdrawaws',
    percentValue: 0.56,
    trend: 'increasing',
    value: '5.674',
    valueLegend: 'SPLM: 1,598,385'
  },
  {
    title: 'MTD Bonuses',
    percentValue: 1.75,
    trend: 'increasing',
    value: '2.002',
    valueLegend: 'SPLM: 1,598,385'
  },
  {
    title: 'MTD Deposits',
    percentValue: 1.75,
    trend: 'increasing',
    value: '2.002',
    valueLegend: 'SPLM: 1,598,385'
  }
];

export const vipStatusOptions = ['Platinum', 'Gold', 'Silver', 'Bronze'];

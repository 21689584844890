import React, { useState, useEffect, Fragment, useContext } from 'react';
import { AppContext } from '../AppContext';
import { AppConsts } from '../enums/AppConsts';
import { Actions } from '../enums/ActionEnums';
import Loader from '../components/Common/Global/Loader';
import { decodeToken } from '../utils/ui';
import { useQueryClient } from '@tanstack/react-query';

interface IProps {
  children: React.ReactNode;
}

const Auth: React.FunctionComponent<IProps> = ({ children }) => {
  const queryClient = useQueryClient();
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    const token = localStorage.getItem(AppConsts.AccessToken);
    if (!token) {
      // context remove user
      dispatch({ type: Actions.RemoveUser });
    } else {
      const decoded = decodeToken(token);
      const now = new Date().getTime() / 1000;
      if (decoded.exp < now) {
        // if is not valid -> context remove user
        localStorage.removeItem(AppConsts.AccessToken);
        dispatch({ type: Actions.RemoveUser });
        queryClient.invalidateQueries();
      } else {
        dispatch({
          type: Actions.SetUser,
          payload: {
            data: {
              accessToken: token,
              email: decoded.email,
              expirationDate: decoded.exp.toString(),
              firstName: decoded.name,
              lastName: decoded.family_name,
              roles: decoded.role,
              brands: decoded.groupsid
            },
            token
          }
        });
        dispatch({
          type: Actions.SetSelectedBrand,
          payload: {
            brand: decoded.groupsid[0]
          }
        });
      }
    }
    setWaitAuthCheck(false);
  }, []);

  return waitAuthCheck ? <Loader loading={waitAuthCheck} /> : <Fragment>{children}</Fragment>;
};

export default Auth;

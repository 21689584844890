import React, { useContext } from 'react';
import DialogBox from '../Common/Layout/DialogBox';
import { IPlayer } from '../../models/player';
import CustomTable, { IHeadCell } from '../Common/Tables/CustomTable';
import { Box } from '@mui/material';
import CancelButton from '../Common/Buttons/CancelButton';
import MainButton from '../Common/Buttons/MainButton';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { getBrandDisplayName, getBrandId, handleRequestError } from '../../utils/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { UploadPlayersMutation } from '../../queries/players';
import Loader from '../Common/Global/Loader';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface IProps {
  onClose: () => void;
  players: IPlayer[];
}

const PlayerUpload: React.FunctionComponent<IProps> = ({ onClose, players }: IProps) => {
  const queryClient = useQueryClient();
  const { dispatch, state } = useContext(AppContext);
  const brand = getBrandDisplayName(state.selectedBrand);
  const brandId = getBrandId(state.selectedBrand);

  const uploadPlayers = useMutation(UploadPlayersMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Uploaded ${data} players`
        }
      });
      onClose();
    },
    onError: ({ response }) => handleRequestError(dispatch, response),
    onSettled: () => queryClient.invalidateQueries([QueryKey.Players, brandId])
  });

  const columns = [
    {
      title: 'Id',
      field: 'id'
    },
    {
      title: 'First Name',
      field: 'firstName'
    },
    {
      title: 'Last Name',
      field: 'lastName'
    },
    {
      title: 'Email',
      field: 'email'
    },
    {
      title: 'Username',
      field: 'username'
    },
    {
      title: 'Country',
      field: 'country'
    }
  ] as IHeadCell[];

  const handleSave = () => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `Are you sure you want to save ${players.length} players to ${brand} brand`,
        agreeAction: savePlayers
      }
    });
  };

  const savePlayers = () => {
    dispatch({
      type: Actions.HideConfirmation
    });

    const updated = players.map((p) => ({
      ...p,
      registered: dayjs(p.registered).utc(true).format(),
      lastLogin:
        p.lastLogin && p.lastLogin.length > 0 ? dayjs(p.lastLogin).utc(true).format() : null
    }));
    uploadPlayers.mutate({ brandId, players: updated });
  };

  return (
    <DialogBox width={1100} onClose={onClose} title="Players Preview">
      <CustomTable columns={columns} data={players} toolbarTitle="Players to upload" showSearch />
      <Box className="form-actions" sx={{ mt: '25px' }}>
        <Box className="form-group-buttons">
          <CancelButton
            id="upload-cancel"
            text="Cancel"
            onClick={onClose}
            sx={{ height: '36px', width: '48px' }}
          />
          <MainButton id="upload-save" text="Save" onClick={handleSave} />
        </Box>
      </Box>
      <Loader loading={uploadPlayers.isLoading} />
    </DialogBox>
  );
};

export default PlayerUpload;

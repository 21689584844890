import axios from 'axios';
import {
  IPlayer,
  IPlayerKanbanStage,
  IPlayerStage,
  IPlayerVipManager,
  IPlayerVipStatus,
  UpdatePlayerKanbanStageModel
} from '../models/player';
import { baseUrl, getHttpHeaders } from '../utils/rights';

export const testPlayersQuery = async () => {
  return await axios.get(`${baseUrl}/test`);
};

export const testDummyPostQuery = async () => {
  return await axios.post(`${baseUrl}/test`);
};

export const GetBrandPlayersQuery = async (brand: string) => {
  return await axios.get(`${baseUrl}/player/getall/${brand}`, getHttpHeaders());
};

export const GetPlayersByEmailsQuery = async ({
  brand,
  emails
}: {
  brand: string;
  emails: string[];
}) => {
  return await axios.post(
    `${baseUrl}/player/GetAllByEmails/${brand}`,
    { emails },
    getHttpHeaders()
  );
};

export const GetPlayersPartialQuery = async (brand: string) => {
  return await axios.get(`${baseUrl}/player/GetPartial/${brand}`, getHttpHeaders());
};

export const UploadPlayersMutation = async ({
  brandId,
  players
}: {
  brandId: number;
  players: IPlayer[];
}) => {
  return await axios.post(`${baseUrl}/player/upload/${brandId}`, { players }, getHttpHeaders());
};

export const UpdatePlayerVipStatusMutation = async (data: IPlayerVipStatus) => {
  return await axios.put(`${baseUrl}/Player/UpdateVipStatus`, data, getHttpHeaders());
};

export const UpdatePlayerVipManagerMutation = async (data: IPlayerVipManager) => {
  return await axios.put(`${baseUrl}/Player/UpdateVipManager`, data, getHttpHeaders());
};

export const UpdatePlayerStage = async (data: IPlayerStage) => {
  return await axios.put(`${baseUrl}/Player/UpdateStage`, data, getHttpHeaders());
};

export const CreatePlayerKanbanStage = async (data: IPlayerKanbanStage) => {
  return await axios.put(`${baseUrl}/Player/CreatePlayerKanbanStage`, data, getHttpHeaders());
};

export const UpdatePlayerKanbanStage = async (data: UpdatePlayerKanbanStageModel) => {
  return await axios.post(`${baseUrl}/Player/UpdateKanbanStage/${data.id}`, data, getHttpHeaders());
};

export const GetPlayersKanbanStages = async () => {
  return await axios.get(`${baseUrl}/player/GetAllPlayersKanbanStages`, getHttpHeaders());
};

export const DeletePlayerKanbanStage = async (id: number) => {
  return await axios.delete(`${baseUrl}/player/DeleteKanbanStage/${id}`, getHttpHeaders());
};

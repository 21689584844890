import React from 'react';
import { Box, Divider } from '@mui/material';
import { colors } from '../../../../utils/theme';
import { CheckCircleOutlineOutlined } from '@mui/icons-material';

interface IProps {
  state: 'completed' | 'active' | 'following';
  title: string;
  isLast: boolean;
}

const IgaStep: React.FunctionComponent<IProps> = ({ state, title, isLast }: IProps) => {
  const getBackground = () => {
    if (state === 'active') {
      return colors.gradients.pinkRgb;
    } else if (state === 'completed') {
      return colors.green.second;
    } else {
      return '#F2F1F9';
    }
  };
  const size = '20px';
  const innerSize = '80%';
  const borderColor = state === 'following' ? colors.gradients.pinkRgb : 'white';
  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start">
      <Box display="flex" gap="16px">
        <Box
          sx={{
            p: '2px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: size,
            height: size,
            borderRadius: '50px',
            backgroundColor: getBackground()
          }}
        >
          {state === 'completed' ? (
            <CheckCircleOutlineOutlined
              sx={{ width: innerSize, height: innerSize, color: 'white' }}
            />
          ) : (
            <Box
              sx={{
                width: innerSize,
                height: innerSize,
                borderRadius: '50px',
                border: `2px solid ${borderColor}`
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            fontWeight: state === 'active' ? 600 : 400,
            fontSize: '16px',
            color: colors.primary.main
          }}
        >
          {title}
        </Box>
      </Box>
      {!isLast && (
        <Divider
          orientation="vertical"
          sx={{ width: '2px', height: '24px', pl: '10px', mb: '5px' }}
        />
      )}
    </Box>
  );
};

export default IgaStep;

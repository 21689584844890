import React, { useContext, useEffect, useState } from 'react';
import { EditOutlined } from '@mui/icons-material';
import { Box, IconButton, Link } from '@mui/material';
import { AuthRoutes, RouteNames } from '../../../enums/RouteEnums';
import { IRouteItem } from '../../../models/global';
import Header from '../../Common/Layout/Header';
import CustomTable, { IHeadCell } from '../../Common/Tables/CustomTable';
import BreadcrumbsNav from '../../Navigation/BreadcrumbsNav';
import JourneyForm from './JourneyForm';
import { Journey, JourneyGroup } from '../../../models/journeys';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import StatusIdicator from '../../Common/Layout/StatusIdicator';
import { displayText, getBrandId, handleRequestError } from '../../../utils/ui';
import JourneyGroupForm from './JourneyGroupForm';
import { useQuery } from '@tanstack/react-query';
import { AppContext } from '../../../AppContext';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { JourneyGroupsQuery } from '../../../queries/journey';
import Loader from '../../Common/Global/Loader';
import { Actions } from '../../../enums/ActionEnums';

const JourneyGroupOverview: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathElements = location.pathname.split('/').filter(Boolean);
  const [isDetails, setIsDetails] = useState(pathElements.length === 2);
  const [currentRoute, setCurrentRoute] = useState<string>(
    isDetails ? location.state?.journeyGroup?.name : RouteNames.CustomerJourney
  );
  const [routeItems, setRouteItems] = useState<IRouteItem[]>(
    isDetails ? [{ name: RouteNames.CustomerJourney, path: AuthRoutes.CustomerJourney }] : []
  );
  const [openForm, setOpenForm] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState<Journey | null>(null);
  const [openGroupForm, setOpenGroupForm] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState<JourneyGroup | null>(null);
  const { state, dispatch } = useContext(AppContext);
  const fnKey = QueryKey.JourneyGroups;
  const brandId = getBrandId(state.selectedBrand);

  const journeyGroupsQuery = useQuery({
    queryKey: [fnKey, brandId],
    queryFn: () => JourneyGroupsQuery(brandId),
    onSuccess: ({ data }) => {
      const dbGroups = (data as JourneyGroup[]).map((jg) => ({
        ...jg,
        journeys: jg.journeys.map((j) => ({ ...j, group: jg }))
      }));
      setJourneyGroups(dbGroups);
      dispatch({
        type: Actions.SetJourneyGroups,
        payload: {
          groups: dbGroups
        }
      });
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });
  const [journeyGroups, setJourneyGroups] = useState(journeyGroupsQuery.data?.data ?? []);

  useEffect(() => {
    if (journeyGroupsQuery.data?.data) {
      const { data } = journeyGroupsQuery.data;
      const dbGroups = (data as JourneyGroup[]).map((jg) => ({
        ...jg,
        journeys: jg.journeys.map((j) => ({ ...j, group: jg }))
      }));
      setJourneyGroups(dbGroups);
      dispatch({
        type: Actions.SetJourneyGroups,
        payload: {
          groups: dbGroups
        }
      });
    }
  }, [journeyGroupsQuery.data]);

  useEffect(() => {
    if (!location.state?.journeyGroup) {
      setIsDetails(false);
      setCurrentRoute(RouteNames.CustomerJourney);
      setRouteItems([]);
    }
  }, [location.state]);

  const customActions = ({ rowData }: { rowData: JourneyGroup | Journey }) => {
    const iconButtonProps = {
      height: '48px',
      width: '48px',
      marginRight: '16px'
    };

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <IconButton onClick={() => handleEdit(rowData)} sx={{ ...iconButtonProps, marginRight: 0 }}>
          <EditOutlined sx={{ color: 'primary.main' }} />
        </IconButton>
        {/* <IconButton onClick={() => openForm(rowData)} sx={{ ...iconButtonProps, marginRight: 0 }}>
          <DeleteOutlineOutlined sx={{ color: 'error.main' }} />
        </IconButton> */}
      </Box>
    );
  };

  const handleEdit = (input: JourneyGroup | Journey) => {
    if ('isActive' in input) {
      setSelectedJourney(input);
      setOpenForm(true);
    } else {
      setSelectedGroup(input);
      setOpenGroupForm(true);
    }
  };

  const handleDetails = (rowData: JourneyGroup | Journey) => {
    setCurrentRoute(rowData.name);
    let items = [{ name: RouteNames.CustomerJourney, path: AuthRoutes.CustomerJourney }];
    if (isDetails) {
      items.push({
        name: RouteNames.CustomerJourney,
        path: `${location.pathname}` as AuthRoutes
      });
    }
    if ('isActive' in rowData) {
      dispatch({
        type: Actions.SetJourney,
        payload: {
          journey: rowData as Journey
        }
      });
    }
    setRouteItems([{ name: RouteNames.CustomerJourney, path: AuthRoutes.CustomerJourney }]);

    navigate(`${location.pathname}/${rowData.id}`, {
      state: { journeyGroup: rowData, path: AuthRoutes.CustomerJourney }
    });
    setIsDetails(true);
  };

  const groupColumns = [
    {
      title: 'Group name',
      field: 'name',
      render: (data: JourneyGroup) => (
        <Link underline="hover" color="info.main" onClick={() => handleDetails(data)}>
          {data.name}
        </Link>
      )
    },
    {
      title: 'Number of Journeys',
      field: 'journeysAmount',
      type: 'numeric',
      render: (data: JourneyGroup) => displayText(data.journeys?.length.toString())
    }
  ] as IHeadCell[];

  const journeysColumns = [
    {
      title: 'Journey name',
      field: 'name',
      render: (data: Journey) => (
        <Link underline="hover" color="info.main" onClick={() => handleDetails(data)}>
          {data.name}
        </Link>
      )
    },
    {
      title: 'Status',
      render: (data: Journey) => (
        <Box>
          <StatusIdicator type={data.isActive ? 'success' : 'info'} />
          {data.isActive ? 'Active' : 'Inactive'}
        </Box>
      )
    },
    {
      title: 'Start date',
      field: 'starDate',
      render: (data: Journey) => displayText(moment(data.startDate).format('YYYY/MM/DD HH:mm:ss'))
    },
    {
      title: 'End date',
      field: 'endDate',
      render: (data: Journey) =>
        displayText(data.endDate && moment(data.endDate).format('YYYY/MM/DD HH:mm:ss'))
    }
  ] as IHeadCell[];

  return (
    <Box className="nav-page">
      <BreadcrumbsNav current={currentRoute} items={routeItems} />
      <Box sx={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
        <Box>
          <Header text="Customer Journey" />
          <Header text="Add or edit different journeys" isSubHeader={true} />
        </Box>
      </Box>
      <CustomTable
        toolbarTitle={isDetails ? location.state?.journeyGroup?.name ?? '' : 'Groups'}
        data={
          isDetails
            ? state.journeyGroups.find((jg) => jg.name === currentRoute)?.journeys ?? []
            : journeyGroups
        }
        columns={isDetails ? journeysColumns : groupColumns}
        showSearch
        Actions={customActions}
        createButtonProps={{
          id: 'add-user-button',
          onClick: () => setOpenForm(true),
          text: '+ Add Journey',
          disabled: false
        }}
      />
      {openForm && (
        <JourneyForm
          journey={selectedJourney}
          groups={journeyGroups}
          onClose={() => {
            setOpenForm(false);
            setSelectedJourney(null);
          }}
        />
      )}
      {openGroupForm && (
        <JourneyGroupForm
          journeyGroup={selectedGroup as JourneyGroup}
          onClose={() => {
            setOpenGroupForm(false);
            setSelectedGroup(null);
          }}
        />
      )}
      <Loader loading={journeyGroupsQuery.isLoading} />
    </Box>
  );
};

export default JourneyGroupOverview;

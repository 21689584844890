import React, { useContext, useState } from 'react';
import { ArrowDropDown } from '@mui/icons-material';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { getBrandDisplayName } from '../../utils/ui';

const BrandSelect: React.FunctionComponent = () => {
  const { dispatch, state } = useContext(AppContext);
  const [anchorElBrand, setAnchorElBrand] = useState<null | HTMLElement>(null);

  return (
    <Box>
      <Button sx={{ width: '140px' }} onClick={(e) => setAnchorElBrand(e.currentTarget)}>
        {getBrandDisplayName(state.selectedBrand)}
        <ArrowDropDown />
      </Button>
      <Menu
        sx={{ mt: '28px' }}
        id="menu-brands"
        anchorEl={anchorElBrand}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        keepMounted
        open={Boolean(anchorElBrand)}
        onClose={() => setAnchorElBrand(null)}
        MenuListProps={{ sx: { p: '4px 0', minWidth: '160px' } }}
      >
        {state.user.data?.brands?.map((br, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              dispatch({
                type: Actions.SetSelectedBrand,
                payload: {
                  brand: br
                }
              });
              setAnchorElBrand(null);
            }}
          >
            {getBrandDisplayName(br)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default BrandSelect;

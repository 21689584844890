import { InitialStateType } from '../AppContext';
import { ActionType } from '../models/appContext';
import { brandReducer } from './brandReducer';
import { confirmationReducer } from './confirmationReducer';
import { journeyGroupReducer } from './journeyGroupReducer';
import { journeyReducer } from './journeyReducer';
import { journeyStageReducer } from './journeyStageReducer';
import { messageReducer } from './messageReducer';
import { rolesReducer } from './rolesReducer';
import { userReducer } from './userReducer';

export const mainReducer = (
  {
    user,
    message,
    routes,
    roles,
    confirmation,
    selectedBrand,
    journeyGroups,
    selectedJourney,
    selectedJourneyStage
  }: InitialStateType,
  action: ActionType
) => ({
  user: userReducer(user, action),
  message: messageReducer(message, action),
  roles: rolesReducer(roles, action),
  confirmation: confirmationReducer(confirmation, action),
  selectedBrand: brandReducer(selectedBrand, action),
  journeyGroups: journeyGroupReducer(journeyGroups, action),
  selectedJourney: journeyReducer(selectedJourney, action),
  selectedJourneyStage: journeyStageReducer(selectedJourneyStage, action),
  routes
});

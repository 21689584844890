import axios from 'axios';
import { AppConsts } from '../enums/AppConsts';
import {
  CalculateSegment,
  CreateSegment,
  CreateSegmentGroup,
  SegmentGroup,
  SegmentModel
} from '../models/segmets';
import { baseUrl, getHttpHeaders } from '../utils/rights';

export const CreateSegmentGroupMutation = async (input: CreateSegmentGroup) => {
  return await axios.post(
    `${baseUrl}/segment/createGroup/${input.brandId}`,
    { input: input.name },
    getHttpHeaders()
  );
};

export const UpdateSegmentGroupMutation = async (input: SegmentGroup) => {
  return await axios.put(
    `${baseUrl}/segment/UpdateGroup/${input.id}`,
    { input: input.name },
    getHttpHeaders()
  );
};

export const SegmentGroupsQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/segment/GetGroups/${brandId}`, getHttpHeaders());
};

export const DeleteSegmentGroupMutation = async (id: number) => {
  return await axios.delete(`${baseUrl}/segment/deletegroup/${id}`, getHttpHeaders());
};

export const CreateSegmentMutation = async ({ brandId, input }: CreateSegment) => {
  return await axios.post(`${baseUrl}/segment/create/${brandId}`, { ...input }, getHttpHeaders());
};

export const CalculateSegmentMutation = async ({ brand, criterias }: CalculateSegment) => {
  return await axios.post(`${baseUrl}/segment/calculate/${brand}`, { criterias }, getHttpHeaders());
};

export const DeleteSegmentMutation = async (id: number) => {
  return await axios.delete(`${baseUrl}/segment/delete/${id}`, getHttpHeaders());
};

export const UpdateSegmentMutation = async ({
  id,
  segment
}: {
  id: number;
  segment: SegmentModel;
}) => {
  return await axios.put(`${baseUrl}/segment/Update/${id}`, segment, getHttpHeaders());
};

export const SyncGroupMutation = async (groupId: number) => {
  return await axios.get(`${baseUrl}/segment/SyncGroup/${groupId}`, getHttpHeaders());
};

export const SetIsSegmentActiveMutation = async ({
  id,
  isActive
}: {
  id: number;
  isActive: boolean;
}) => {
  return await axios.post(`${baseUrl}/segment/SetIsActive`, { id, isActive }, getHttpHeaders());
};

import React, { forwardRef } from 'react';
import { Box } from '@mui/material';
import { colors } from '../../../utils/theme';
import AddButton from '../../Common/Buttons/AddButton';
import icon from '../../../assets/empty_card.svg';
import './index.css';

interface IProps {
  openForm: () => void;
  disabled?: boolean;
}

const EmptyCard: React.FunctionComponent<IProps> = forwardRef(function EmptyCard(props, ref) {
  const { openForm, disabled } = props;
  const borderColor = disabled ? '#DFDEE7' : colors.gradients.pinkRgb;

  return (
    <Box
      {...props}
      ref={ref}
      className="filter-card"
      sx={{
        border: `2px dashed ${borderColor}`,
        flexDirection: 'column-reverse',
        p: '24px',
        gap: '12px',
        minWidth: '212px'
      }}
    >
      <AddButton
        id="add-filter-button"
        text="+ Add Filter"
        onClick={openForm}
        disabled={disabled}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          gap: '12px'
        }}
      >
        <Box component="img" src={icon} />
        {!disabled && (
          <Box
            sx={{
              fontWeight: 600,
              fontSize: '14px',
              color: (t) => t.palette.primary.main,
              textShadow: '-3px 3px 4px rgba(40,36,95,0.4)'
            }}
          >
            Build a filter card
          </Box>
        )}
      </Box>
    </Box>
  );
});

export default EmptyCard;

import React from 'react';
import { Box, Dialog, IconButton, Slide, SxProps } from '@mui/material';
import { colors } from '../../../utils/theme';
import { Close } from '@mui/icons-material';
import Header from './Header';
import { TransitionProps } from '@mui/material/transitions';

interface IProps {
  sx?: SxProps;
  width: number;
  children: React.ReactNode;
  title?: string;
  subTitle?: string;
  onClose?: () => void;
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogBox: React.FunctionComponent<IProps> = ({
  sx,
  children,
  width,
  onClose,
  title = '',
  subTitle
}) => (
  <Dialog
    id="dialog-window"
    open={true}
    sx={{
      '& .MuiDialog-container': {
        display: 'flex',
        alignItems: 'start',
        marginTop: 'auto',
        marginBottom: 'auto'
      }
    }}
    PaperProps={{
      sx: {
        p: '24px',
        maxWidth: `${width}px`,
        width: `${width}px`,
        borderRadius: '24px'
      }
    }}
    BackdropProps={{ sx: { background: 'rgba(20, 18, 47, 0.6)' } }}
    TransitionComponent={Transition}
  >
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box>
        <Header text={title} />
        {subTitle && <Header text={subTitle} isSubHeader />}
      </Box>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500]
        }}
      >
        <Close />
      </IconButton>
    </Box>
    <Box
      sx={{
        background: colors.white,
        padding: '24px',
        ...sx
      }}
    >
      {children}
    </Box>
  </Dialog>
);

export default DialogBox;

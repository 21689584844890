import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import DialogBox from '../Common/Layout/DialogBox';
import InputComponent from '../Common/Fields/InputComponent';
import CancelButton from '../Common/Buttons/CancelButton';
import MainButton from '../Common/Buttons/MainButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createBrandMutation, uptadeBrandMutation } from '../../queries/brand';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { getBrandDisplayName, handleRequestError } from '../../utils/ui';
import Loader from '../Common/Global/Loader';
import { IBrand, INewBrandModel } from '../../models/brand';

interface IProps {
  onClose: () => void;
  brandInput: IBrand | null;
}

export type IErrors = {
  name: string | null;
};

const defaultErrorsObj: IErrors = {
  name: null
};

const errorTexts = {
  name: 'Brand name too short!'
};

const BrandForm: React.FunctionComponent<IProps> = ({ onClose, brandInput }: IProps) => {
  const [name, setName] = useState(brandInput?.name || '');
  const [sendGridKey, setSendGridKey] = useState(brandInput?.sendGridKey || '');
  const [accountSID, setAccountSID] = useState(brandInput?.accountSID || '');
  const [authToken, setAuthToken] = useState(brandInput?.authToken || '');
  const [messagingServiceSid, setMessagingServiceSid] = useState(
    brandInput?.messagingServiceSid || ''
  );
  const [unsubscribeLink, setUnsubscribeLink] = useState(brandInput?.unsubscribeLink || '');
  const queryClient = useQueryClient();
  const { dispatch } = useContext(AppContext);
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const queryKey = ['brands'];

  const createBrand = useMutation(createBrandMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Brand ${getBrandDisplayName(data)} created`
        }
      });
      dispatch({
        type: Actions.AddBrand,
        payload: {
          brand: data
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey)
  });

  const updateBrand = useMutation(uptadeBrandMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Brand ${name} updated`
        }
      });
      dispatch({
        type: Actions.UpdateBrand,
        payload: {
          brand: data
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey)
  });

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      name: value && value.length > 0 ? null : errorTexts.name
    });
    setName(value);
  };

  const handleSendGridKey = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    setSendGridKey(value);
  };

  const handleSave = (type: 'create' | 'update') => {
    const input = {
      name,
      sendGridKey,
      accountSID,
      authToken,
      messagingServiceSid,
      unsubscribeLink
    } as INewBrandModel;
    if (type === 'create') {
      createBrand.mutate(input);
    } else {
      updateBrand.mutate({
        ...input,
        id: brandInput?.id as number
      });
    }
  };

  const handleSubmit = () => {
    const trimmedName = name ? name.trim() : '';
    const errors = {
      name: null
    } as IErrors;
    let hasErrors = false;
    if (trimmedName.length === 0) {
      hasErrors = true;
      errors.name = errorTexts.name;
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      handleSave(brandInput === null ? 'create' : 'update');
    }
  };

  return (
    <DialogBox width={500} onClose={onClose} title={brandInput ? 'Edit Brand' : 'Create Brand'}>
      <Box
        sx={{
          mb: '24px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px'
        }}
      >
        <InputComponent
          id="brand-name"
          label="Brand Name"
          errorMsg={formErrors.name}
          value={name}
          onChange={handleNameChange}
        />
        <InputComponent
          id="send-grid-key"
          label="Sendgrid Key"
          value={sendGridKey}
          onChange={handleSendGridKey}
        />
        <InputComponent
          id="twillio-account-sid"
          label="Twillio Account SID"
          value={accountSID}
          onChange={(e) => setAccountSID(e.currentTarget.value)}
        />
        <InputComponent
          id="twillio-auth-token"
          label="Twillio Auth Token"
          value={authToken}
          onChange={(e) => setAuthToken(e.currentTarget.value)}
        />
        <InputComponent
          id="twillio-messaging-sid"
          label="Twillio messaging Service Sid"
          value={messagingServiceSid}
          onChange={(e) => setMessagingServiceSid(e.currentTarget.value)}
        />
        <InputComponent
          id="unsubscribe-link"
          label="Unsubscribe link template"
          value={unsubscribeLink}
          onChange={(e) => setUnsubscribeLink(e.currentTarget.value)}
        />
      </Box>
      <Box className="form-actions">
        <Box className="form-group-buttons">
          <CancelButton
            id="brand-cancel"
            text="Cancel"
            onClick={onClose}
            sx={{ height: '36px', width: '48px' }}
          />
          <MainButton
            id="brand-create"
            text={brandInput ? 'Update' : 'Create'}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
      <Loader loading={createBrand.isLoading || updateBrand.isLoading} />
    </DialogBox>
  );
};

export default BrandForm;

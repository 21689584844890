import axios from 'axios';
import { AppConsts } from '../enums/AppConsts';
import { INewBrandModel, IBrand } from '../models/brand';

const baseUrl = process.env.BASE_SERVER_ADDRESS;
const getToken = () => localStorage.getItem(AppConsts.AccessToken);

const getHeaders = () => ({
  headers: { Authorization: `Bearer ${getToken()}` }
});

export const getCompanyBrandsQuery = async () => {
  return await axios.get(`${baseUrl}/Brand`, getHeaders());
};

export const createBrandMutation = async (data: INewBrandModel) => {
  return await axios.post(`${baseUrl}/Brand/create`, data, getHeaders());
};

export const deleteBrandMutation = async (id: number) => {
  return await axios.delete(`${baseUrl}/Brand/delete/${id}`, getHeaders());
};

export const uptadeBrandMutation = async (data: IBrand) => {
  return await axios.post(`${baseUrl}/Brand/update/${data.id}`, data, getHeaders());
};



import { Actions } from '../enums/ActionEnums';
import { ActionType, UserType } from '../models/appContext';
import { getBrandId } from '../utils/ui';

export const userReducer = (state: UserType, action: ActionType): UserType => {
  switch (action.type) {
    case Actions.SetUser:
      return {
        data: action.payload.data,
        token: action.payload.token
      };
    case Actions.RemoveUser:
      return { data: null, token: null };
    case Actions.AddBrand:
      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              brands: [action.payload.brand, ...state.data?.brands]
            }
          : null
      };
    case Actions.DeleteBrand:
      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              brands: state.data?.brands.filter((b) => b !== action.payload.brand)
            }
          : null
      };
    case Actions.UpdateBrand:
      return {
        ...state,
        data: state.data
          ? {
              ...state.data,
              brands: state.data?.brands.map((b) =>
                getBrandId(b) === getBrandId(action.payload.brand) ? action.payload.brand : b
              )
            }
          : null
      };
    default:
      return state;
  }
};

import React from 'react';
import { Button, SxProps } from '@mui/material';

interface IProps {
  id: string;
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  sx?: SxProps;
  icon?: React.ReactNode;
}

const AddButton: React.FunctionComponent<IProps> = ({
  id,
  text,
  onClick,
  disabled = false,
  icon,
  sx
}: IProps) => {
  return (
    <Button
      id={id}
      fullWidth
      disabled={disabled}
      onClick={onClick}
      variant="outlined"
      sx={{ height: '36px', gap: '5px', ...sx }}
    >
      {icon}
      {text}
    </Button>
  );
};

export default AddButton;

import React, { useState } from 'react';
import DialogBox from '../../../Common/Layout/DialogBox';
import IgaStepper from '../../../Common/Layout/Stepper/IgaStepper';
import { Box } from '@mui/material';
import CancelButton from '../../../Common/Buttons/CancelButton';
import MainButton from '../../../Common/Buttons/MainButton';
import ContentStep from '../../Promotions/PromoStepper/ContentStep';
import StepTimeFrame from './StepTimeFrame';
import { DateTimeItem, TimeZone } from '../../../../models/global';

interface IProps {
  stepName: string;
  onClose: () => void;
  channel: string;
  setChannel: (input: string) => void;
  smsText: string;
  setSmsText: (input: string | null) => void;
  emailSender: number | null;
  setEmailSender: (input: number | null) => void;
  template: string | null;
  setTemplate: (input: string | null) => void;
  timezone: TimeZone | null;
  setTimezone: (input: TimeZone | null) => void;
  dateHours: DateTimeItem[];
  setDateHours: (input: DateTimeItem[]) => void;
}

const StepConfig: React.FunctionComponent<IProps> = ({
  onClose,
  stepName,
  channel,
  setChannel,
  smsText,
  setSmsText,
  emailSender,
  setEmailSender,
  template,
  setTemplate,
  timezone,
  setTimezone,
  dateHours,
  setDateHours
}: IProps) => {
  const steps = ['Content', 'Time frame'];
  const [activeStep, setActiveStep] = useState(0);

  const getContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <ContentStep
            channel={channel}
            setChannel={setChannel}
            emailSender={emailSender}
            setEmailSender={setEmailSender}
            template={template}
            setTemplate={setTemplate}
            smsText={smsText}
            setSmsText={setSmsText}
            allowBoth
          />
        );
      case 1:
        return (
          <StepTimeFrame
            timezone={timezone}
            setTimezone={setTimezone}
            dateHours={dateHours}
            setDateHours={setDateHours}
          />
        );

      default:
        break;
    }
  };

  return (
    <DialogBox width={1400} onClose={onClose} title={`Configure ${stepName} step`}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <IgaStepper steps={steps} activeStep={activeStep}>
          {getContent()}
        </IgaStepper>
        <Box className="form-actions" sx={{ mt: '16px' }}>
          <Box className="form-group-buttons">
            <CancelButton
              id="promo-back"
              text="Back"
              onClick={() => setActiveStep(activeStep - 1)}
              disabled={activeStep === 0}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton
              id="promo-next"
              text={activeStep + 1 === steps.length ? 'Save' : 'Next'}
              onClick={() => {
                activeStep === 1 ? onClose() : setActiveStep(activeStep + 1);
              }}
            />
          </Box>
        </Box>
      </Box>
    </DialogBox>
  );
};

export default StepConfig;

import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  SxProps,
  Typography
} from '@mui/material';
import { generateUUID } from '../../../utils/validators';
import { displayText, NormalizeCamelCaseString } from '../../../utils/ui';
import moment from 'moment';

type Props = {
  title: string;
  equalColumns?: boolean;
  rows: InfoRow[];
  minHeight?: string;
  loading?: boolean;
  subtitle?: JSX.Element;
  sx?: SxProps;
};

export type InfoRow = {
  title: string;
  field?: string;
  value: string;
  render?: () => string | JSX.Element;
};

function InfoCard({
  title,
  rows,
  equalColumns,
  minHeight = '0px',
  loading = false,
  subtitle,
  sx
}: Props) {
  const displayData = () => {
    if (rows) {
      return (
        <Box>
          {loading ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <CircularProgress />
            </Box>
          ) : (
            rows.map((row) => (
              <Grid container key={generateUUID()} sx={{ paddingTop: '10px' }}>
                <Grid
                  item
                  xs={equalColumns ? 6 : 4}
                  key={generateUUID()}
                  sx={{
                    fontWeight: 600,
                    fontSize: '12px',
                    color: (t) => t.palette.grey[300],
                    textAlign: 'left'
                  }}
                >
                  {NormalizeCamelCaseString(row.title)}
                </Grid>
                <Grid
                  item
                  xs={equalColumns ? 6 : 8}
                  key={generateUUID()}
                  sx={{
                    fontWeight: '400 !important',
                    fontSize: '14px',
                    color: (t) => t.palette.primary.main,
                    textAlign: 'left',
                    pl: '30px'
                  }}
                >
                  {row.render ? row.render() : displayObjectValue(row.title, row.value)}
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: '10px' }}></Grid>
              </Grid>
            ))
          )}
        </Box>
      );
    }
  };

  const displayObjectValue = (key: string, value: string | null) => {
    if (key.toUpperCase().includes('DATE')) {
      return displayText(value ? moment(value).format('DD/MM/YYYY') : '');
    } else {
      return displayText(value);
    }
  };

  return (
    <Card
      sx={{
        boxShadow: '0px 10px 15px rgba(8, 79, 180, 0.05)',
        borderRadius: '16px',
        minHeight: minHeight,
        ...sx
      }}
    >
      <CardContent sx={{ padding: '24px' }}>
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            marginBottom: '11px',
            lineHeight: '22px',
            textAlign: 'left'
          }}
          color="primary.main"
          gutterBottom
        >
          {title}
        </Typography>
        {subtitle && subtitle}
        {displayData()}
      </CardContent>
    </Card>
  );
}

export default InfoCard;

import React from 'react';
import { Box, InputAdornment, InputLabel, SxProps, TextField } from '@mui/material';
import { colors } from '../../../utils/theme';
import { getBorderColor, getBorderColorOnHover } from '../../../utils/ui';
import InfoIcon from '@mui/icons-material/Info';

interface IProps {
  label: string;
  id: string;
  value: string | number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: 'text' | 'number' | 'password';
  errorMsg?: string | null;
  placeholder?: string;
  disabled?: boolean;
  containerStyles?: SxProps;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
  helperText?: string;
}

const inputPropsStyle = {
  padding: '12px 16px',
  '&:-webkit-autofill': {
    WebkitTextFillColor: colors.info.main
  }
};

const InputComponent: React.FunctionComponent<IProps> = ({
  label,
  id,
  value,
  onChange,
  errorMsg,
  type = 'text',
  placeholder = '',
  disabled = false,
  containerStyles,
  startIcon,
  endIcon,
  helperText
}) => {
  const textFieldProps = {
    height: '46px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: getBorderColor(value.toString())
      },
      '&:hover fieldset, &.Mui-focused fieldset': {
        borderColor: getBorderColorOnHover(errorMsg)
      }
    }
  };
  return (
    <Box sx={{ ...containerStyles }}>
      <InputLabel htmlFor={id} sx={{ marginBottom: '4px' }}>
        {label}
      </InputLabel>
      <TextField
        id={id}
        type={type}
        fullWidth
        value={value}
        onChange={onChange}
        sx={textFieldProps}
        disabled={disabled}
        placeholder={placeholder}
        InputProps={{
          sx: textFieldProps,
          startAdornment: startIcon ? (
            <InputAdornment position="start">{startIcon}</InputAdornment>
          ) : null,
          endAdornment: endIcon ? <InputAdornment position="end">{endIcon}</InputAdornment> : null
        }}
        inputProps={{
          sx: {
            ...inputPropsStyle,
            paddingLeft: startIcon ? '0' : '16px',
            paddingRight: endIcon ? '0' : '16px'
          }
        }}
        {...(errorMsg !== undefined && {
          error: errorMsg !== null,
          helperText: errorMsg ?? ''
        })}
      />
      {helperText && (
        <Box className="helper-text-box">
          <InfoIcon sx={{ width: '16px', height: '16px', color: '#C9C8D0' }} /> {helperText}
        </Box>
      )}
    </Box>
  );
};

export default InputComponent;

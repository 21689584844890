import { Actions } from '../enums/ActionEnums';
import { ActionType } from '../models/appContext';
import { JourneyStage } from '../models/journeys';

export const journeyStageReducer = (state: JourneyStage | null, action: ActionType) => {
  switch (action.type) {
    case Actions.SetSelectedJourneyStage:
      return action.payload.stage;
    default:
      return state;
  }
};

import React from 'react';
import { Box, SxProps } from '@mui/material';
import JourneyStep from './JourneyStep';
import { JourneyStage } from '../../../../models/journeys';

interface IProps {
  steps: JourneyStage;
  sx?: SxProps;
}

const JourneySteps: React.FunctionComponent<IProps> = ({ sx, steps }: IProps) => {
  return (
    <Box>
      {steps.children?.map((rootNode, index) => (
        <JourneyStep key={rootNode.id} node={rootNode} index={index + 1} />
      ))}
    </Box>
  );
};

export default JourneySteps;

import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import { Box, Slide } from '@mui/material';
import { IKanbanStage } from '../../../models/global';
import KanbanStageItems from './KanbanStageItems';
import { PlayerKanbanStage } from '../../../models/player';

interface KanbanCardProps {
  item: unknown;
  index: number;
}

interface IProps {
  stages: IKanbanStage[];
  handleStageChange: (sourceName: string, destinationName: string, item: unknown) => void;
  renderCard: ({ item, index }: KanbanCardProps) => React.ReactNode;
  handleKanbanStageEdit: (kanbanStage: PlayerKanbanStage) => void;
}

const KanbanBoard: React.FunctionComponent<IProps> = ({
  stages,
  handleStageChange,
  renderCard,
  handleKanbanStageEdit
}: IProps) => {
  const [columns, setColumns] = useState(stages);

  useEffect(() => {
    setColumns(stages);
  }, [stages]);

  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const onDragEnd = (result: DropResult, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      });
      handleStageChange(sourceColumn.title, destColumn.title, removed);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      });
    }
  };

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        {Object.entries(columns).map(([columnId, column], i) => {
          return (
            <Droppable key={columnId} droppableId={columnId}>
              {(provided) => (
                <Slide key={i} direction="right" in timeout={1000 + i * 200}>
                  <Box ref={provided.innerRef} {...provided.droppableProps}>
                    <KanbanStageItems
                      column={column}
                      handleKanbanStageEdit={handleKanbanStageEdit}
                      renderCard={renderCard}
                    />
                  </Box>
                </Slide>
              )}
            </Droppable>
          );
        })}
      </Box>
    </DragDropContext>
  );
};

export default KanbanBoard;

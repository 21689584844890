import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  IconButton,
  LabelDisplayedRowsArgs,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import { Launch, Search } from '@mui/icons-material';
import { Theme } from '@mui/system';
import { colors } from '../../../utils/theme';
import { displayText } from '../../../utils/ui';
import AddButton from '../Buttons/AddButton';
import { BeatLoader } from 'react-spinners';

type Order = 'asc' | 'desc';

type Data = any;

interface IProps {
  toolbarTitle: string | null;
  columns: IHeadCell[];
  data: Data[];
  Actions?: (props: IActionsProps) => JSX.Element;
  isLayoutFixed?: boolean;
  hidePagination?: boolean;
  rowsPerPageOptions?: number[];
  rowsPerPage?: number;
  createButtonProps?: CreateButtonProps;
  showSearch?: boolean;
  handleDetails?: (rowData: any) => void;
  exportFunc?: () => void;
  capitalizeTitle?: boolean;
  filters?: JSX.Element;
  isFirstCellStatic?: boolean;
  loading?: boolean;
}

export interface IActionsProps {
  rowData: Data;
}

interface ITableToolbarProps {
  title: string;
  exportFunc?: () => void;
  filters?: any;
}

interface ITableHeadersProps {
  columns: IHeadCell[];
  order: Order;
  orderBy: keyof Data | null;
  onRequestSort: (property: keyof Data) => void;
  anyActions: boolean;
  capitalize: boolean;
  isFirstCellStatic: boolean;
}

export interface IHeadCell {
  title: string;
  field?: string;
  type?: 'string' | 'numeric' | 'boolean' | 'money';
  render?: (data: Data) => string | JSX.Element;
  sorting?: boolean;
  searchable?: boolean;
  customFilterAndSearch?: (filter: string, rowData: Data, columnDef: IHeadCell) => boolean;
}

interface CreateButtonProps {
  id: string;
  onClick: () => void;
  text: string;
  disabled?: boolean;
}

const tableHeaderCellStyle: SxProps<Theme> = {
  color: (theme) => theme.palette.grey[50],
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '16px',
  letterSpacing: '0.05em',
  zIndex: 1,
  backgroundColor: 'common.white'
};

export const BooleanBox = ({ value }: { value: boolean }) => (
  <Box>
    <Box
      sx={{
        width: '8px',
        height: '8px',
        backgroundColor: value ? colors.green.second : colors.error.second,
        display: 'inline-block',
        borderRadius: '50px',
        marginRight: '4px'
      }}
    ></Box>
    {value ? 'Yes' : 'No'}
  </Box>
);

export const ColorBox = ({ text, color }: { text: string | undefined; color: string }) => (
  <Box>
    <Box
      sx={{
        width: '8px',
        height: '8px',
        backgroundColor: color,
        display: 'inline-block',
        borderRadius: '50px',
        marginRight: '4px'
      }}
    ></Box>
    {text}
  </Box>
);

const TableToolbar = ({ title, exportFunc, filters }: ITableToolbarProps) => {
  return (
    <Box>
      <Toolbar
        sx={{
          padding: '0 !important',
          minHeight: '0 !important',
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '12px'
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '22px',
            color: 'primary.main'
          }}
        >
          {title}
        </Typography>
        {filters}
        {exportFunc && (
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: '16px',
              lineHeight: '22px',
              color: 'primary.main'
            }}
          >
            <IconButton
              sx={{
                color: 'info.main',
                flexGrow: 0,
                fontSize: '14px',
                fontWeight: 600
              }}
              onClick={exportFunc}
            >
              <Launch sx={{ width: '16px', height: '16px' }} />
              Export
            </IconButton>
          </Typography>
        )}
      </Toolbar>
      <Divider sx={{ width: '100%', marginTop: '11px' }} />
    </Box>
  );
};

const TableHeaders = ({
  columns,
  order,
  orderBy,
  onRequestSort,
  anyActions,
  capitalize,
  isFirstCellStatic
}: ITableHeadersProps) => {
  const getStaticProps = (index: number) =>
    isFirstCellStatic && index === 0
      ? ({ position: 'sticky', left: 0, zIndex: 2 } as SxProps)
      : ({} as SxProps);
  return (
    <TableHead>
      <TableRow>
        {columns.map((col, index) => (
          <TableCell
            key={index}
            align={col.type === 'numeric' ? 'right' : 'left'}
            padding="none"
            sortDirection={orderBy === col.field ? order : false}
            sx={{ ...tableHeaderCellStyle, ...getStaticProps(index) }}
          >
            {col.sorting !== false ? (
              <TableSortLabel
                active={orderBy === col.field}
                direction={orderBy === col.field ? order : 'asc'}
                onClick={() => onRequestSort(col.field as keyof Data)}
              >
                <Box sx={{ ...tableHeaderCellStyle, ...getStaticProps(index) }}>
                  {capitalize ? col.title.toUpperCase() : col.title}
                </Box>
              </TableSortLabel>
            ) : (
              col.title
            )}
          </TableCell>
        ))}
        {anyActions ? (
          <TableCell padding="none" sx={tableHeaderCellStyle}>
            {' '}
          </TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof Data>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: Data }, b: { [key in Key]: Data }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const CustomTable: React.FunctionComponent<IProps> = ({
  toolbarTitle,
  columns,
  data,
  Actions,
  isLayoutFixed,
  hidePagination,
  rowsPerPageOptions,
  rowsPerPage,
  createButtonProps,
  showSearch,
  handleDetails,
  exportFunc,
  capitalizeTitle = false,
  isFirstCellStatic = false,
  filters,
  loading = false
}: IProps) => {
  const itemsPerPageOptions = rowsPerPageOptions ?? [5, 10, 25];
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data | null>(null);
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(rowsPerPage ?? 25);
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
    setPage(0);
  }, [data]);

  const handleRequestSort = (property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(isAsc ? property : orderBy !== property ? property : null);
  };

  const getReadyToUseData = () => {
    let rows = filteredData.slice();
    if (orderBy !== null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      rows = rows.sort(getComparator(order, orderBy));
    }
    if (hidePagination !== true) {
      rows = rows.slice(page * itemsPerPage, page * itemsPerPage + itemsPerPage);
    }
    return rows;
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * itemsPerPage - filteredData.length) : 0;

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearchValue(value);
    filterData(value);
    setPage(0);
  };

  const filterData = (filter: string) => {
    const trimmedSearchText = filter.trim();
    setFilteredData(
      data.filter((row) => {
        return columns
          .filter((columnDef) => {
            return columnDef.searchable === undefined ? true : columnDef.searchable;
          })
          .some((columnDef) => {
            if (columnDef.customFilterAndSearch) {
              return !!columnDef.customFilterAndSearch(trimmedSearchText, row, columnDef);
            } else if (columnDef.field) {
              const value = row[columnDef.field as keyof Data] ?? '';
              return value?.toString().toUpperCase().includes(trimmedSearchText.toUpperCase());
            }
          });
      })
    );
  };

  const searchAndCreate = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          marginBottom: '47px',
          marginTop: '-87px',
          marginRight: '-24px'
        }}
      >
        {showSearch && (
          <TextField
            id="table-search-field"
            value={searchValue}
            onChange={handleValueChange}
            size="small"
            sx={{
              color: 'primary.main',
              width: '250px',
              '& .MuiInputBase-root': {
                height: '36px'
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: searchValue ? `${colors.info.main} !important` : 'default'
                }
              }
            }}
            placeholder={'Search...'}
            InputProps={{
              endAdornment: <Search style={{ color: '#9CA3AE', width: 18, height: 18 }} />,
              style: {
                marginTop: 'auto',
                marginBottom: 'auto',
                marginRight: createButtonProps ? '16px' : 0
              }
            }}
            className={'custom-search'}
          />
        )}
        {createButtonProps && (
          <AddButton
            id={createButtonProps.id}
            text={createButtonProps.text}
            sx={{ width: '162px' }}
            onClick={createButtonProps.onClick}
          />
        )}
      </Box>
    );
  };

  const getCellValue = (rowData: any, path: string): string => {
    const parts = path.split('.');
    if (parts.length == 1) {
      return rowData[parts[0]] !== undefined && rowData[parts[0]] !== null
        ? rowData[parts[0]].toString()
        : '';
    } else {
      if (rowData[parts[0]]) {
        return getCellValue(rowData[parts[0]], parts.slice(1).join('.'));
      } else {
        return '';
      }
    }
  };
  return (
    <Paper
      sx={{
        width: '100%',
        padding: '24px',
        boxShadow: '0px 10px 15px rgba(8, 79, 180, 0.05)',
        borderRadius: '24px'
      }}
    >
      {(showSearch || createButtonProps) && searchAndCreate()}
      {toolbarTitle && (
        <TableToolbar title={toolbarTitle} exportFunc={exportFunc} filters={filters} />
      )}
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          sx={{ tableLayout: isLayoutFixed === true ? 'fixed' : 'default' }}
        >
          <TableHeaders
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            columns={columns}
            anyActions={Boolean(Actions)}
            capitalize={capitalizeTitle}
            isFirstCellStatic={isFirstCellStatic}
          />
          <TableBody>
            {getReadyToUseData().map((rowData, index) => (
              <TableRow
                key={index}
                hover
                onClick={handleDetails ? () => handleDetails(rowData) : () => true}
                sx={{
                  cursor: handleDetails ? 'pointer' : 'default',
                  '&.MuiTableRow-hover': {
                    '&:hover': {
                      backgroundColor: colors.pink.background,
                      '& td': {
                        backgroundColor: colors.pink.background
                      }
                    }
                  }
                }}
              >
                {columns.map((col, index2) => (
                  <TableCell
                    key={index2}
                    sx={{
                      color: 'primary.main',
                      position: index2 === 0 ? 'sticky' : 'relative',
                      zIndex: index2 === 0 ? 2 : 1,
                      left: 0,
                      backgroundColor: rowData.background ?? 'common.white'
                    }}
                    align={col.type === 'numeric' ? 'right' : 'left'}
                  >
                    {col.render
                      ? col.render(rowData)
                      : displayText(getCellValue(rowData, col.field ?? ''), col.type)}
                  </TableCell>
                ))}
                {Actions ? (
                  <TableCell sx={{ color: 'primary.main', padding: '0 !important' }} align="right">
                    <Actions rowData={rowData} />
                  </TableCell>
                ) : null}
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 48 * emptyRows + 8 * (emptyRows - 1)
                }}
              >
                <TableCell colSpan={columns.length + (Actions ? 1 : 0)} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {hidePagination !== true && (
        <TablePagination
          rowsPerPageOptions={itemsPerPageOptions}
          showFirstButton={true}
          showLastButton={true}
          component="div"
          count={filteredData.length}
          rowsPerPage={itemsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          backIconButtonProps={{
            contextMenu: 'back',
            color: 'info',
            title: 'Previous'
          }}
          labelDisplayedRows={({ count }: LabelDisplayedRowsArgs) => {
            const pageCount = count > itemsPerPage ? Math.floor(count / itemsPerPage + 1) : 1;
            return `${page + 1} of ${pageCount}`;
          }}
          nextIconButtonProps={{
            color: 'info',
            title: 'Next'
          }}
          labelRowsPerPage={
            <Box sx={{ display: 'flex', gap: '6px' }}>
              <span
                style={{
                  fontSize: '14px',
                  lineHeight: '17px',
                  color: colors.grey[50]
                }}
              >
                Total:
              </span>
              {loading ? (
                <BeatLoader color={colors.info.main} size={10} />
              ) : (
                <span
                  style={{
                    color: colors.info.main,
                    fontSize: '12px',
                    fontWeight: 700
                  }}
                >
                  {data?.length}
                </span>
              )}
            </Box>
          }
          SelectProps={{
            renderValue: (props) => `${props} Rows/Page`,
            sx: {
              color: colors.primary.main,
              border: `1px solid ${colors.primary.main}`,
              borderRadius: '8px',
              backgroundColor: colors.white,
              fontSize: '12px',
              lineHeight: '16px',
              fontWeight: 700,
              marginRight: '20px',
              '&.Mui-focused div': {
                borderRadius: '8px',
                backgroundColor: colors.white
              }
            }
          }}
        />
      )}
    </Paper>
  );
};

export default CustomTable;

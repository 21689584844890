import { Actions } from '../enums/ActionEnums';
import { ActionType } from '../models/appContext';
import { JourneyGroup } from '../models/journeys';

export const journeyGroupReducer = (state: JourneyGroup[], action: ActionType) => {
  switch (action.type) {
    case Actions.SetJourneyGroups:
      return action.payload.groups;
    default:
      return state;
  }
};

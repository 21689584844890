import React, { useState } from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import { ScreenType } from '../../../enums/AppConsts';

const iframeStyles = {
  width: '100%',
  height: '500px',
  border: 'none',
  overflowX: 'auto',
  overflowY: 'hidden'
} as React.CSSProperties;

const screenWidth = {
  mobile: '400px',
  desktop: '100%'
};

const HtmlPreview = ({ htmlContent }: { htmlContent: string }) => {
  const [previewWidth, setPreviewWidth] = useState('100%');
  const [resolution, setResolution] = useState(ScreenType.Desktop);

  const handleDeviceChange = (screen: ScreenType) => {
    setPreviewWidth(screenWidth[screen]);
    setResolution(screen);
  };

  return (
    <Box>
      <ButtonGroup variant="outlined" aria-label="outlined button group">
        <Button
          variant={resolution === ScreenType.Desktop ? 'contained' : 'outlined'}
          onClick={() => handleDeviceChange(ScreenType.Desktop)}
        >
          {ScreenType.Desktop}
        </Button>
        <Button
          variant={resolution === ScreenType.Mobile ? 'contained' : 'outlined'}
          onClick={() => handleDeviceChange(ScreenType.Mobile)}
        >
          {ScreenType.Mobile}
        </Button>
      </ButtonGroup>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: previewWidth }}>
          <iframe allowFullScreen srcDoc={htmlContent} style={iframeStyles} title="Preview" />
        </Box>
      </Box>
    </Box>
  );
};

export default HtmlPreview;

import axios from 'axios';
import { baseUrl, getHttpHeaders } from '../utils/rights';

const controller = 'kpi';

export const DashboardKpiQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/${controller}/Dashboard/${brandId}`, getHttpHeaders());
};

export const PlayerKpiQuery = async ({
  brandId,
  playerId
}: {
  brandId: number;
  playerId: string;
}) => {
  return await axios.get(
    `${baseUrl}/${controller}/Player/${brandId}/${playerId}`,
    getHttpHeaders()
  );
};

export const PlayerWalletHistoryQuery = async ({
  brandId,
  playerId
}: {
  brandId: number;
  playerId: string;
}) => {
  return await axios.get(
    `${baseUrl}/${controller}/WalletHistory/${brandId}/${playerId}`,
    getHttpHeaders()
  );
};

export const BrandBonusesHistoryQuery = async ({ brandId }: { brandId: number }) => {
  return await axios.get(
    `${baseUrl}/${controller}/BrandBonusesHistory/${brandId}`,
    getHttpHeaders()
  );
};

export const BrandMailingHistoryQuery = async ({ brandId }: { brandId: number }) => {
  return await axios.get(
    `${baseUrl}/${controller}/BrandMailingHistory/${brandId}`,
    getHttpHeaders()
  );
};

import React, { useContext, useState } from 'react';
import DialogBox from '../../Common/Layout/DialogBox';
import { Box } from '@mui/material';
import InputComponent from '../../Common/Fields/InputComponent';
import CancelButton from '../../Common/Buttons/CancelButton';
import MainButton from '../../Common/Buttons/MainButton';
import { AppContext } from '../../../AppContext';
import { getBrandId, handleRequestError } from '../../../utils/ui';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreatePlayerKanbanStage, UpdatePlayerKanbanStage } from '../../../queries/players';
import { Actions } from '../../../enums/ActionEnums';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { PlayerKanbanStage } from '../../../models/player';
import Loader from '../../Common/Global/Loader';

interface IProps {
  onClose: () => void;
  stageInput: PlayerKanbanStage | null;
}

export type IErrors = {
  name: string | null;
  orderNumber: string | null;
};

const defaultErrorsObj: IErrors = {
  name: null,
  orderNumber: null
};

const errorTexts = {
  name: 'Stage name too short!',
  orderNumber: 'Invalid order number!'
};

const StageForm: React.FunctionComponent<IProps> = ({ onClose, stageInput }: IProps) => {
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const { dispatch, state } = useContext(AppContext);
  const [stageName, setStageName] = useState(stageInput?.title || '');
  const [orderNumber, setOrderNumber] = useState(stageInput?.orderNumber || 0);
  const brandId = getBrandId(state.selectedBrand);
  const queryClient = useQueryClient();

  const createKanbanStage = useMutation(CreatePlayerKanbanStage, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Stage ${data.stageName} created`
        }
      });
      onClose();
    },
    onError: ({ response }) => handleRequestError(dispatch, response),
    onSettled: () => {
      queryClient.invalidateQueries([QueryKey.KanbanStages, brandId]);
      queryClient.invalidateQueries([QueryKey.Players]);
    }
  });

  const updateKanbanStage = useMutation(UpdatePlayerKanbanStage, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Kanban stage ${data.stageName} updated`
        }
      });
      onClose();
    },
    onError: ({ response }) => handleRequestError(dispatch, response),
    onSettled: () => {
      queryClient.invalidateQueries([QueryKey.KanbanStages, brandId]);
      queryClient.invalidateQueries([QueryKey.Players]);
    }
  });

  const handleStageNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      name: value && value.length > 0 ? null : errorTexts.name
    });
    setStageName(value);
  };

  const handleOrderNumberChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      orderNumber: value && Number(value) !== 0 ? null : errorTexts.name
    });
    setOrderNumber(Number(value));
  };
  const handleSave = (type: 'create' | 'update') => {
    if (type === 'create') {
      createKanbanStage.mutate({
        stageName: stageName,
        orderNumber: Number(orderNumber)
      });
    } else {
      const input = {
        id: stageInput?.id as number,
        stageName: stageName as string,
        orderNumber: orderNumber as number
      };
      updateKanbanStage.mutate(input);
    }
  };

  const handleSubmit = () => {
    const trimmedStageName = stageName ? stageName.trim() : '';
    const errors = {
      name: null,
      orderNumber: null
    } as IErrors;
    let hasErrors = false;
    if (trimmedStageName.length === 0) {
      hasErrors = true;
      errors.name = errorTexts.name;
    }
    if (!orderNumber) {
      hasErrors = true;
      errors.orderNumber = errorTexts.orderNumber;
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      handleSave(stageInput === null ? 'create' : 'update');
    }
  };

  return (
    <DialogBox
      width={800}
      onClose={onClose}
      title={stageInput ? 'Edit Kanban Stage' : 'Create Kanban Stage'}
    >
      <Box
        sx={{
          mb: '24px',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
          gap: '20px'
        }}
      >
        <InputComponent
          id="stage-name"
          label="Stage Name"
          errorMsg={formErrors.name}
          value={stageName}
          onChange={handleStageNameChange}
          containerStyles={{ gridColumn: 'span 1' }}
        />
        <InputComponent
          id="order-number"
          label="Order Number"
          type="number"
          errorMsg={formErrors.orderNumber}
          value={orderNumber}
          onChange={handleOrderNumberChange}
          containerStyles={{ gridColumn: 'span 1' }}
        />
      </Box>
      <Box className="form-actions">
        <Box className="form-group-buttons">
          <CancelButton
            id="kanbanStage-cancel"
            text="Cancel"
            onClick={onClose}
            sx={{ height: '36px', width: '48px' }}
          />
          <MainButton
            id="kanbanStage-create"
            text={stageInput ? 'Update' : 'Create'}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
      <Loader loading={createKanbanStage.isLoading || updateKanbanStage.isLoading} />
    </DialogBox>
  );
};

export default StageForm;

import { ChangePasswordModel, LoginModel, ResetPasswordModel } from '../models/account';
import axios from 'axios';
import { getHttpHeaders } from '../utils/rights';

const baseUrl = process.env.BASE_SERVER_ADDRESS;

export const LoginQuery = async (data: LoginModel) => {
  return await axios.post(`${baseUrl}/account/login`, data);
};

export const ForgetPassQuery = async (email: string) => {
  const data = {
    email: email
  };
  return await axios.post(`${baseUrl}/account/ForgotPassword`, data);
};

export const ResetPassQuery = async (data: ResetPasswordModel) => {
  return await axios.post(`${baseUrl}/account/ResetPassword`, data);
};

export const ChangePasswordQuery = async (data: ChangePasswordModel) => {
  return await axios.post(`${baseUrl}/account/changepassword`, data, getHttpHeaders());
};

export const NewEmailConfirmQuery = async (email: string) => {
  return await axios.post(`${baseUrl}/account/NewEmailConfirm`, {
    email: email
  });
};

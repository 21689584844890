import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import { AppProvider, AppContext } from '../AppContext';
import { buildTheme } from '../utils/theme';
import Auth from '../auth/Auth';
import { AuthRoutes } from '../enums/RouteEnums';
import AuthRoute from '../auth/AuthRoute';
import Message from './Common/Global/Message';
import Navigation from './Navigation/Navigation';
import Confirmation from './Common/Global/Confirmation';
import { IRouteModel } from '../Routes';

const App: React.FunctionComponent = () => {
  const { state } = useContext(AppContext);
  const appRoutes: IRouteModel[] = [];
  state.routes.forEach((r) => {
    r.subRoutes ? r.subRoutes.forEach((sr) => appRoutes.push(sr)) : appRoutes.push(r);
  });

  return (
    <ThemeProvider theme={buildTheme()}>
      <AppProvider>
        <Auth>
          <Router>
            <Box sx={{ display: 'flex', paddingTop: '80px' }}>
              <Navigation />
              <Routes>
                {appRoutes.map((r, index) =>
                  r.Component ? (
                    <Route
                      key={index}
                      path={r.path}
                      element={
                        <AuthRoute
                          isPublic={r.isPublic}
                          requiredRole={r.requiredRole}
                          Component={r.Component}
                        />
                      }
                    />
                  ) : null
                )}
                <Route path="*" element={<Navigate to={AuthRoutes.Home} />} />
              </Routes>
              <Message />
              <Confirmation />
            </Box>
          </Router>
        </Auth>
      </AppProvider>
    </ThemeProvider>
  );
};

export default App;

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  SxProps
} from '@mui/material';
import React from 'react';
import { colors } from '../../../utils/theme';

interface IProps {
  id?: string;
  label: string;
  value: string[] | string;
  onChange: (event: SelectChangeEvent<string[]>) => void;
  options?: string[];
  isSingle?: boolean;
  errorMsg?: string | null;
  placeholder?: string;
  containerStyles?: SxProps;
  hideNoneValue?: boolean;
  disabled?: boolean;
  customOptions?: JSX.Element[];
  onCreateClick?: () => void;
  height?: number;
}

const MenuProps = {
  MenuListProps: {
    style: {
      paddingBottom: 0
    }
  },
  PaperProps: {
    style: {
      // maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      paddingBottom: 0
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left'
    }
  }
};

export const getBorderColor = (values: string[] | string, isHover = false, disabled: boolean) => {
  if (disabled) return 'default';
  const res =
    (values && values.length > 0) || isHover ? `${colors.gradients.pinkRgb} !important` : 'default';
  return res;
};

const SelectComponent: React.FunctionComponent<IProps> = ({
  id = 'select',
  label,
  value,
  onChange,
  options,
  isSingle,
  placeholder,
  errorMsg,
  containerStyles,
  hideNoneValue,
  disabled = false,
  customOptions,
  onCreateClick,
  height = 46
}: IProps) => {
  const getValue = () => {
    let result = '' as '' | string[];
    if (typeof value === 'string') {
      if (value.length > 0) {
        result = [value];
      }
    } else if (Array.isArray(value)) {
      result = value;
    }
    return result;
  };

  return (
    <Box sx={{ ...containerStyles }}>
      <InputLabel id={`${id}-label`} sx={{ marginBottom: '4px', textAlign: 'left' }}>
        {label}
      </InputLabel>
      <FormControl sx={{ width: '100%' }}>
        <Select
          displayEmpty
          labelId={`${id}-label`}
          id={`${id}-checkbox`}
          multiple={isSingle === true ? false : true}
          value={getValue()}
          onChange={onChange}
          disabled={disabled}
          input={
            <OutlinedInput
              fullWidth
              sx={{
                height: `${height}px`,
                '& fieldset': {
                  borderColor: getBorderColor(value, false, disabled)
                },
                '&:hover fieldset': {
                  borderColor: getBorderColor(value, true, disabled)
                },
                '&:focused fieldset': {
                  borderColor: colors.gradients.pinkRgb
                }
              }}
              {...(errorMsg !== undefined && {
                error: errorMsg !== null,
                helperText: errorMsg ?? ''
              })}
            />
          }
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Box component="span" sx={{ color: colors.grey.placeholder }}>
                  {placeholder}
                </Box>
              );
            } else {
              return selected.join(', ');
            }
          }}
          MenuProps={MenuProps}
        >
          {isSingle && hideNoneValue !== true && (
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
          )}
          {customOptions
            ? customOptions
            : options?.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: colors.pink.background // set your desired background color here
                    },
                    '&:hover': { backgroundColor: colors.pink.background }
                  }}
                >
                  {isSingle !== true && (
                    <Checkbox
                      checked={value.indexOf(name) > -1}
                      sx={{
                        '&.Mui-checked': {
                          color: colors.gradients.pinkRgb
                        }
                      }}
                    />
                  )}
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
          {onCreateClick && (
            <MenuItem
              onClick={onCreateClick}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: colors.pink.background,
                pt: '5px',
                '&:hover': { backgroundColor: colors.pink.background }
              }}
            >
              <Button
                variant="text"
                onClick={onCreateClick}
                sx={{ color: colors.gradients.pinkRgb, height: '16px' }}
              >
                + Create New
              </Button>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectComponent;

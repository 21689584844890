import React, { useState } from "react";
import { Box, SelectChangeEvent } from "@mui/material";
import SelectComponent from "../../../Common/Fields/SelectComponent";
import AddButton from "../../../Common/Buttons/AddButton";
import { PublishedWithChanges } from "@mui/icons-material";
import "./index.css";
import { Segment } from "../../../../models/segmets";

interface IProps {
  segments: Segment[];
  includedIds: number[];
  setIncludedIds: (values: number[]) => void;
  excludedIds: number[];
  setExcludedIds: (values: number[]) => void;
}

const SegmentsStep: React.FunctionComponent<IProps> = ({
  segments,
  includedIds,
  excludedIds,
  setIncludedIds,
  setExcludedIds,
}: IProps) => {
  const width = "600px";
  const segmentNames = segments.map((s) => s.name);
  const [options] = useState(segmentNames);
  const [included, setIncluded] = useState<string[]>(
    segments.filter((s) => includedIds.includes(s.id)).map((s) => s.name)
  );
  const [excluded, setExcluded] = useState<string[]>(
    segments.filter((s) => excludedIds.includes(s.id)).map((s) => s.name)
  );

  const handleIncludedChange = (event: SelectChangeEvent<typeof included>) => {
    const {
      target: { value },
    } = event;
    const names = typeof value === "string" ? value.split(",") : value;
    setIncluded(names);
    setIncludedIds(
      segments.filter((s) => names.includes(s.name)).map((s) => s.id)
    );
    setExcluded(excluded.filter((e) => !names.includes(e)));
  };

  const handleExcludedChange = (event: SelectChangeEvent<typeof included>) => {
    const {
      target: { value },
    } = event;
    const names = typeof value === "string" ? value.split(",") : value;
    setExcluded(names);
    setExcludedIds(
      segments.filter((s) => names.includes(s.name)).map((s) => s.id)
    );
    setIncluded(included.filter((e) => !names.includes(e)));
  };

  return (
    <Box className="step-container">
      <Box className="step-title">Segments</Box>
      <SelectComponent
        label="Include Segmnets"
        value={included}
        isSingle={false}
        onChange={handleIncludedChange}
        options={options}
        hideNoneValue
        containerStyles={{ width }}
      />
      {/* <AddButton
          id="promo-calculate"
          text="Calculate"
          sx={{ width: '105px' }}
          icon={
            <PublishedWithChanges
              //   className={calculateSegment.isLoading ? 'rotate-icon' : ''}
              sx={{ width: '16px', height: '16px' }}
            />
          }
          onClick={() => true}
        /> */}
      <SelectComponent
        label="Exclude Segmnets"
        value={excluded}
        isSingle={false}
        onChange={handleExcludedChange}
        options={options}
        hideNoneValue
        containerStyles={{ width }}
      />
    </Box>
  );
};

export default SegmentsStep;

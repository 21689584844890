import { Box, IconButton } from "@mui/material";
import React, { useContext, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { AppContext } from "../../AppContext";
import { Actions } from "../../enums/ActionEnums";
import { ChangePasswordQuery } from "../../queries/account";
import { isPasswordValid } from "../../utils/validators";
import DialogBox from "../Common/Layout/DialogBox";
import ModalHeader from "../Common/Layout/ModalHeader";
import InputComponent from "../Common/Fields/InputComponent";
import { Lock, Visibility, VisibilityOff } from "@mui/icons-material";
import { getIconColor } from "../../utils/ui";
import CancelButton from "../Common/Buttons/CancelButton";
import MainButton from "../Common/Buttons/MainButton";
import { colors } from "../../utils/theme";

type IProps = {
  onClose: () => void;
};

type IErrors = {
  currentPassword: string | null;
  newPassword: string | null;
  confirmNewPassword: string | null;
  passwordsMismatch: string | null;
};

const defaultErrorsObj: IErrors = {
  currentPassword: null,
  newPassword: null,
  confirmNewPassword: null,
  passwordsMismatch: null,
};

const ErrorTexts = {
  currentPasswordNotValid: "Password not valid",
  newPasswordNotValid: "Password not valid",
  confirmNewPasswordNotValid: "Confirm password not valid",
  passwordsMismatch: "Password and confirm password do not match",
};

const ChangePassword: React.FunctionComponent<IProps> = ({
  onClose,
}: IProps) => {
  const { dispatch, state } = useContext(AppContext);
  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleConfirmPass, setVisibleConfirmPass] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [email] = useState(state.user.data?.email || "");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const { mutate, isLoading } = useMutation(ChangePasswordQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: "success",
          text: "Password successfully changed",
        },
      });
      onClose();
    },
    onError: ({ response }) => {
      const message =
        response.status === 400 ? response.data.error : "An error occurred";
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          autoHide: 6000,
          severity: "error",
          text: message,
        },
      });
    },
  });

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({
        ...formErrors,
        newPassword: ErrorTexts.newPasswordNotValid,
        passwordsMismatch: null,
      });
    } else {
      if (confirmNewPassword) {
        if (value !== confirmNewPassword) {
          setFormErrors({
            ...formErrors,
            newPassword: null,
            passwordsMismatch: ErrorTexts.passwordsMismatch,
          });
        } else {
          setFormErrors({
            ...formErrors,
            newPassword: null,
            passwordsMismatch: null,
          });
        }
      } else {
        setFormErrors({
          ...formErrors,
          newPassword: null,
          passwordsMismatch: null,
        });
      }
    }
    setNewPassword(value);
  };

  const handleCurrentPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({
        ...formErrors,
        currentPassword: ErrorTexts.currentPasswordNotValid,
        passwordsMismatch: null,
      });
    }
    setCurrentPassword(value);
  };

  const handleConfirmNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({
        ...formErrors,
        confirmNewPassword: ErrorTexts.confirmNewPasswordNotValid,
        passwordsMismatch: null,
      });
    } else {
      if (newPassword) {
        if (value !== newPassword) {
          setFormErrors({
            ...formErrors,
            confirmNewPassword: null,
            passwordsMismatch: ErrorTexts.passwordsMismatch,
          });
        } else {
          setFormErrors({
            ...formErrors,
            confirmNewPassword: null,
            passwordsMismatch: null,
          });
        }
      } else {
        setFormErrors({
          ...formErrors,
          confirmNewPassword: null,
          passwordsMismatch: null,
        });
      }
    }
    setConfirmNewPassword(value);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleChangePassword = () => {
    let hasErrors = false;
    const errors: IErrors = {
      currentPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      passwordsMismatch: null,
    };

    if (!isPasswordValid(newPassword)) {
      hasErrors = true;
      errors.newPassword = formErrors.newPassword;
    }
    if (!isPasswordValid(confirmNewPassword)) {
      hasErrors = true;
      errors.confirmNewPassword = formErrors.confirmNewPassword;
    }
    if (!hasErrors && newPassword !== confirmNewPassword) {
      hasErrors = true;
      errors.passwordsMismatch = formErrors.passwordsMismatch;
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      mutate({
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword,
      });
    }
  };

  const isFormValid = () => {
    if (
      isPasswordValid(newPassword) &&
      isPasswordValid(confirmNewPassword) &&
      newPassword === confirmNewPassword
    ) {
      return true;
    }
    return false;
  };

  return (
    <DialogBox width={600} onClose={onClose}>
      <Box sx={{ display: "flex", flexFlow: "column", gap: "24px" }}>
        <Box>
          <Box sx={{ display: "flex", flexFlow: "row", alignItems: "start" }}>
            <Box
              sx={{
                fontWeight: 600,
                fontSize: "24px",
                lineHeight: "34px",
                color: colors.primary.main,
              }}
            >
              Reset password
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "8px",
              marginBottom: "12px",
              fontSize: "16px",
              lineHeight: "22px",
              color: colors.grey.subheader,
            }}
          >
            Enter your new password that should be different from previous used.
          </Box>
        </Box>
        <InputComponent
          id="change-password-current-password"
          label="Current Password"
          type={visiblePass ? "text" : "password"}
          placeholder="Enter current password"
          value={currentPassword}
          onChange={handleCurrentPasswordChange}
          disabled={isLoading}
          errorMsg={formErrors.currentPassword}
          containerStyles={{ mb: "40px" }}
          startIcon={
            <Lock
              sx={{
                fill: getIconColor(currentPassword, formErrors.currentPassword),
                zIndex: 2,
              }}
            />
          }
          endIcon={
            <IconButton
              aria-label="toggle new password visibility"
              onClick={() => setVisiblePass(!visiblePass)}
              sx={{ marginRight: "-8px" }}
            >
              {visiblePass ? (
                <VisibilityOff
                  sx={{
                    fill: getIconColor(
                      newPassword,
                      formErrors.newPassword || formErrors.passwordsMismatch
                    ),
                    zIndex: 2,
                  }}
                />
              ) : (
                <Visibility
                  sx={{
                    fill: getIconColor(
                      newPassword,
                      formErrors.newPassword || formErrors.passwordsMismatch
                    ),
                    zIndex: 2,
                  }}
                />
              )}
            </IconButton>
          }
        />
        <InputComponent
          id="change-password-new-password"
          label="New Password"
          type={visiblePass ? "text" : "password"}
          placeholder="Enter new password"
          value={newPassword}
          onChange={handleNewPasswordChange}
          disabled={isLoading}
          errorMsg={formErrors.newPassword || formErrors.passwordsMismatch}
          startIcon={
            <Lock
              sx={{
                fill: getIconColor(
                  newPassword,
                  formErrors.newPassword || formErrors.passwordsMismatch
                ),
                zIndex: 2,
              }}
            />
          }
          endIcon={
            <IconButton
              aria-label="toggle new password visibility"
              onClick={() => setVisiblePass(!visiblePass)}
              sx={{ marginRight: "-8px" }}
            >
              {visiblePass ? (
                <VisibilityOff
                  sx={{
                    fill: getIconColor(
                      newPassword,
                      formErrors.newPassword || formErrors.passwordsMismatch
                    ),
                    zIndex: 2,
                  }}
                />
              ) : (
                <Visibility
                  sx={{
                    fill: getIconColor(
                      newPassword,
                      formErrors.newPassword || formErrors.passwordsMismatch
                    ),
                    zIndex: 2,
                  }}
                />
              )}
            </IconButton>
          }
        />
        <InputComponent
          id="change-password-confirm-password"
          label="Confirm Password"
          type={visibleConfirmPass ? "text" : "password"}
          placeholder="Enter new password"
          value={confirmNewPassword}
          onChange={handleConfirmNewPasswordChange}
          disabled={isLoading}
          errorMsg={
            formErrors.confirmNewPassword ?? formErrors.passwordsMismatch
          }
          startIcon={
            <Lock
              sx={{
                fill: getIconColor(
                  confirmNewPassword,
                  formErrors.confirmNewPassword || formErrors.passwordsMismatch
                ),
                zIndex: 2,
              }}
            />
          }
          endIcon={
            <IconButton
              aria-label="toggle new password visibility"
              onClick={() => setVisibleConfirmPass(!visibleConfirmPass)}
              sx={{ marginRight: "-8px" }}
            >
              {visibleConfirmPass ? (
                <VisibilityOff
                  sx={{
                    fill: getIconColor(
                      confirmNewPassword,
                      formErrors.confirmNewPassword ||
                        formErrors.passwordsMismatch
                    ),
                    zIndex: 2,
                  }}
                />
              ) : (
                <Visibility
                  sx={{
                    fill: getIconColor(
                      newPassword,
                      formErrors.confirmNewPassword ||
                        formErrors.confirmNewPassword
                    ),
                    zIndex: 2,
                  }}
                />
              )}
            </IconButton>
          }
        />
        <Box sx={{ display: "flex", gap: "16px" }}>
          <CancelButton
            id="change-password-cancel"
            text="Cancel"
            onClick={handleCancel}
          />
          <MainButton
            id="change-password-send"
            text="Change Password"
            onClick={handleChangePassword}
            disabled={!isFormValid()}
          />
        </Box>
      </Box>
    </DialogBox>
  );
};

export default ChangePassword;

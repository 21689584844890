import React, { useContext, useState } from 'react';
import { EditOutlined, FolderOpen, QueryBuilder, West } from '@mui/icons-material';
import { Box, Divider, FormControlLabel, IconButton, SelectChangeEvent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { AuthRoutes } from '../../../../enums/RouteEnums';
import { colors } from '../../../../utils/theme';
import {
  displayText,
  getActiveSegments,
  getBrandId,
  getJourneyStageParent,
  handleRequestError
} from '../../../../utils/ui';
import CancelButton from '../../../Common/Buttons/CancelButton';
import MainButton from '../../../Common/Buttons/MainButton';
import moment from 'moment';
import './index.css';
import JourneySteps from '../JourneySteps/JourneySteps';
import { AppContext } from '../../../../AppContext';
import { Actions } from '../../../../enums/ActionEnums';
import JourneyForm from '../JourneyForm';
import { PinkSwitch } from '../../Segments/SegmentForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../../../../enums/HttpRequestKeyEnums';
import { SegmentGroupsQuery } from '../../../../queries/segment';
import { Segment, SegmentGroup } from '../../../../models/segmets';
import SelectComponent from '../../../Common/Fields/SelectComponent';
import InfoIcon from '@mui/icons-material/Info';
import {
  JourneyDetailsModel,
  JourneyStage,
  JourneyStepDbStored
} from '../../../../models/journeys';
import { JourneyStepType } from '../../../../enums/EntityEnums';
import StepEditor from '../JourneySteps/StepEditor';
import { SetJourneyDetailsMutation } from '../../../../queries/journey';
import { cutJourneyStepsTree, restoreJourneyStepsTree } from '../../../../utils/math';
import Loader from '../../../Common/Global/Loader';

const JourneyDetails: React.FunctionComponent = () => {
  const getSegmentNames = (ids: number[], input?: Segment[]) => {
    const opts = input ?? segments;
    return opts.filter((s) => ids.includes(s.id)).map((s) => s.name);
  };

  const { state, dispatch } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const segmentsQuery = useQuery({
    queryKey: [QueryKey.SegmentGroups, brandId],
    queryFn: () => SegmentGroupsQuery(brandId),
    onSuccess: ({ data }) => {
      const segments = [] as Segment[];
      (data as SegmentGroup[]).map((sg) => segments.push(...sg.segments));
      setSegments(getActiveSegments(data as SegmentGroup[]));

      setExitSegments(getSegmentNames(state.selectedJourney?.exitSegmentIds ?? [], segments));
      setExcludedSegments(getSegmentNames(state.selectedJourney?.targetExcludedSegmentIds ?? []));
      setIncludedSegments(getSegmentNames(state.selectedJourney?.targetIncludedSegmentIds ?? []));
    }
  });

  const navigate = useNavigate();
  const [segments, setSegments] = useState<Segment[]>(
    getActiveSegments(segmentsQuery.data?.data || [])
  );
  const [hasExit, setHasExit] = useState(state.selectedJourney?.hasExit ?? false);
  const [exitSegments, setExitSegments] = useState<string[]>(
    getSegmentNames(state.selectedJourney?.exitSegmentIds ?? [])
  );
  const [includedSegments, setIncludedSegments] = useState<string[]>(
    getSegmentNames(state.selectedJourney?.targetIncludedSegmentIds ?? [])
  );
  const [excludedSegments, setExcludedSegments] = useState<string[]>(
    getSegmentNames(state.selectedJourney?.targetExcludedSegmentIds ?? [])
  );

  const [steps, setSteps] = useState<JourneyStage | null>(
    state.selectedJourney?.stages && state.selectedJourney?.stages.length > 0
      ? restoreJourneyStepsTree(state.selectedJourney?.stages as JourneyStepDbStored[])
      : {
          id: 0,
          name: 'Root',
          children: [
            {
              id: 1,
              name: 'Target Audience',
              children: [],
              type: JourneyStepType.TargetAudience,
              dbId: 0,
              channel: null,
              smsText: null,
              emailSender: null,
              template: null,
              timezone: null,
              dateHours: [],
              waitTime: null,
              waitType: null
            }
          ],
          type: JourneyStepType.TargetAudience,
          dbId: 0,
          channel: null,
          smsText: null,
          emailSender: null,
          template: null,
          timezone: null,
          dateHours: [],
          waitTime: null,
          waitType: null
        }
  );
  const [openForm, setOpenForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const queryClient = useQueryClient();
  const updateJourney = useMutation(SetJourneyDetailsMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      setIsEditing(false);
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${state.selectedJourney?.name} journey updated`
        }
      });
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.JourneyGroups, brandId])
  });

  const handleExitSegmentsChange = (event: SelectChangeEvent<typeof exitSegments>) => {
    setIsEditing(true);
    const {
      target: { value }
    } = event;
    const names = typeof value === 'string' ? value.split(',') : value;
    setExitSegments(names);
  };

  const handleSegmentsChange = (
    event: SelectChangeEvent<typeof exitSegments>,
    type: 'included' | 'excluded'
  ) => {
    setIsEditing(true);
    const {
      target: { value }
    } = event;
    const names = typeof value === 'string' ? value.split(',') : value;
    if (type === 'included') {
      setIncludedSegments(names);
    } else {
      setExcludedSegments(names);
    }
  };

  const handleBack = () => {
    navigate(`${AuthRoutes.CustomerJourney}/${state.selectedJourney?.id}`, {
      state: {
        journeyGroup: state.selectedJourney?.group,
        path: AuthRoutes.CustomerJourney
      }
    });
    dispatch({
      type: Actions.SetSelectedJourneyStage,
      payload: { stage: null }
    });
    dispatch({
      type: Actions.ResetJourney
    });
  };

  const toggleHasExit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsEditing(true);
    setHasExit(checked);
    if (!checked) {
      setExitSegments([]);
    }
  };

  const addChildToStep = (parentId: number, child: JourneyStage) => {
    if (!steps) return;
    const updatedSteps = { ...steps };
    const findParentAndAddChild = (stage: JourneyStage) => {
      if (stage.id === parentId) {
        if (!stage.children) {
          stage.children = [child];
        } else {
          stage.children.push(child);
        }
        return;
      } else if (stage.children && stage.children.length > 0) {
        stage.children.forEach((child) => {
          findParentAndAddChild(child);
        });
      }
    };

    findParentAndAddChild(updatedSteps);
    setSteps(updatedSteps);
  };

  const handleAddStep = () => {
    setIsEditing(true);
    const parentId = findLargestParentId();
    const newStep = {
      id: parentId + 1,
      name: 'wait',
      children: [],
      type: JourneyStepType.Wait,
      dbId: 0,
      channel: null,
      smsText: null,
      emailSender: null,
      template: null,
      timezone: null,
      dateHours: [],
      waitTime: null,
      waitType: null
    } as JourneyStage;
    addChildToStep(parentId, newStep);
  };

  const findLargestParentId = () => {
    let largestParentId = steps?.id ?? 0;

    const traverseSteps = (steps: JourneyStage | null) => {
      if (steps) {
        steps.children?.forEach((step) => {
          if (step.id > largestParentId) {
            largestParentId = step.id;
          }

          if (step.children && step.children.length > 0) {
            traverseSteps(step);
          }
        });
      }
    };

    traverseSteps(steps);

    return largestParentId;
  };

  const handleStepSave = (updatedStage: JourneyStage) => {
    if (!steps) return;
    const updatedSteps = { ...steps };
    const findStage = (stage: JourneyStage) => {
      if (stage.id === updatedStage.id) {
        stage.name = updatedStage.name;
        stage.children = updatedStage.children;
        stage.type = updatedStage.type;
        stage.channel = updatedStage.channel;
        stage.emailSender = updatedStage.emailSender;
        stage.template = updatedStage.template;
        stage.smsText = updatedStage.smsText;
        stage.timezone = updatedStage.timezone;
        stage.dateHours = updatedStage.dateHours;
        stage.waitTime = updatedStage.waitTime;
        stage.waitType = updatedStage.waitType;
        return;
      } else if (stage.children && stage.children.length > 0) {
        stage.children.forEach((child) => {
          findStage(child);
        });
      }
    };

    const reorder = (stage: JourneyStage) => {
      //@ts-ignore
      if (stage.id === updatedStage.parent.id) {
        const containts = stage.children.findIndex((c) => c.id === updatedStage.id) > -1;
        if (!containts) {
          stage.children = [...stage.children, updatedStage];
        }
      } else {
        const containts = stage.children.findIndex((c) => c.id === updatedStage.id) > -1;
        if (containts) {
          stage.children = stage.children.filter((c) => c.id !== updatedStage.id);
        }
      }
      stage.children.forEach((child) => reorder(child));
    };

    findStage(updatedSteps);
    reorder(updatedSteps);
    setSteps(updatedSteps);
    dispatch({
      type: Actions.SetSelectedJourneyStage,
      payload: {
        stage: null
      }
    });
  };

  const handleStepDelete = () => {
    if (!steps || state.selectedJourneyStage === null) return;
    const stageToDelete = state.selectedJourneyStage;
    dispatch({
      type: Actions.SetSelectedJourneyStage,
      payload: {
        stage: null
      }
    });
    const updatedSteps = { ...steps };
    const stageChildren = [...stageToDelete.children];
    const parent = getJourneyStageParent(stageToDelete, steps) as JourneyStage;
    const remove = (stage: JourneyStage) => {
      if (stage.id === parent.id) {
        stage.children = [
          ...stage.children.filter((c) => c.id !== stageToDelete.id),
          ...stageChildren
        ];
        return;
      }
      stage.children.forEach((child) => remove(child));
    };

    remove(updatedSteps);
    setSteps(updatedSteps);
  };

  const handleSave = () => {
    const input = {
      hasExit,
      exitSegmentIds: segments.filter((s) => exitSegments.includes(s.name)).map((s) => s.id),
      targetIncludedSegmentIds: segments
        .filter((s) => includedSegments.includes(s.name))
        .map((s) => s.id),
      targetExcludedSegmentIds: segments
        .filter((s) => excludedSegments.includes(s.name))
        .map((s) => s.id),
      stages: cutJourneyStepsTree(steps)
    } as JourneyDetailsModel;
    updateJourney.mutate({ id: state.selectedJourney?.id as number, input });
  };

  const handleCancelEditing = () => {
    setIsEditing(false);
    setSteps(
      state.selectedJourney?.stages && state.selectedJourney?.stages.length > 0
        ? restoreJourneyStepsTree(state.selectedJourney?.stages as JourneyStepDbStored[])
        : {
            id: 0,
            name: 'Root',
            children: [
              {
                id: 1,
                name: 'Target Audience',
                children: [],
                type: JourneyStepType.TargetAudience,
                channel: null,
                dbId: 0,
                smsText: null,
                emailSender: null,
                template: null,
                timezone: null,
                dateHours: [],
                waitTime: null,
                waitType: null
              }
            ],
            type: JourneyStepType.TargetAudience,
            dbId: 0,
            channel: null,
            smsText: null,
            emailSender: null,
            template: null,
            timezone: null,
            dateHours: [],
            waitTime: null,
            waitType: null
          }
    );
    setHasExit(state.selectedJourney?.hasExit ?? false);
    setExitSegments(getSegmentNames(state.selectedJourney?.exitSegmentIds ?? []));
    setExcludedSegments(getSegmentNames(state.selectedJourney?.targetExcludedSegmentIds ?? []));
    setIncludedSegments(getSegmentNames(state.selectedJourney?.targetIncludedSegmentIds ?? []));
    dispatch({
      type: Actions.SetSelectedJourneyStage,
      payload: { stage: null }
    });
  };

  return (
    <Box className="nav-page">
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box
          sx={{
            fontWeight: 600,
            fontSize: '24px',
            color: (t) => t.palette.primary.main
          }}
        >
          <IconButton onClick={handleBack}>
            <West sx={{ color: (t) => t.palette.primary.main }} />
          </IconButton>
          {state.selectedJourney?.group.name} Journeys
        </Box>
        {isEditing && (
          <Box className="form-actions">
            <Box className="form-group-buttons">
              <CancelButton
                id="journey-cancel"
                text="Cancel"
                onClick={handleCancelEditing}
                sx={{ height: '36px', width: '48px' }}
              />
              <MainButton id="journey-create" text="Save" onClick={handleSave} />
            </Box>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
          mt: '24px',
          alignItems: 'flex-start'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            borderRadius: '24px',
            background: 'white',
            p: '24px',
            gap: '10px'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box className="journey-details-title">{state.selectedJourney?.name}</Box>
            <IconButton onClick={() => setOpenForm(true)}>
              <EditOutlined sx={{ color: colors.gradients.pinkRgb }} />
            </IconButton>
          </Box>
          <Box className="journey-details-text">
            <FolderOpen />
            {displayText(state.selectedJourney?.group.name ?? '')}
          </Box>
          <Box className="journey-details-text">
            <QueryBuilder />
            {displayText(moment(state.selectedJourney?.startDate).format('YYYY/MM/DD'))} -{' '}
            {displayText(
              state.selectedJourney?.endDate
                ? moment(state.selectedJourney?.endDate).format('YYYY/MM/DD')
                : 'NA'
            )}
          </Box>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box className="journey-details-title" sx={{ pt: '8px' }}>
              Exit condition
            </Box>
            <FormControlLabel
              control={
                <PinkSwitch
                  checked={hasExit}
                  onChange={toggleHasExit}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={hasExit ? 'Yes' : 'No'}
              labelPlacement="bottom"
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                color: (t) => t.palette.primary.main
              }}
            />
          </Box>
          <Box className="helper-text-box" sx={{ mt: '-20px' }}>
            <InfoIcon sx={{ width: '16px', height: '16px', color: '#C9C8D0' }} /> Players will leave
            the journey when they match one of the segments:
          </Box>
          <SelectComponent
            label="Segmnets"
            value={exitSegments}
            isSingle={false}
            onChange={handleExitSegmentsChange}
            options={segments.map((s) => s.name)}
            hideNoneValue
            disabled={!hasExit}
          />

          {state.selectedJourneyStage && (
            <>
              <Divider sx={{ pt: '8px' }} />
              <StepEditor
                allSteps={steps as JourneyStage}
                handleStepSave={handleStepSave}
                handleDelete={handleStepDelete}
                setIsEditing={() => setIsEditing(true)}
                segments={segments}
                includedSegments={includedSegments}
                excludedSegments={excludedSegments}
                setSegments={handleSegmentsChange}
              />
            </>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 2,
            borderRadius: '24px',
            background: 'white',
            height: '75vh',
            p: '24px',
            overflow: 'auto',
            scrollBehavior: 'smooth',
            scrollbarWidth: 'thin'
          }}
        >
          <MainButton
            id="add-journey-step"
            text="+ Add Step"
            sx={{ width: '123px' }}
            onClick={handleAddStep}
          />
          {steps && <JourneySteps steps={steps} />}
        </Box>
      </Box>
      {openForm && (
        <JourneyForm
          journey={state.selectedJourney}
          onClose={() => setOpenForm(false)}
          groups={state.journeyGroups}
        />
      )}
      <Loader loading={updateJourney.isLoading} />
    </Box>
  );
};

export default JourneyDetails;

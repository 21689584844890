import { Box, Modal, SxProps } from '@mui/material';
import React from 'react';
import { RotateLoader } from 'react-spinners';
import { colors } from '../../../utils/theme';

interface IProps {
  loading: boolean;
  additionalStyle?: SxProps;
}
const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  '&:focus': {
    outline: 'none'
  }
};

const Loader: React.FunctionComponent<IProps> = ({ loading, additionalStyle }: IProps) => {
  return (
    <Modal open={loading}>
      <Box sx={{ ...style, ...additionalStyle }}>
        <div id="load">
          <div>G</div>
          <div>N</div>
          <div>I</div>
          <div>D</div>
          <div>A</div>
          <div>O</div>
          <div>L</div>
        </div>
      </Box>
    </Modal>
  );
};

export default Loader;

import React, { useState, useEffect } from 'react';
import { Box, Divider, FormControlLabel } from '@mui/material';
import DayTimePicker from '../../../Common/Tables/DayTimePicker/DayTimePicker';
import { PinkSwitch } from '../../Segments/SegmentForm';
import DateComponent from '../../../Common/Fields/DateComponent';
import CustomTimeComponent from '../../../Common/Fields/CustomTime/CustomTimeComponent';
import AutocompleteComponent from '../../../Common/Fields/AutocompleteComponent';
import moment from 'moment-timezone';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { DateTimeItem, TimeZone } from '../../../../models/global';

dayjs.extend(utc);

interface IProps {
  startDate: Date | null;
  setStartDate: (input: Date | null) => void;
  endDate: Date | null;
  setEndDate: (input: Date | null) => void;
  hasTimePolicy: boolean;
  setHasTimePolicy: (input: boolean) => void;
  timeZone: TimeZone | null;
  setTimeZone: (input: TimeZone | null) => void;
  dateHours: DateTimeItem[];
  setDateHours: (input: DateTimeItem[]) => void;
  hasFrequency: boolean;
  setHasFrequency: (input: boolean) => void;
  frequencyTime: number | null;
  setFrequencyTime: (input: number | null) => void;
  frequencyType: string | null;
  setFrequencyType: (input: string | null) => void;
  frequencyPerUser: number | null;
  setFrequencyPerUser: (input: number | null) => void;
  overrideBlockedSegments: boolean;
  setOverrideBlockedSegments: (input: boolean) => void;
}

const TimeFrameStep: React.FunctionComponent<IProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  hasTimePolicy,
  setHasTimePolicy,
  timeZone,
  setTimeZone,
  dateHours,
  setDateHours,
  hasFrequency,
  setHasFrequency,
  frequencyTime,
  setFrequencyTime,
  frequencyType,
  setFrequencyType,
  frequencyPerUser,
  setFrequencyPerUser,
  overrideBlockedSegments,
  setOverrideBlockedSegments
}: IProps) => {
  const [startTime, setStartTime] = useState<Date | null>(startDate);
  const [endTime, setEndTime] = useState<Date | null>(endDate);
  const [timeZones, setTimeZones] = useState<TimeZone[]>([]);

  const today = dayjs().set('minute', 0).toDate();

  useEffect(() => {
    // Fetch all time zones from moment-timezone
    const allTimeZones = moment.tz.names();

    // Create an array of objects with timezone and offset
    const timeZonesWithOffset = (allTimeZones as string[]).map(
      (tz) =>
        ({
          timeZone: tz,
          offset: moment.tz(tz).format('Z')
        } as TimeZone)
    );
    setTimeZones(timeZonesWithOffset);
  }, []);

  useEffect(() => {
    setStartTime(startDate);
    setEndTime(endDate);
  }, [startDate, endDate]);

  const handleFrquencyChange = (checked: boolean) => {
    if (!checked) {
      setFrequencyTime(null);
      setFrequencyType(null);
      setFrequencyPerUser(null);
    }
    setHasFrequency(checked);
  };

  const handleStartTimeChange = (input: Date) => {
    const date = dayjs(startDate ?? input)
      .set('hour', input.getHours())
      .set('minute', input.getMinutes())
      .toDate();
    setStartDate(date);
  };

  const handleEndTimeChange = (input: Date) => {
    const date = dayjs(endDate ?? input)
      .set('hour', input.getHours())
      .set('minute', input.getMinutes())
      .toDate();
    setEndDate(date);
  };

  const handleSetEndDate = (input: Date | null) => {
    let date = input;
    if (input) {
      date = dayjs(input).set('hour', 23).set('minute', 50).toDate();
    }
    setEndDate(date);
  };

  const handleTimePolicyChange = (checked: boolean) => {
    setHasTimePolicy(checked);
    if (!checked) {
      setDateHours([]);
    }
  };
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(12, minmax(0, 1fr))',
        gap: '24px',
        pl: '24px'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          gridColumn: 'span  8'
        }}
      >
        <Box sx={{ display: 'flex', gap: '24px' }}>
          <DateComponent
            id="start-date"
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
            minDate={today}
            {...(endDate && { maxDate: endDate })}
          />
          <CustomTimeComponent id="start-time" value={startTime} onChange={handleStartTimeChange} />
          <DateComponent
            id="end-date"
            label="End Date"
            value={endDate}
            onChange={handleSetEndDate}
            minDate={startDate ?? today}
          />
          <CustomTimeComponent id="end-time" value={endTime} onChange={handleEndTimeChange} />
        </Box>
        <Divider flexItem />
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
            gap: '24px'
          }}
        >
          <AutocompleteComponent
            id="time-zones-autocomplete"
            label="Timezone"
            value={timeZone}
            options={timeZones}
            getOptionLabel={(option) => `${option.timeZone} (GMT${option.offset})`}
            onChange={(_: any, newValue: any) => {
              setTimeZone(newValue);
            }}
          />
          <Box sx={{ pt: '15px' }}>
            <FormControlLabel
              control={
                <PinkSwitch
                  checked={hasFrequency}
                  onChange={(event) => {
                    handleFrquencyChange(event.target.checked);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Frequency"
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                color: (t) => t.palette.primary.main
              }}
            />
            {hasFrequency && (
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
                  gap: '24px'
                }}
              >
                <AutocompleteComponent
                  id="time-frequency"
                  label="Min interval"
                  value={frequencyTime}
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  getOptionLabel={(opt) => opt.toString()}
                  onChange={(_, newValue) => setFrequencyTime(newValue)}
                  containerStyles={{ width: '100%' }}
                />
                <AutocompleteComponent
                  id="frequency-type"
                  label="Type"
                  value={frequencyType}
                  options={['Week/s', 'Day/s']}
                  onChange={(_, newValue) => setFrequencyType(newValue)}
                />
                <AutocompleteComponent
                  id="frequency-per-user"
                  label="Max sendings per user"
                  value={frequencyPerUser}
                  options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                  getOptionLabel={(opt) => opt.toString()}
                  onChange={(_, newValue) => setFrequencyPerUser(newValue)}
                  containerStyles={{ gridColumn: 'span 2' }}
                />
              </Box>
            )}
          </Box>
        </Box>
        <Divider flexItem />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          p: '24px',
          gridColumn: 'span 4',
          borderLeft: '1px solid #DFDEE7'
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <FormControlLabel
            control={
              <PinkSwitch
                checked={hasTimePolicy}
                onChange={(event) => {
                  handleTimePolicyChange(event.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Time Policy"
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              color: (t) => t.palette.primary.main
            }}
          />
          <FormControlLabel
            control={
              <PinkSwitch
                checked={overrideBlockedSegments}
                onChange={(event) => {
                  setOverrideBlockedSegments(event.target.checked);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label="Override blocked segments"
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              color: (t) => t.palette.primary.main
            }}
          />
        </Box>
        {hasTimePolicy && <DayTimePicker dateHours={dateHours} setDateHours={setDateHours} />}
      </Box>
    </Box>
  );
};

export default TimeFrameStep;

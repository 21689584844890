import React from 'react';
import { Box } from '@mui/material';
import Cell from './Cell';
import { DateTimeItem } from '../../../../models/global';

interface IProps {
  dateHours: DateTimeItem[];
  setDateHours: (input: DateTimeItem[]) => void;
}

const DayTimePicker: React.FunctionComponent<IProps> = ({ dateHours, setDateHours }: IProps) => {
  const hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
  ];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const handleCellClicked = (day: string, hour: number) => {
    const item = {
      day,
      hour
    } as DateTimeItem;
    const itemIndex = dateHours.findIndex((dh) => dh.day === day && dh.hour === hour);
    if (itemIndex > -1) {
      const draft = Array.from(dateHours);
      draft.splice(itemIndex, 1);
      console.log(dateHours);
      setDateHours(draft);
    } else {
      setDateHours([...dateHours, item]);
    }
  };

  const cellIsSelected = (day: string, hour: number): boolean => {
    const itemIndex = dateHours.findIndex((dh) => dh.day === day && dh.hour === hour);
    return itemIndex > -1;
  };

  const getHeader = () => {
    return (
      <>
        <Cell key="dummy-cell" noBorder clickable={false} />
        {days.map((d) => (
          <Cell key={`header-${d}`} value={d} clickable={false} />
        ))}
      </>
    );
  };

  const getRow = (hourValue: number) => {
    const hour = hourValue > 9 ? hourValue.toString() : `0${hourValue}`;
    const cellContent = hour + ':00';
    return (
      <>
        <Cell key="cell-0-0" value={cellContent} clickable={false} />
        {days.map((d) => (
          <Cell
            key={`cell-${d}-${hourValue}`}
            day={d}
            hour={hourValue}
            onClick={handleCellClicked}
            selected={cellIsSelected(d, hourValue)}
          />
        ))}
      </>
    );
  };
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(8, minmax(0, 1fr))',
        gap: '4px',
        boxShadow: '0px 10px 15px 5px rgba(125, 118, 226, 0.08)',
        p: '4px',
        borderRadius: '10px'
      }}
    >
      {getHeader()}
      {hours.map((h) => getRow(h))}
    </Box>
  );
};

export default DayTimePicker;

import { Edit, Delete } from '@mui/icons-material';
import { Box, IconButton, Link } from '@mui/material';
import React, { useContext, useState } from 'react';
import { IBrand, INewBrandModel } from '../../models/brand';
import CustomTable, { IHeadCell } from '../Common/Tables/CustomTable';
import Header from '../Common/Layout/Header';
import BrandForm from './BrandForm';
import { deleteBrandMutation, getCompanyBrandsQuery } from '../../queries/brand';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import Loader from '../Common/Global/Loader';
import { Restriction } from '../../enums/RouteEnums';
import { canUserCreate, canUserDelete } from '../../utils/rights';
import { handleRequestError } from '../../utils/ui';
import StatusIdicator from '../Common/Layout/StatusIdicator';

interface IProps {}

const BrandsOverview: React.FunctionComponent<IProps> = ({}: IProps) => {
  const queryKey = ['brands'];
  const { isLoading, data } = useQuery(queryKey, getCompanyBrandsQuery, {
    onSuccess: ({ data }) => {
      setBrands(data);
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    }
  });
  const [brands, setBrands] = useState<IBrand[]>(data?.data || []);
  const [openForm, setOpenForm] = useState(false);
  const { dispatch, state } = useContext(AppContext);
  const queryClient = useQueryClient();
  const [brand, setBrand] = useState<IBrand | null>(null);

  const deleteBrand = useMutation(deleteBrandMutation, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey),
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.DeleteBrand,
        payload: {
          brand: data
        }
      });
      dispatch({
        type: Actions.HideConfirmation
      });
    }
  });

  const handleRemove = (rowData: IBrand) => {
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `Are you sure you want to delete ${rowData.name} brand?`,
        agreeAction: handleDelete,
        params: rowData
      }
    });
  };

  const handleDelete = (rowData: IBrand) => {
    if (!rowData.id) {
      return;
    }
    deleteBrand.mutate(rowData.id);
  };

  const handleEdit = (rowData: IBrand) => {
    setBrand(rowData);
    setOpenForm(true);
  };

  const handleClose = () => {
    setBrand(null);
    setOpenForm(false);
  };

  const customActions = ({ rowData }: { rowData: any }) => {
    const iconButtonProps = {
      height: '48px',
      width: '48px',
      marginRight: '16px'
    };
    return canUserDelete(state.user, Restriction.Brand) ? (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <IconButton onClick={() => handleEdit(rowData)} sx={{ ...iconButtonProps, marginRight: 0 }}>
          <Edit sx={{ color: 'error.main' }} />
        </IconButton>
        <IconButton
          onClick={() => {
            handleRemove(rowData);
          }}
          sx={{ ...iconButtonProps, marginRight: 0 }}
        >
          <Delete sx={{ color: 'error.main' }} />
        </IconButton>
      </Box>
    ) : null;
  };

  const brandColumns = [
    {
      title: 'Brand',
      field: 'name',
      render: (data: IBrand) => (
        <Link underline="hover" color="primary.main" target="_blank">
          {data.name}
        </Link>
      )
    },
    {
      title: 'SendGridKey',
      render: (data: INewBrandModel) => (
        <Box>
          <StatusIdicator type={data.sendGridKey ? 'success' : 'fail'} />
          {data.sendGridKey ? 'Yes' : 'No'}
        </Box>
      )
    },
    {
      title: 'TwillioData',
      render: (data: INewBrandModel) => (
        <Box>
          <StatusIdicator
            type={
              data.accountSID && data.authToken && data.messagingServiceSid ? 'success' : 'fail'
            }
          />
          {data.accountSID && data.authToken && data.messagingServiceSid ? 'Yes' : 'No'}
        </Box>
      )
    }
  ] as IHeadCell[];

  return (
    <Box>
      <Box sx={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
        <Box>
          <Header text="Brands" />
          <Header text="Add or edit brand settings" isSubHeader={true} />
        </Box>
      </Box>
      <CustomTable
        toolbarTitle={'Brands'}
        data={brands}
        columns={brandColumns}
        showSearch
        Actions={customActions}
        {...(canUserCreate(state.user, Restriction.Brand) && {
          createButtonProps: {
            id: 'add-brand-button',
            onClick: () => setOpenForm(true),
            text: '+ Create Brand',
            disabled: false
          }
        })}
      />
      {openForm && <BrandForm brandInput={brand} onClose={() => handleClose()} />}
      <Loader loading={isLoading || deleteBrand.isLoading} />
    </Box>
  );
};

export default BrandsOverview;

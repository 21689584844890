import React from 'react';
import { Typography } from '@mui/material';
import jwtDecode from 'jwt-decode';
import { colors } from './theme';
import { getTokenArrayProp } from './types';
import { ActionType } from '../models/appContext';
import { Actions } from '../enums/ActionEnums';
import { Segment, SegmentGroup } from '../models/segmets';
import { JourneyStage } from '../models/journeys';
import * as FileSaver from 'file-saver';

export interface decoded {
  exp: number;
  name: string;
  family_name: string;
  given_name: string;
  email: string;
  role: string[];
  groupsid: string[];
}

export const decodeToken = (token: string) => {
  const decoded: decoded = jwtDecode(token);
  return {
    ...decoded,
    role: getTokenArrayProp(decoded.role),
    groupsid: getTokenArrayProp(decoded.groupsid)
  } as decoded;
};

export const getBorderColor = (value: string | Date | null) => {
  if (typeof value === 'string' || value instanceof String) {
    return value && value.length > 0 ? 'info.main' : 'default';
  } else {
    return value ? 'info.main' : 'default';
  }
};

export const getBorderColorOnHover = (formErrorValue: string | null | undefined) => {
  return formErrorValue ? 'default' : 'info.main';
};

export const getIconColor = (value: string | undefined | null, formErorrValue?: string | null) => {
  return formErorrValue ? colors.error.main : value ? colors.info.main : colors.grey.main;
};

export const displayText = (
  text: string | number | null,
  type?: string | undefined,
  currency?: string
) => {
  if (type && type === 'money') {
    return (
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: '14px'
        }}
      >
        {currency}
        {Number(text).toFixed(2)}
      </Typography>
    );
  }
  return (
    <Typography
      sx={{
        fontWeight: 500,
        fontSize: '14px'
      }}
    >
      {text}
    </Typography>
  );
};

export const getBrandDisplayName = (input: string) => {
  if (input.length === 0) {
    return input;
  } else {
    const elements = input.split(':');
    if (elements.length > 0) {
      return elements[1];
    } else {
      return input;
    }
  }
};

export const getBrandId = (input: string): number => {
  if (input.length === 0) {
    return -1;
  } else {
    const elements = input.split(':');
    if (elements.length > 0) {
      return Number(elements[0]);
    } else {
      return -1;
    }
  }
};

export const handleRequestError = (dispatch: React.Dispatch<ActionType>, response) => {
  const message = response.status === 400 ? response.data.error : 'An error occurred';
  dispatch({
    type: Actions.ShowMessage,
    payload: {
      autoHide: 6000,
      severity: 'error',
      text: message
    }
  });
};

export const NormalizeCamelCaseString = (input: string): string => {
  if (input.length === 0) {
    return input;
  }

  return input.replace(/([A-Z])/g, ' $1').toUpperCase();
};

export const getActiveSegments = (input: SegmentGroup[]) => {
  const segments = [] as Segment[];
  input.map((sg) => segments.push(...sg.segments));
  return segments.filter((s) => s.isActive);
};

export const getJourneyStageParent = (
  stageInput: JourneyStage | null,
  all: JourneyStage
): JourneyStage | null => {
  if (stageInput) {
    let parent = null;
    const isParent = (stage: JourneyStage) => {
      if (stage.children && stage.children.length > 0) {
        const found = stage.children.find((c) => c.id === stageInput?.id);
        if (found) {
          parent = stage;
          return;
        } else {
          stage.children.forEach((child) => isParent(child));
        }
      }
    };

    isParent(all);
    return parent;
  }
  return stageInput;
};

export const formatNumber = (number: number) => {
  const parts = number.toFixed(2).split('.');
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  const formattedNumber = `${integerPart}.${parts[1]}`;

  return formattedNumber;
};

export const getUserCurrencySymbol = (currency: string | undefined) => {
  const input = currency?.toUpperCase() ?? 'USD';
  const data = {
    EUR: '\u20AC',
    USD: '\u0024',
    GBP: '\u00A3',
    RUB: '\u20BD'
  };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return data[input];
};

export const GetCsvData = (data: unknown[], fileName: string) => {
  if (data.length > 0) {
    const titleKeys = Object.keys(data[0]);

    const refinedData = [];
    refinedData.push(titleKeys);

    data.forEach((item) => {
      refinedData.push(Object.values(item));
    });

    let csvContent = '';

    refinedData.forEach((row) => {
      csvContent += row.join(',') + '\n';
    });

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
    FileSaver.saveAs(blob, fileName);
  }
};

import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AppContext } from '../AppContext';
import { Actions } from '../enums/ActionEnums';
import { AppConsts } from '../enums/AppConsts';
import { AuthRoutes, PublicRoutes } from '../enums/RouteEnums';
import { decoded } from '../utils/ui';
import jwtDecode from 'jwt-decode';

interface Props {
  Component: React.FunctionComponent;
  requiredRole?: string | null;
  isPublic: boolean;
}

const AuthRoute = ({ Component, isPublic, requiredRole }: Props) => {
  const { state, dispatch } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  const redirectToSignIn = () => (
    <Navigate
      to={{
        pathname: PublicRoutes.Login
      }}
      replace
      state={{ path: location.pathname }}
    />
  );

  const redirectToHome = () => (
    <Navigate
      to={{
        pathname: AuthRoutes.Home
      }}
      replace
    />
  );

  const navigateToLogin = () => {
    if (!isPublic) {
      navigate(PublicRoutes.Login, { state: { path: location.pathname } });
    }
  };

  const handleInvalidToken = (shouldRemoveToken = false) => {
    if (shouldRemoveToken) {
      localStorage.removeItem(AppConsts.AccessToken);
    }
    dispatch({ type: Actions.RemoveUser });
    navigateToLogin();
  };

  useEffect(() => {
    const token = localStorage.getItem(AppConsts.AccessToken);
    let timer: null | NodeJS.Timeout = null;
    if (!token) {
      handleInvalidToken();
    } else {
      const decoded: decoded = jwtDecode(token);
      const now = new Date().getTime() / 1000;
      if (decoded.exp < now) {
        handleInvalidToken(true);
      } else {
        timer = setTimeout(() => {
          handleInvalidToken(true);
        }, (decoded.exp - now) * 1000);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [location]);

  const isAuthed = isPublic ? true : state.user.token !== null;
  const userRoles: (string | null)[] = state.user.data ? state.user.data.roles ?? [] : [];
  const accessGranted = requiredRole ? userRoles.includes(requiredRole) : true;
  return isAuthed ? accessGranted ? <Component /> : redirectToHome() : redirectToSignIn();
};

export default AuthRoute;

// export enum CriteriaAttribute {
//   FullName = 'Full name',
//   //VipManager = 'Vip manager',
//   //VipStatus = 'Vip status',
//   Country = 'Country',
//   Email = 'Email',
//   FirstDepositDate = 'First Deposit Date',
//   TotalDeposits = 'Total Deposit Amount',
//   TotalWithdrawal = 'Withdrawal Amount',
//   TotalGGR = 'Total GGR',
//   LastDepositDate = 'Last Deposit Date',
//   LastBet = 'Last Bet',
//   //LeadProduct = 'Lead product',
//   LifetimeNGR = 'Lifetime NGR',
//   Balance = 'Real Money Balance'
// }

export enum CriteriaAttribute {
  FullName = 'Full name',
  LastLogin = 'Last Login',
  LastLoginAbs = 'Last Login ABS',
  Registered = 'Registered',
  RegisteredAbs = 'Registered ABS',
  Country = 'Country',
  Email = 'Email',
  FirstDepositDate = 'First Deposit Date',
  FirstDepositDateAbs = 'First Deposit Date ABS',
  LastDepositDate = 'Last Deposit Date',
  LastDepositDateAbs = 'Last Deposit Date ABS',
  TotalDeposits = 'Total Deposit Amount',
  AverageDeposits = 'Average Deposit Amount',
  TotalDepositsCount = 'Total Deposit Count',
  Balance = 'Real Money Balance',
  IsBlocked = 'Is Blocked',
  EmailEnabled = 'Email Enabled',
  AccountStatus = 'Account Status'
}

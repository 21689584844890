import {
  Box,
  InputLabel,
  TextField,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import AddButton from "../Common/Buttons/AddButton";
import CancelButton from "../Common/Buttons/CancelButton";
import React, { useContext, useState } from "react";
import SelectComponent from "../Common/Fields/SelectComponent";
import { colors } from "../../utils/theme";
import { CategoryEnums } from "../../enums/CategoryEnums";
import { AppContext } from "../../AppContext";
import { Actions } from "../../enums/ActionEnums";
import { handleRequestError } from "../../utils/ui";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addCommentMutation } from "../../queries/comment";
import { QueryKey } from "../../enums/HttpRequestKeyEnums";

interface IProps {
  showTitle?: boolean;
  playerId: string;
}

export type IErrors = {
  text: string | null;
};

const defaultErrorsObj: IErrors = {
  text: null,
};

const errorTexts = {
  text: "Text too short!",
};

const CommentForm: React.FunctionComponent<IProps> = ({
  showTitle = true,
  playerId,
}: IProps) => {
  const categoryOptions = Object.values(CategoryEnums);
  const [categories] = useState(categoryOptions || []);
  const [type, setType] = useState("");
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const queryClient = useQueryClient();
  const [text, setText] = useState("");
  const { dispatch } = useContext(AppContext);
  const queryKey = [QueryKey.Comments];

  const addComment = useMutation(addCommentMutation, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: "success",
          text: `Comment added successfully!`,
        },
      });
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries(queryKey),
  });

  const handleCategoryChange = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = e;
    if (value) {
      setType(value as string);
    }
  };

  const handleTextChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    setFormErrors({
      ...formErrors,
      text: value && value.length > 0 ? null : errorTexts.text,
    });
    setText(value);
  };

  const handleSave = () => {
    const input = { text, type, playerId };
    addComment.mutate(input);
    setText("");
    setType("");
  };

  const handleSubmit = () => {
    const errors = {
      text: null,
    } as IErrors;
    let hasErrors = false;
    if (text.length === 0) {
      hasErrors = true;
      errors.text = errorTexts.text;
    }
    if (type.length == 0) {
      hasErrors = true;
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      handleSave();
    }
  };

  const handleClose = () => {
    setText("");
    setType("");
    setFormErrors(defaultErrorsObj);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        padding: "24px",
      }}
    >
      {showTitle && (
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 600,
            marginBottom: "11px",
            lineHeight: "22px",
            textAlign: "left",
            textTransform: "uppercase",
          }}
          color="primary.main"
          gutterBottom
        >
          {"Add Comments"}
        </Typography>
      )}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "40px" }}>
        <Box>
          <InputLabel sx={{ textAlign: "left", mb: "4px" }}>
            New Comment
          </InputLabel>
          <TextField
            multiline
            value={text}
            onChange={handleTextChange}
            rows={2}
            error={formErrors.text != null}
            helperText={formErrors.text ? errorTexts.text : ""}
            fullWidth
            variant="outlined"
            placeholder="Add a Comment"
            inputProps={{
              sx: {
                "&::placeholder": {
                  color: colors.grey.placeholder,
                  opacity: 1,
                },
              },
            }}
          ></TextField>
        </Box>
        <SelectComponent
          label="Comment Category"
          placeholder="Select"
          value={type}
          isSingle={true}
          onChange={handleCategoryChange}
          options={categories.filter((c) => c !== "All")}
          containerStyles={{}}
          hideNoneValue
        />
        <Box className="form-actions">
          <Box className="form-group-buttons">
            <CancelButton
              id="comment-form-cancel"
              text="Cancel"
              onClick={handleClose}
              sx={{ height: "40px", width: "96px" }}
            />
            <AddButton
              id="add-comment-save"
              text="Save"
              sx={{ height: "40px", width: "82px" }}
              onClick={handleSubmit}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommentForm;

import { createTheme } from '@mui/material/styles';
import CSS from 'csstype';

const fontFamily = 'Inter';
export const colors = {
  primary: {
    main: '#28245F'
  },
  info: {
    main: '#D3137D'
  },
  grey: {
    placeholder: '#B5BAC3',
    main: '#E5E5E5',
    secondary: 'rgba(243, 242, 250, 0.6)',
    thread: '#F9F9FF',
    background: '#E6E8EB',
    50: '#838C9A',
    subheader: '#ACAABA'
  },
  white: '#FFFFFF',
  error: {
    main: '#d32f2f',
    second: '#EA0606'
  },
  green: {
    main: '#11CBCB',
    second: '#82D616',
    hover: '#0FB7B7'
  },
  warning: {
    main: '#F09A05'
  },
  bohShadows: {
    grey: '4px 4px 10px rgba(40, 36, 95, 0.12)'
  },
  backdropFilter: 'blur(20px)',
  gradients: {
    ping: 'linear-gradient(90deg, #EC1A58 0.02%, #E61861 33.34%, #D5137B 94.27%, #D3137D 100%)',
    pinkRgb: 'rgb(236,26,88)'
  },
  pink: {
    main: '#EC1A58',
    secondary: '#fcbde0',
    background: '#FBE7F2'
  }
};

export const shapes = {
  borderRadius: 24
};

const labelStyle: CSS.Properties = {
  color: colors.primary.main,
  textTransform: 'uppercase',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '14px',
  letterSpacing: '0.05em'
};

export const buildTheme = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: colors.primary.main
      },
      info: {
        main: colors.info.main
      },
      grey: {
        50: colors.grey[50],
        300: colors.grey.placeholder
      },
      common: {
        white: colors.white
      },
      error: {
        main: colors.error.main,
        contrastText: colors.error.second
      }
    },
    shape: {
      borderRadius: shapes.borderRadius
    },
    typography: {
      allVariants: {
        fontFamily
      },
      button: {
        textTransform: 'none',
        fontWeight: 600,
        fontSize: '14px',
        fontFamily,
        lineHeight: '19.6px'
      },
      h5: {
        fontFamily,
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: '24px',
        lineHeight: '33.6px'
      }
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            p: '6px 12px',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            color: 'primary.main',
            '&:hover': {
              fontWeight: 500
            }
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            textDecoration: 'none',
            cursor: 'pointer',
            fontFamily,
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: '14px',
            lineHeight: '20px'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            // borderRadius: `${shapes.borderRadius}px`,
            // border: `1px solid ${colors.primary.main}`,
            height: '36px',
            fontSize: '14px',
            letterSpacing: 0,
            padding: '10px 23px',
            '&.Mui-disabled': {
              color: colors.white,
              backgroundColor: colors.grey.background,
              background: colors.grey.background,
              border: 'none'
            },
            '&:hover': {
              color: `${colors.gradients.ping} !important`
            }
          },
          contained: {
            borderRadius: `${shapes.borderRadius}px`,
            border: `1px solid ${colors.gradients.pinkRgb}`,
            boxShadow: 'none',
            lineHeight: '20px',
            fontSize: '14px',
            letterSpacing: 0,
            backgroundColor: colors.gradients.pinkRgb,
            padding: '10px 24px',
            '&:hover': {
              boxShadow: 'none',
              border: `1px solid ${colors.gradients.pinkRgb}`,
              backgroundColor: colors.white,
              color: colors.pink.main
            }
          },
          outlined: {
            border: `1px solid ${colors.gradients.pinkRgb}`,
            color: colors.gradients.pinkRgb,
            '&:hover': {
              border: `1px solid ${colors.gradients.pinkRgb}`,
              background: `${colors.gradients.ping} !important`,
              color: colors.white
            },
            '&.Mui-disabled': {
              border: 'none'
            }
          }
        }
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            border: 'none',
            padding: '14px 16px',
            lineHeight: '19.6px'
          }
        }
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            '& .MuiTableCell-head': {
              padding: '3px 16px'
            }
          }
        }
      },
      MuiTable: {
        styleOverrides: {
          root: {
            borderCollapse: 'separate',
            borderSpacing: '0px 8px'
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            background: colors.white,
            borderRadius: `${shapes.borderRadius}px`,
            color: colors.info.main,
            '&.Mui-focused .MuiInputAdornment-root .MuiSvgIcon-root': {
              color: colors.gradients.pinkRgb
            },
            '&:hover': {
              borderColor: colors.gradients.pinkRgb
            },
            '&.Mui-focused': {
              borderColor: colors.gradients.pinkRgb
            },
            '&.Mui-disabled': {
              backgroundColor: colors.grey.background
            }
          },
          input: {
            // borderRadius: `${shapes.borderRadius}px`,
            '&::placeholder': {
              color: colors.grey.placeholder
            },
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px white inset'
            }
          }
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            ...labelStyle
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            ...labelStyle
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            backgroundColor: colors.grey.background,
            color: colors.primary.main,
            fontFamily,
            fontSize: '14px',
            fontWeight: 400
          },
          deleteIcon: {
            color: colors.primary.main
          }
        }
      }
    }
  });

  return theme;
};

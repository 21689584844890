import { Box, SelectChangeEvent, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "./playerCardsNotesTab.css";
import SelectComponent from "../Common/Fields/SelectComponent";
import Comment from "../Comment/Comment";
import { CategoryEnums } from "../../enums/CategoryEnums";
import CommentForm from "./commentForm";
import { GetCommentsQuery } from "../../queries/comment";
import { handleRequestError } from "../../utils/ui";
import { AppContext } from "../../AppContext";
import { useQuery } from "@tanstack/react-query";
import { IComment } from "../../models/comment";
import Loader from "../Common/Global/Loader";
import { useLocation } from "react-router-dom";
import { QueryKey } from "../../enums/HttpRequestKeyEnums";
const PlayerCardsNotesTab: React.FunctionComponent = () => {
  const categoryOptions = Object.values(CategoryEnums);
  const [categories] = useState(categoryOptions || []);
  const location = useLocation();
  const [filterCategory, setFilterCategory] = useState("All");
  const { dispatch } = useContext(AppContext);
  const playerId = location.state.playerCard.id;
  const queryKey = [QueryKey.Comments, playerId];
  const { isLoading, data } = useQuery(
    queryKey,
    () => GetCommentsQuery(playerId),
    {
      onSuccess: ({ data }) => {
        setComments(data);
      },
      onError: ({ response }) => {
        handleRequestError(dispatch, response);
      },
    }
  );
  const [comments, setComments] = useState<IComment[]>(data?.data || []);
  const [filteredComments, setFilteredComments] = useState(comments || []);
  useEffect(() => {
    if (filterCategory === "All") {
      setFilteredComments(comments);
    } else {
      const filtered = comments.filter(
        (c) => c.type.toLowerCase() === filterCategory.toLowerCase()
      );
      setFilteredComments(filtered);
    }
  }, [filterCategory, comments]);

  const handleFilterCategoryChange = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = e;
    if (value) {
      setFilterCategory(value as string);
    }
  };

  return (
    <Box className="container">
      <Box className="add-comments-container">
        {/* Add Comments */}
        <CommentForm playerId={playerId} />
      </Box>
      <Box className="comments-container">
        {/* Comments */}
        <Box sx={{ pl: "24px", pt: "24px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                marginBottom: "11px",
                lineHeight: "22px",
                textAlign: "left",
                textTransform: "uppercase",
              }}
              color="primary.main"
              gutterBottom
            >
              {`Comments (${filteredComments.length})`}
            </Typography>
            <Box sx={{ width: "164px", height: "40px", mr: "34px" }}>
              <SelectComponent
                label=""
                placeholder="Select"
                value={filterCategory}
                isSingle={true}
                onChange={handleFilterCategoryChange}
                options={categories}
                containerStyles={{}}
                hideNoneValue
              />
            </Box>
          </Box>
        </Box>
        {filteredComments.map((c, index) => (
          <Comment
            id={c.id}
            author={c.author}
            dateTime={c.publishDate}
            category={c.type}
            content={c.text}
            key={index}
          />
        ))}
      </Box>
      <Loader loading={isLoading} />
    </Box>
  );
};

export default PlayerCardsNotesTab;

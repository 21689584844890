import React from 'react';
import { Autocomplete, Box, TextField, SxProps, InputLabel } from '@mui/material';
import { getBorderColor } from './SelectComponent';
import { getBorderColorOnHover } from '../../../utils/ui';

type Data = any;

interface IProps {
  id: string;
  label: string;
  value: Data | Data[];
  onChange: (event: unknown, newValue: Data | Data[] | null) => void;
  options: Data[];
  getOptionLabel?: (value: Data) => string;
  isSingle?: boolean;
  errorMsg?: string | null;
  containerStyles?: SxProps;
  disabled?: boolean;
  placeholder?: string;
}

const AutocompleteComponent: React.FunctionComponent<IProps> = ({
  id,
  label,
  value,
  onChange,
  options,
  errorMsg,
  containerStyles,
  disabled = false,
  getOptionLabel,
  placeholder
}: IProps) => {
  return (
    <Box sx={{ ...containerStyles }}>
      <InputLabel id={`${id}-label`} sx={{ marginBottom: '4px' }}>
        {label}
      </InputLabel>
      <Autocomplete
        id={`${id}-autocomplete`}
        data-testid={`${id}-autocomplete`}
        multiple={false}
        value={value}
        fullWidth
        {...(getOptionLabel && { getOptionLabel })}
        onChange={onChange}
        disabled={disabled}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            sx={{
              minHeight: '46px',
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: getBorderColor(value, true)
                },
                '&:hover fieldset, &.Mui-focused fieldset': {
                  borderColor: getBorderColorOnHover(errorMsg)
                }
              }
            }}
            {...(errorMsg !== undefined && {
              error: errorMsg !== null,
              helperText: errorMsg ?? ''
            })}
            placeholder={placeholder ?? ''}
            InputProps={{
              ...params.InputProps,
              sx: { paddingY: '0 !important', position: 'relative' }
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
              sx: {
                padding: '12px 16px !important'
              }
            }}
          />
        )}
      />
    </Box>
  );
};

export default AutocompleteComponent;

import React, { useContext, useState } from 'react';
import DialogBox from '../../Common/Layout/DialogBox';
import { Box } from '@mui/material';
import InputComponent from '../../Common/Fields/InputComponent';
import CancelButton from '../../Common/Buttons/CancelButton';
import MainButton from '../../Common/Buttons/MainButton';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CreatePromotionGroupMutation,
  UpdatePromotionGroupMutation
} from '../../../queries/promotion';
import { getBrandId, handleRequestError } from '../../../utils/ui';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import Loader from '../../Common/Global/Loader';
import { IPromotionGroup } from '../../../models/promotion';

interface IProps {
  group?: IPromotionGroup;
  onClose: () => void;
}

const PromoGroupForm: React.FunctionComponent<IProps> = ({ group, onClose }: IProps) => {
  const { dispatch, state } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const [name, setName] = useState(group?.name ?? '');
  const [errorText, setErrorText] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const createGroupMutation = useMutation(CreatePromotionGroupMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.name} Promotion Group created`
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.Promotions, brandId])
  });

  const updateGroupMutation = useMutation(UpdatePromotionGroupMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.name} Promotion Group updated`
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.Promotions, brandId])
  });

  const handleSave = () => {
    if (group) {
      updateGroupMutation.mutate({ ...group, name });
    } else {
      createGroupMutation.mutate({
        brandId,
        name
      });
    }
  };

  return (
    <DialogBox
      width={800}
      onClose={() => onClose()}
      title={group ? 'Edit Promotion Group' : 'Create New Group'}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <InputComponent
          id="promotion-group-name"
          label="Promotion Name"
          value={name}
          onChange={(event) => {
            setErrorText(event.currentTarget.value.length > 2 ? null : 'name too short');
            setName(event.currentTarget.value);
          }}
          errorMsg={errorText}
          containerStyles={{ flex: 1 }}
        />
        <Box className="form-actions">
          <Box className="form-group-buttons">
            <CancelButton
              id="promotion-group-cancel"
              text="Cancel"
              onClick={() => onClose()}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton
              id="promotion-group-create"
              text={group ? 'Save' : 'Create'}
              disabled={name.length < 3}
              onClick={handleSave}
            />
          </Box>
        </Box>
      </Box>
      <Loader loading={createGroupMutation.isLoading || updateGroupMutation.isLoading} />
    </DialogBox>
  );
};

export default PromoGroupForm;

import React, { useContext, useState } from 'react';
import DialogBox from '../../Common/Layout/DialogBox';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { PromoStatsQuery } from '../../../queries/email';
import { getBrandId, handleRequestError } from '../../../utils/ui';
import { AppContext } from '../../../AppContext';
import Loader from '../../Common/Global/Loader';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import CustomTable, { IHeadCell } from '../../Common/Tables/CustomTable';
import { PlayerStat, PromoStat } from '../../../models/promotion';
import StatDetails from './StatDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IProps {
  id: number;
  promoName: string;
  onClose: () => void;
}

const PromoStats: React.FunctionComponent<IProps> = ({ id, onClose, promoName }: IProps) => {
  const { dispatch, state } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);

  const statsQuery = useQuery({
    queryKey: [QueryKey.DashboardKpi, brandId, id],
    queryFn: () => PromoStatsQuery({ brandId, id }),
    onSuccess: ({ data }) => {
      setStats(data);
      setFiltered(data);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });
  const [stats, setStats] = useState<PromoStat>(statsQuery.data?.data ?? null);
  const [filtered, setFiltered] = useState<PromoStat>(statsQuery.data?.data ?? null);
  const [selectedStat, setSelectedStat] = useState('');
  const [showLinks, setShowLinks] = useState(false);
  const props = Object.keys(stats?.totals ?? []);

  const handleStatClicked = (statName: string) => {
    const name = selectedStat === statName ? '' : statName;
    setSelectedStat(name);
    if (name.length > 0) {
      if (name.startsWith('https')) {
        setFiltered({
          playerStats: stats.playerStats.filter((ps) =>
            ps.events.some((event) => event.url === name)
          )
        } as PromoStat);
      } else {
        setFiltered({
          playerStats: stats.playerStats.filter((ps) => ps.totals[name])
        } as PromoStat);
      }
    } else {
      setFiltered(stats);
    }
  };
  const columns = [
    {
      title: 'Email',
      field: 'email'
    },
    {
      title: 'Totals Per Player',
      field: 'totals',
      render: (data: PlayerStat) => {
        const totalProps = Object.keys(data?.totals ?? []);
        return (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box sx={{ display: 'flex', gap: '12px' }}>
                {totalProps
                  .filter((t) => !t.startsWith('https'))
                  .map((key, index) => (
                    <StatDetails key={`${index}-${key}`} name={key} value={data.totals[key]} />
                  ))}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {data.events.map((ev, index) => (
                <Box
                  key={`${ev.email}-${index}`}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <h5 key={`${ev.email}-${index}`}>{ev.url ?? ev.event}</h5>
                  {new Date(Number(ev.timestamp) * 1000).toISOString()}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      }
    }
  ] as IHeadCell[];

  return (
    <DialogBox width={window.innerWidth * 0.6} onClose={onClose} title={promoName}>
      {!statsQuery.isLoading && stats === null ? (
        <h1>No Data</h1>
      ) : (
        <Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '12px',
              mb: 10,
              alignItems: 'flex-start'
            }}
          >
            <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
              {props
                .filter((s) => !s.startsWith('https'))
                .map((key) => (
                  <StatDetails
                    key={key}
                    name={key}
                    value={stats.totals[key]}
                    handleClick={handleStatClicked}
                    selected={key === selectedStat}
                    total={stats.totals['processed'] as string}
                  />
                ))}
              <FormControlLabel
                label="show links"
                control={
                  <Checkbox
                    checked={showLinks}
                    onChange={() => setShowLinks(!showLinks)}
                    inputProps={{ 'aria-label': 'controlled' }}
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                  />
                }
              />
            </Box>
            {showLinks &&
              props
                .filter((s) => s.startsWith('https'))
                .map((key) => (
                  <StatDetails
                    key={key}
                    name={key}
                    value={stats.totals[key]}
                    handleClick={handleStatClicked}
                    selected={key === selectedStat}
                    total={stats.totals['processed'] as string}
                  />
                ))}
          </Box>
          <CustomTable
            data={filtered?.playerStats || []}
            columns={columns}
            toolbarTitle="Player details"
            showSearch
            rowsPerPage={10}
          />
        </Box>
      )}
      <Loader loading={statsQuery.isLoading} />
    </DialogBox>
  );
};

export default PromoStats;

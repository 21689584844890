import React, { useState } from 'react';
import DialogBox from '../../../Common/Layout/DialogBox';
import Header from '../../../Common/Layout/Header';
import { Box } from '@mui/material';
import InputComponent from '../../../Common/Fields/InputComponent';
import CancelButton from '../../../Common/Buttons/CancelButton';
import MainButton from '../../../Common/Buttons/MainButton';

interface IProps {
  onClose: () => void;
  handleSend: (number: string) => void;
}

const TestSmsForm: React.FunctionComponent<IProps> = ({ onClose, handleSend }: IProps) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  return (
    <DialogBox width={600} onClose={onClose} title="Send Test Sms">
      <Header isSubHeader text="Enter the phone number that you want to send the SMS preview" />
      <Box>
        <InputComponent
          id="phone-number"
          label="Phone Number"
          value={phoneNumber}
          onChange={(event) => setPhoneNumber(event.currentTarget.value)}
        />

        <Box className="form-actions" sx={{ mt: '24px' }}>
          <Box className="form-group-buttons">
            <CancelButton
              id="segment-cancel"
              text="Cancel"
              onClick={onClose}
              sx={{ height: '36px', width: '48px' }}
            />
            <MainButton id="segment-create" text="Send" onClick={() => handleSend(phoneNumber)} />
          </Box>
        </Box>
      </Box>
    </DialogBox>
  );
};

export default TestSmsForm;

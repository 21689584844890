import React, { useContext, useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  IconButton,
  SelectChangeEvent,
  TextField,
  Typography
} from '@mui/material';
import InfoCard, { InfoRow } from '../Common/Layout/InfoCard';
import './playerCardsOverviewTab.css';
import { css } from '@mui/system';
import { useLocation } from 'react-router-dom';
import CommentForm from './commentForm';
import Comment from '../Comment/Comment';
import AddButton from '../Common/Buttons/AddButton';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GetCommentsQuery } from '../../queries/comment';
import {
  formatNumber,
  getBrandId,
  getUserCurrencySymbol,
  handleRequestError
} from '../../utils/ui';
import { AppContext } from '../../AppContext';
import { IComment } from '../../models/comment';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';
import { IUser } from '../../models/user';
import { getCompanyUsersQuery } from '../../queries/user';
import { Check, Clear, Edit } from '@mui/icons-material';
import { colors } from '../../utils/theme';
import ColoredText from '../Common/Other/ColoredText';
import InfoBox from '../Common/Layout/InfoBox/InfoBox';
import {
  UpdatePlayerVipManagerMutation,
  UpdatePlayerVipStatusMutation
} from '../../queries/players';
import { Actions } from '../../enums/ActionEnums';
import { vipStatusOptions } from '../../models/fakeDb';
import { PlayerKpiQuery, PlayerWalletHistoryQuery } from '../../queries/kpi';
import { PlayerKpi, PlayerWalletHistory } from '../../models/kpi';
import { BarLoader } from 'react-spinners';
import LineChart from '../Charts/LineChart';
import { basicRows, financialInfoRows } from './infoBoxDefs';
import SelectComponent from '../Common/Fields/SelectComponent';
import { TransactionTypeEnums } from '../../enums/TransactionTypeEnums';

interface IProps {
  navigateToNotesTab: () => void;
}

export type IErrors = {
  vipStatus: string | null;
  vipManager: string | null;
};

const defaultErrorsObj: IErrors = {
  vipStatus: null,
  vipManager: null
};

const errorTexts = {
  vipStatus: 'Invalid Vip Status!',
  vipManager: 'Invalid Manager!'
};

const PlayerCardsOverviewTab: React.FunctionComponent<IProps> = ({
  navigateToNotesTab
}: IProps) => {
  const location = useLocation();
  const { dispatch, state } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);

  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [editingVipStatus, setEditingVipStatus] = useState(false);
  const [editingVipManager, setEditingVipManager] = useState(false);
  const [selectedTransactionType, setSelectedTransactionType] = useState('Deposits');
  const transactionTypeOptions = Object.values(TransactionTypeEnums);
  const [transactionTypes] = useState(transactionTypeOptions || []);
  const [showVipStatusActions, setShowVipStatusActions] = useState(false);
  const [showVipManagerActions, setShowVipManagerActions] = useState(false);
  const [showVipStatusEdit, setShowVipStatusEdit] = useState(true);
  const [showVipManagerEdit, setShowVipManagerEdit] = useState(true);
  const [vipStatus, setVipStatus] = useState<string>(location.state.playerCard.vipStatus || '');
  const [selectedVipStatus, setSelectedVipStatus] = useState(vipStatus);
  const [vipManager, setVipManager] = useState<string>(location.state.playerCard.vipManager || '');
  const [selectedVipManager, setSelectedVipManager] = useState(vipManager);
  const playerId = location.state.playerCard.id;
  const fullName = `${location.state.playerCard.firstName} ${location.state.playerCard.lastName}`;
  const queryKeyUsers = [QueryKey.Users];
  const { data: usersData } = useQuery(queryKeyUsers, getCompanyUsersQuery, {
    onSuccess: ({ data: usersData }) => {
      setUsers(usersData);
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    }
  });
  const [users, setUsers] = useState<IUser[]>(usersData?.data || []);
  const queryClient = useQueryClient();
  const userOptions = users.map((user) => `${user.firstName} ${user.lastName}`);
  const queryKey = [QueryKey.Comments, playerId];
  const { isLoading, data } = useQuery(queryKey, () => GetCommentsQuery(playerId), {
    onSuccess: ({ data }) => {
      setComments(data);
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    }
  });
  const [comments, setComments] = useState<IComment[]>(data?.data || []);

  const playerKpiQuery = useQuery({
    queryKey: [QueryKey.PlayerKpi, brandId, playerId],
    queryFn: () => PlayerKpiQuery({ brandId, playerId }),
    onSuccess: ({ data }) => {
      setKpiData(data);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });
  const playerWalletQuery = useQuery({
    queryKey: [QueryKey.PlayerWalletHistory, brandId, playerId],
    queryFn: () => PlayerWalletHistoryQuery({ brandId, playerId }),
    onSuccess: ({ data }) => {
      setWalletHistory(data);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const [kpiData, setKpiData] = useState<PlayerKpi | null>(playerKpiQuery.data?.data || null);
  const [walletHistory, setWalletHistory] = useState<PlayerWalletHistory[]>(
    playerWalletQuery.data?.data || []
  );
  useEffect(() => {
    setVipStatus(location.state.playerCard.vipStatus || '');
    setVipManager(location.state.playerCard.vipManager || '');
  }, [location.state]);
  console.log(location.state.playerCard);
  const iconButtonProps = {
    height: '20px',
    width: '20px'
  };

  const updateVipStatus = useMutation(UpdatePlayerVipStatusMutation, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Player ${fullName} updated`
        }
      });
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.Players, brandId])
  });

  const handleVipStatusChange = (value: string) => {
    setFormErrors({
      ...formErrors,
      vipStatus: value && value.length > 0 ? null : errorTexts.vipStatus
    });
    if (value) {
      setSelectedVipStatus(value as string);
    }
  };

  const updateVipManager = useMutation(UpdatePlayerVipManagerMutation, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Player ${fullName} updated`
        }
      });
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.Players, brandId])
  });

  const handleVipManagerChange = (value: string) => {
    setFormErrors({
      ...formErrors,
      vipManager: value && value.length > 0 ? null : errorTexts.vipManager
    });
    if (value) {
      setSelectedVipManager(value as string);
    }
  };

  const handleEditVipStatusClick = () => {
    setEditingVipStatus(true);
    setShowVipStatusActions(true);
    setShowVipStatusEdit(false);
  };

  const handleVipStatusCheckClick = () => {
    const errors = {
      vipStatus: null,
      vipManager: null
    } as IErrors;
    let hasErrors = false;
    if (selectedVipStatus.length === 0) {
      hasErrors = true;
      errors.vipStatus = errorTexts.vipStatus;
    }
    const data = {
      playerId,
      vipStatus: selectedVipStatus
    };
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      setEditingVipStatus(false);
      setShowVipStatusActions(false);
      setShowVipStatusEdit(true);
      setVipStatus(selectedVipStatus);
      updateVipStatus.mutate(data);
    }
  };

  const handleVipManagerCheckClick = () => {
    const errors = {
      vipStatus: null,
      vipManager: null
    } as IErrors;
    let hasErrors = false;
    if (selectedVipManager.length === 0) {
      hasErrors = true;
      errors.vipManager = errorTexts.vipManager;
    }
    const data = {
      playerId,
      vipManager: selectedVipManager
    };
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      setEditingVipManager(false);
      setShowVipManagerActions(false);
      setShowVipManagerEdit(true);
      setVipManager(selectedVipManager);
      updateVipManager.mutate(data);
    }
  };

  const handleVipStatusClearClick = () => {
    setEditingVipStatus(false);
    setShowVipStatusActions(false);
    setShowVipStatusEdit(true);
    setSelectedVipStatus('');
  };

  const handleEditVipManagerClick = () => {
    setEditingVipManager(true);
    setShowVipManagerActions(true);
    setShowVipManagerEdit(false);
  };

  const handleVipManagerClearClick = () => {
    setEditingVipManager(false);
    setShowVipManagerActions(false);
    setShowVipManagerEdit(true);
    setSelectedVipManager('');
  };

  const basicInfoRows = [
    ...basicRows(location, playerId),
    {
      title: 'VIP Status',
      field: 'vipStatus',
      value: (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {editingVipStatus ? (
            <Autocomplete
              options={vipStatusOptions}
              clearIcon={false}
              renderInput={(params) => (
                <TextField {...params} helperText={formErrors ? formErrors.vipStatus : ''} />
              )}
              value={selectedVipStatus}
              onChange={(_, newValue) => handleVipStatusChange(newValue as string)}
              style={{ width: '240px' }}
              sx={{
                height: '10px',
                ...css`
                  & .MuiAutocomplete-inputRoot {
                    padding: 0;
                    padding-left: 5px;
                  }
                `
              }}
            />
          ) : (
            <>
              {vipStatus ? (
                <ColoredText
                  text={vipStatus}
                  color={colors.gradients.pinkRgb}
                  sx={{
                    padding: '4px 8px',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '140%'
                  }}
                />
              ) : (
                <Box />
              )}
            </>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex' }}>
              {showVipStatusEdit && (
                <IconButton sx={{ ...iconButtonProps }}>
                  <Edit
                    sx={{ color: 'error.main', width: '20px', height: '20px' }}
                    onClick={handleEditVipStatusClick}
                  />
                </IconButton>
              )}
              {showVipStatusActions && (
                <Box sx={{ display: 'flex', ml: '20px' }}>
                  <IconButton sx={{ ...iconButtonProps, mr: '8px' }}>
                    <Check
                      sx={{ color: 'success.main', width: '20px', height: '20px' }}
                      onClick={handleVipStatusCheckClick}
                    />
                  </IconButton>
                  <IconButton sx={{ ...iconButtonProps }}>
                    <Clear
                      sx={{
                        color: 'error.main',
                        width: '20px',
                        height: '20px'
                      }}
                      onClick={handleVipStatusClearClick}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )
    },
    {
      title: 'VIP Manager',
      field: 'vipManager',
      value: (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {editingVipManager ? (
            <Autocomplete
              options={userOptions}
              clearIcon={false}
              renderInput={(params) => (
                <TextField {...params} helperText={formErrors ? formErrors.vipManager : ''} />
              )}
              value={selectedVipManager}
              onChange={(_, newValue) => handleVipManagerChange(newValue as string)}
              style={{ width: '240px' }}
              sx={{
                height: '10px',
                ...css`
                  & .MuiAutocomplete-inputRoot {
                    padding: 0;
                    padding-left: 5px;
                  }
                `
              }}
            />
          ) : (
            <Box>{vipManager}</Box>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex' }}>
              {showVipManagerEdit && (
                <IconButton sx={{ ...iconButtonProps }}>
                  <Edit
                    sx={{ color: 'error.main', width: '20px', height: '20px' }}
                    onClick={handleEditVipManagerClick}
                  />
                </IconButton>
              )}
              {showVipManagerActions && (
                <Box sx={{ display: 'flex', ml: '20px' }}>
                  <IconButton sx={{ ...iconButtonProps, mr: '8px' }}>
                    <Check
                      sx={{ color: 'success.main', width: '20px', height: '20px' }}
                      onClick={handleVipManagerCheckClick}
                    />
                  </IconButton>
                  <IconButton sx={{ ...iconButtonProps }}>
                    <Clear
                      sx={{ color: 'error.main', width: '20px', height: '20px' }}
                      onClick={handleVipManagerClearClick}
                    />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )
    }
  ];

  const handleViewAllClick = () => {
    navigateToNotesTab();
  };

  const handleTransactionTypeChange = (e: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = e;
    if (value) {
      setSelectedTransactionType(value as string);
    }
  };

  const symbol = getUserCurrencySymbol(location.state.playerCard.currency);
  return (
    <Box className="container">
      <Box className="info-cards-container">
        {/* Basic Info */}

        <InfoCard title="Basic Info" rows={basicInfoRows as InfoRow[]} />
        {/* Financial Info */}
        <InfoCard title="Financial Info" rows={financialInfoRows(location, symbol)} />
      </Box>
      <Box className="right-column-container">
        {/* Players Stats */}
        <Box className="player-stats-box">
          <Box className="data-box-title">
            <Box>Player Stats</Box>
            <Box sx={{ display: 'flex', gap: '8px' }}>
              <SelectComponent
                label=""
                placeholder="Select"
                value={selectedTransactionType}
                isSingle={true}
                onChange={handleTransactionTypeChange}
                options={transactionTypes}
                containerStyles={{}}
                hideNoneValue
                height={36}
              />
            </Box>
          </Box>
          <LineChart
            series={{
              name:
                selectedTransactionType === 'Deposits'
                  ? `${location.state.playerCard.firstName} deposits`
                  : `${location.state.playerCard.firstName} withdrawals`,
              data: walletHistory
                .filter((wh) =>
                  selectedTransactionType === 'Deposits'
                    ? wh.transactionType === 'Deposit'
                    : wh.transactionType === 'Withdrawal'
                )
                .map((wh) => wh.monthlyTotal)
            }}
            markSymbol={symbol}
            labels={walletHistory
              .filter((wh) =>
                selectedTransactionType === 'Deposits'
                  ? wh.transactionType === 'Deposit'
                  : wh.transactionType === 'Withdrawal'
              )
              .map((wh) => wh.month)}
          />
        </Box>
        {/* KPIs */}
        <Box className="kpis-box" sx={{ display: 'inline-flex' }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 600,
              lineHeight: '22px',
              textAlign: 'left'
            }}
            color="primary.main"
            gutterBottom
          >
            {'KPIs'}
          </Typography>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
              gap: '20px'
            }}
          >
            <InfoBox
              title="MTD Deposits"
              percentValue={kpiData?.depositsPercentageChange ?? 0}
              trend={kpiData && kpiData.depositsPercentageChange < 0 ? 'decreasing' : 'increasing'}
              value={`${symbol}${formatNumber(kpiData?.depositsCurrentMonth ?? 0)}`}
              valueLegend={`SPLM: ${formatNumber(kpiData?.depositsPreviousMonth ?? 0)}`}
              loading={playerKpiQuery.isLoading}
              pinkValue
            />
            <InfoBox
              title="MTD Withdrawals"
              percentValue={kpiData?.withdrawalsPercentageChange ?? 0}
              trend={
                kpiData && kpiData.withdrawalsPercentageChange < 0 ? 'decreasing' : 'increasing'
              }
              value={`${symbol}${formatNumber(kpiData?.withdrawalsCurrentMonth ?? 0)}`}
              valueLegend={`SPLM: ${formatNumber(kpiData?.withdrawalsPreviousMonth ?? 0)}`}
              loading={playerKpiQuery.isLoading}
              pinkValue
            />
            <InfoBox
              title="MTD Bonuses"
              percentValue={kpiData?.bonusesPercentageChange ?? 0}
              trend={kpiData && kpiData.bonusesPercentageChange < 0 ? 'decreasing' : 'increasing'}
              value={`${symbol}${formatNumber(kpiData?.bonusesCurrentMonth ?? 0)}`}
              valueLegend={`SPLM: ${formatNumber(kpiData?.bonusesPreviousMonth ?? 0)}`}
              loading={playerKpiQuery.isLoading}
              pinkValue
            />
            <InfoBox
              title="MTD Bonus Count"
              percentValue={kpiData?.bonusCountPercentageChange ?? 0}
              trend={
                kpiData && kpiData.bonusCountPercentageChange < 0 ? 'decreasing' : 'increasing'
              }
              value={(kpiData?.bonusCountCurrentMonth ?? 0).toString()}
              valueLegend={`SPLM: ${formatNumber(kpiData?.bonusCountPreviousMonth ?? 0)}`}
              loading={playerKpiQuery.isLoading}
              pinkValue
            />
          </Box>
        </Box>
        <Box className="comments-timeLine-container">
          <Box className="comments-box">
            <Box sx={{ pl: '24px', pt: '24px' }}>
              <Typography
                sx={{
                  fontSize: 16,
                  fontWeight: 600,
                  marginBottom: '11px',
                  lineHeight: '22px',
                  textAlign: 'left',
                  textTransform: 'uppercase'
                }}
                color="primary.main"
                gutterBottom
              >
                {`Comments (${comments.length})`}
              </Typography>
            </Box>
            <CommentForm showTitle={false} playerId={playerId} />
            <Box sx={{ overflow: 'auto' }}>
              {isLoading && (
                <BarLoader
                  color="rgb(236,26,88)"
                  height={2}
                  speedMultiplier={1}
                  cssOverride={{ top: '20px' }}
                />
              )}
              {comments.map((c, index) => (
                <Comment
                  id={c.id}
                  author={c.author}
                  dateTime={c.publishDate}
                  category={c.type}
                  content={c.text}
                  key={index}
                />
              ))}
            </Box>
            <Box sx={{ m: '24px' }}>
              <AddButton onClick={handleViewAllClick} id="add-comment-save" text="View All" />
            </Box>
          </Box>
          <Box className="timeLine-box">
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 600,
                marginBottom: '11px',
                lineHeight: '22px',
                textAlign: 'left',
                padding: '24px'
              }}
              color="primary.main"
              gutterBottom
            >
              {'Timeline'}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PlayerCardsOverviewTab;

import React, { useEffect, useState } from 'react';
import { DeleteOutlineOutlined } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { SegmentCriteria } from '../../../models/segmets';
import InputComponent from '../../Common/Fields/InputComponent';
import SelectComponent from '../../Common/Fields/SelectComponent';
import { ConditionalOperators } from '../../../models/fakeDb';
import { CriteriaAttribute } from '../../../enums/InputEnums';
import DateComponent from '../../Common/Fields/DateComponent';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

interface IProps {
  index: number;
  segmentCriteria: SegmentCriteria;
  hideDelete: boolean;
  onChange: (index: number, criteria: SegmentCriteria) => void;
  remove: (id: number) => void;
}

const SegmentAttribute: React.FunctionComponent<IProps> = ({
  index,
  segmentCriteria,
  hideDelete,
  onChange,
  remove
}: IProps) => {
  const [operators, setOperators] = useState(ConditionalOperators);

  useEffect(() => {
    const { name } = segmentCriteria;
    if (
      name === CriteriaAttribute.FullName ||
      name === CriteriaAttribute.VipStatus ||
      name === CriteriaAttribute.VipManager ||
      name === CriteriaAttribute.Country ||
      name === CriteriaAttribute.Email ||
      name === CriteriaAttribute.LeadProduct ||
      name === CriteriaAttribute.AccountStatus
    ) {
      setOperators(['=', '!=']);
    } else if (name === CriteriaAttribute.IsBlocked || name === CriteriaAttribute.EmailEnabled) {
      setOperators(['=']);
    } else {
      setOperators(ConditionalOperators);
    }
  }, [segmentCriteria.name]);

  const getInputComponent = () => {
    const { name } = segmentCriteria;
    name === CriteriaAttribute.IsBlocked || name === CriteriaAttribute.EmailEnabled;
    if (name.includes('ABS')) {
      const dateValue = dayjs(segmentCriteria.value).utc(true).toDate();
      return (
        <Box sx={{ flex: 2 }}>
          <DateComponent
            id={`${segmentCriteria.name}-datepicker`}
            label="Value"
            onChange={(event) =>
              onChange(index, {
                ...segmentCriteria,
                value: dayjs(event).utc(true).format('YYYY-MM-DD')
              })
            }
            value={dateValue}
          />
        </Box>
      );
    } else if (name === CriteriaAttribute.IsBlocked || name === CriteriaAttribute.EmailEnabled) {
      return (
        <SelectComponent
          label="value"
          value={segmentCriteria.value}
          onChange={(event) =>
            onChange(index, {
              ...segmentCriteria,
              value: event.target.value as string
            })
          }
          options={['true', 'false']}
          isSingle
          hideNoneValue
          containerStyles={{ flex: 2 }}
        />
      );
    } else {
      return (
        <InputComponent
          id={`${index}-value`}
          label={
            segmentCriteria.name === CriteriaAttribute.LastLogin ||
            segmentCriteria.name === CriteriaAttribute.Registered ||
            segmentCriteria.name === CriteriaAttribute.FirstDepositDate ||
            segmentCriteria.name === CriteriaAttribute.LastDepositDate
              ? 'Days Ago'
              : 'Value'
          }
          value={segmentCriteria.value}
          onChange={(event) =>
            onChange(index, {
              ...segmentCriteria,
              value: event.currentTarget.value
            })
          }
          containerStyles={{ flex: 2 }}
        />
      );
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: '5px' }}>
      <Box sx={{ display: 'flex', flexGrow: 1, gap: '24px' }}>
        <SelectComponent
          label="Attribute Name"
          value={segmentCriteria.name}
          isSingle
          onChange={(e) => {
            const {
              target: { value }
            } = e;
            onChange(index, { ...segmentCriteria, name: value as string });
          }}
          options={Object.values(CriteriaAttribute)}
          containerStyles={{ flex: 2 }}
          hideNoneValue
        />
        <SelectComponent
          label="Is"
          value={segmentCriteria.operator}
          isSingle
          onChange={(e) => {
            const {
              target: { value }
            } = e;
            onChange(index, { ...segmentCriteria, operator: value as string });
          }}
          options={operators}
          containerStyles={{ flex: 1 }}
          hideNoneValue
        />
        {getInputComponent()}
      </Box>
      {!hideDelete && (
        <IconButton
          aria-label="delete"
          sx={{
            flexFlow: 0,
            width: '28px',
            height: '28px',
            position: 'relative',
            top: '25px'
          }}
          onClick={() => remove(index)}
        >
          <DeleteOutlineOutlined
            sx={{
              color: 'error.main',
              width: '24px',
              height: '24px',
              cursor: 'pointer'
            }}
          />
        </IconButton>
      )}
    </Box>
  );
};

export default SegmentAttribute;

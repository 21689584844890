// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

export function skipTake(arr: any[], skip: number, take: number) {
  const result = arr.slice(skip, skip + take);
  return result;
}

export const getTokenArrayProp = (input: unknown) => {
  if (typeof input === 'string') {
    return [input];
  } else {
    return input;
  }
};

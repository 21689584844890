import React from 'react';
import './index.css';
import { Box, Divider, SelectChangeEvent } from '@mui/material';
import SelectComponent from '../../../Common/Fields/SelectComponent';
import { ChannelOperators } from '../../../../models/fakeDb';
import EmailContent from './EmailContent';
import SmsContent from './SmsContent';

interface IProps {
  channel: string;
  setChannel: (value: string) => void;
  emailSender: number | null;
  setEmailSender: (input: number | null) => void;
  template: string | null;
  setTemplate: (input: string | null) => void;
  smsText: string | null;
  setSmsText: (input: string | null) => void;
  allowBoth?: boolean;
}

const ContentStep: React.FunctionComponent<IProps> = ({
  channel,
  setChannel,
  emailSender,
  setEmailSender,
  template,
  setTemplate,
  smsText,
  setSmsText,
  allowBoth = false
}: IProps) => {
  const handleChannelChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event;
    setChannel(value as string);
    if (value !== channel) {
      setEmailSender(null);
      setTemplate(null);
      setSmsText(null);
    }
  };

  const getContentComponent = () => {
    switch (channel.toLowerCase()) {
      case 'email':
        return (
          <EmailContent
            emailSender={emailSender}
            setEmailSender={setEmailSender}
            template={template}
            setTemplate={setTemplate}
          />
        );
      case 'sms':
        return <SmsContent smsText={smsText} setSmsText={setSmsText} />;
      default:
        return (
          <>
            <SmsContent smsText={smsText} setSmsText={setSmsText} />
            <EmailContent
              emailSender={emailSender}
              setEmailSender={setEmailSender}
              template={template}
              setTemplate={setTemplate}
            />
          </>
        );
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '100%' }}>
      <Box className="step-title" sx={{ mb: '15px' }}>
        Content
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          maxWidth: '100%',
          overflow: 'hidden'
        }}
      >
        <SelectComponent
          label="Message Channel"
          value={[channel]}
          isSingle
          onChange={handleChannelChange}
          options={allowBoth ? [...ChannelOperators, 'Email & Sms'] : ChannelOperators}
          hideNoneValue
          containerStyles={{ width: '100%' }}
        />
        <Divider />
        {getContentComponent()}
      </Box>
    </Box>
  );
};

export default ContentStep;

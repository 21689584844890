import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Grow, Tooltip } from '@mui/material';
import Header from '../../Common/Layout/Header';
import AddButton from '../../Common/Buttons/AddButton';
import BreadcrumbsNav from '../../Navigation/BreadcrumbsNav';
import SegmentGroup from './SegmentGroup/SegmentGroup';
import { AuthRoutes, RouteNames } from '../../../enums/RouteEnums';
import { useLocation } from 'react-router-dom';
import { IRouteItem } from '../../../models/global';
import SearchComponent from '../../Common/Fields/SearchComponent';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import SegmentsOverview from './SegmentsOverview';
import SegmentForm from './SegmentForm';
import { Segment, SegmentGroup as SegmentGroupType } from '../../../models/segmets';
import SegmentGroupForm from './SegmentGroupForm';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CreateSegmentGroupMutation,
  DeleteSegmentGroupMutation,
  SegmentGroupsQuery,
  UpdateSegmentGroupMutation
} from '../../../queries/segment';
import { getBrandDisplayName, getBrandId, handleRequestError } from '../../../utils/ui';
import Loader from '../../Common/Global/Loader';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { getMilescondsToRefetchSegments } from '../../../utils/math';
import { CloudDownloadOutlined, CloudUploadOutlined } from '@mui/icons-material';
import { GetPlayersByEmailsQuery } from '../../../queries/players';
import { IPlayer } from '../../../models/player';
import { isValidEmail } from '../../../utils/validators';
import { segmentPlayersTemplateHeaders } from '../../../models/fakeDb';

interface IProps {}

const SegmentGroupsOverview: React.FunctionComponent<IProps> = ({}: IProps) => {
  const fnKey = QueryKey.SegmentGroups;
  const tempalteUrl = URL.createObjectURL(
    new Blob([segmentPlayersTemplateHeaders], { type: 'text/csv' })
  );
  const queryClient = useQueryClient();
  const { dispatch, state } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const brand = getBrandDisplayName(state.selectedBrand);
  const segmentGroupsQuery = useQuery({
    queryKey: [fnKey, brandId],
    queryFn: () => SegmentGroupsQuery(brandId),
    onSuccess: ({ data }) => {
      setFilteredGroups(data);
      setGroups(data);
    },
    staleTime: getMilescondsToRefetchSegments()
  });

  // useEffect(() => {
  //   const data = segmentGroupsQuery.data?.data as SegmentGroupType[];
  //   setFilteredGroups(data);
  //   setGroups(data);
  //   if (selectedGroup) {
  //     setSelectedGroup(data.find((sg) => sg.id === selectedGroup.id) as SegmentGroupType);
  //   }
  // }, [segmentGroupsQuery.data]);

  const createGroupMutation = useMutation(CreateSegmentGroupMutation, {
    onSuccess: ({ data }) => {
      setGroups([data, ...groups]);
      setFilteredGroups([data, ...groups]);
      queryClient.setQueryData<SegmentGroupType[]>([fnKey, brandId], (oldData) =>
        oldData
          ? //@ts-ignore
            { ...oldData, data: [data, ...oldData.data] }
          : oldData
      );
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([fnKey, brandId])
  });

  const updateGroupMutation = useMutation(UpdateSegmentGroupMutation, {
    onSuccess: ({ data }) => {
      const updatedGroup = data as SegmentGroupType;
      setGroups(groups.map((g) => (g.id === data.id ? updatedGroup : g)));
      setFilteredGroups(filteredGroups.map((g) => (g.id === data.id ? updatedGroup : g)));
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: 'Segment group updated'
        }
      });

      queryClient.setQueryData<SegmentGroupType[]>([fnKey, brandId], (oldData) =>
        oldData
          ? {
              ...oldData,
              //@ts-ignore
              data: oldData.data.map((sg) => (sg.id === data.id ? data : sg))
            }
          : oldData
      );
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([fnKey, brandId])
  });

  const deleteSegmentGroupMutation = useMutation(DeleteSegmentGroupMutation, {
    onSuccess: ({ data }) => {
      const groupId = data as number;
      const group = groups.find((g) => g.id === groupId);
      const filtered = groups.filter((g) => g.id !== groupId);
      setGroups(filtered);
      setFilteredGroups(filtered);
      dispatch({
        type: Actions.HideConfirmation
      });
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `Successfully deleted ${group?.name} group`
        }
      });
      queryClient.setQueryData<SegmentGroupType[]>([fnKey, brandId], (oldData) =>
        oldData
          ? //@ts-ignore
            { ...oldData, data: filtered }
          : oldData
      );
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([fnKey, brandId])
  });

  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState<string>(RouteNames.Segments);
  const [routeItems, setRouteItems] = useState<IRouteItem[]>([]);
  const [showDetails, setShowDetails] = useState(location.state?.showDetails ?? false);
  const [groups, setGroups] = useState<SegmentGroupType[]>(segmentGroupsQuery.data?.data || []);
  const [filteredGroups, setFilteredGroups] = useState<SegmentGroupType[]>(
    segmentGroupsQuery.data?.data || []
  );
  const [selectedGroup, setSelectedGroup] = useState<SegmentGroupType | null>(null);
  const [openGroupForm, setOpenGroupForm] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [selectedSegment, setSelectedSegment] = useState<Segment | null>(null);
  const [dbPlayers, setDbPlayers] = useState<IPlayer[]>([]);

  const getPlayersByEmails = useMutation(GetPlayersByEmailsQuery, {
    onSuccess: ({ data }) => {
      setDbPlayers(data);
    },
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  useEffect(() => {
    if (!location.state?.showGroups) {
      setShowDetails(location.state?.showGroups);
      setCurrentRoute(RouteNames.Segments);
      setRouteItems([]);
    }
  }, [location.state]);

  useEffect(() => {
    const segmentGroups = segmentGroupsQuery.data?.data || [];
    setGroups(segmentGroups);
    setFilteredGroups(segmentGroups);
  }, [state.selectedBrand]);

  useEffect(() => {
    if (segmentGroupsQuery.data?.data && selectedGroup) {
      const item = (segmentGroupsQuery.data?.data as SegmentGroupType[]).find(
        (sg) => sg.id === selectedGroup.id
      );
      setSelectedGroup(item ?? null);
    }
  }, [segmentGroupsQuery.data]);

  const handleSearch = (text: string) => {
    if (text.length > 0) {
      setFilteredGroups(groups.filter((g) => g.name.toUpperCase().includes(text.toUpperCase())));
    } else {
      setFilteredGroups(groups);
    }
  };

  const handleDetails = (id: number) => {
    const group = groups.find((g) => g.id === id) as SegmentGroupType;
    setShowDetails(true);
    setSelectedGroup(group);
    setCurrentRoute(group.name);
    setRouteItems([{ name: RouteNames.Segments, path: AuthRoutes.Segments }]);
  };

  const handleEdit = (id: number) => {
    const group = groups.find((g) => g.id === id) as SegmentGroupType;
    setSelectedGroup(group);
    setOpenGroupForm(true);
  };

  const handleDelete = (id: number) => {
    const group = groups.find((g) => g.id === id) as SegmentGroupType;
    dispatch({
      type: Actions.ShowConfirmation,
      payload: {
        text: `Are you sure you want to delete ${group.name} group? You will delete also its segments!`,
        agreeAction: () => deleteSegmentGroupMutation.mutate(group.id)
      }
    });
  };

  const handleNewCroupCreate = (name: string) => {
    const selectedBrandId = getBrandId(state.selectedBrand);
    createGroupMutation.mutate({ brandId: selectedBrandId, name });
  };

  const handleGroupEditClose = (saved: boolean, name?: string) => {
    if (saved && name) {
      const groupToUpdate = groups.find((g) => g.id === selectedGroup?.id) as SegmentGroupType;
      updateGroupMutation.mutate({ ...groupToUpdate, name });
    }

    setSelectedGroup(null);
    setOpenGroupForm(false);
  };

  const handleOpenSegmentForm = (segment?: Segment) => {
    setOpenForm(true);
    if (segment) {
      setSelectedSegment(segment);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
          if (e && e.target && e.target.result) {
            const csvData: string = e.target.result as string;
            const rows = csvData.split('\n');
            if (rows.length > 1) {
              const header = rows[0].trim(); // Get the first row as header
              if (header.toLowerCase() === 'email') {
                const data = rows.slice(1).map((row) => {
                  const values = row.split(',').map((value) => value.trim());
                  const email = values[0];
                  return { email };
                });

                const emails = data.filter((e) => isValidEmail(e.email)).map((e) => e.email);
                getPlayersByEmails.mutate({ brand: brand, emails: emails });
                setOpenForm(true);
              } else {
                dispatch({
                  type: Actions.ShowMessage,
                  payload: {
                    severity: 'info',
                    text: 'CSV file must have a single header named "email".'
                  }
                });
              }
            } else {
              dispatch({
                type: Actions.ShowMessage,
                payload: {
                  severity: 'info',
                  text: 'No valid data found in the CSV file.'
                }
              });
            }
          }
        };
      }
    }
    event.target.value = '';
  };

  return (
    <Box className="nav-page">
      <BreadcrumbsNav current={currentRoute} items={routeItems} />
      <Box sx={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Header text="Segments" />
          <Header text="Add or edit different segments" isSubHeader={true} />
        </Box>
        {!showDetails && (
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0 }}>
            <SearchComponent placeholder="Search by group name" onSearch={handleSearch} />
          </Box>
        )}
        {!showDetails && (
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0, gap: '10px' }}>
            <Tooltip title="Download player csv template">
              <a href={tempalteUrl} download="playersEmails.csv" target="_blank">
                <Button variant="outlined" onClick={() => true}>
                  <CloudDownloadOutlined />
                </Button>
              </a>
            </Tooltip>
            <Box>
              <input
                id="player-upload"
                accept="text/csv"
                type="file"
                onChange={(e) => handleFileUpload(e)}
                hidden
              />
              <Tooltip title="Upload Players">
                <Button
                  variant="outlined"
                  onClick={() => {
                    document.getElementById('player-upload')?.click();
                  }}
                >
                  <CloudUploadOutlined />
                </Button>
              </Tooltip>
            </Box>
            <AddButton
              id="add-segment-button"
              text="+ Add Segment"
              onClick={() => setOpenForm(true)}
            />
          </Box>
        )}
      </Box>
      {showDetails ? (
        <SegmentsOverview
          segmentGroup={selectedGroup as SegmentGroupType}
          openForm={handleOpenSegmentForm}
        />
      ) : (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
            gap: '24px 12px'
          }}
        >
          {filteredGroups.map((s, i) => (
            <Grow key={i} in timeout={(i + 1) * 200}>
              <SegmentGroup
                group={s}
                segments={s.segments}
                showDetails={handleDetails}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            </Grow>
          ))}
        </Box>
      )}
      {openForm && (
        <SegmentForm
          groups={groups}
          onClose={() => {
            setOpenForm(false);
            setSelectedSegment(null);
            setDbPlayers([]);
          }}
          createGroup={handleNewCroupCreate}
          segment={selectedSegment}
          players={dbPlayers}
        />
      )}
      {openGroupForm && (
        <SegmentGroupForm group={selectedGroup as SegmentGroup} onClose={handleGroupEditClose} />
      )}
      <Loader
        loading={
          segmentGroupsQuery.isLoading ||
          deleteSegmentGroupMutation.isLoading ||
          createGroupMutation.isLoading ||
          updateGroupMutation.isLoading
        }
      />
    </Box>
  );
};

export default SegmentGroupsOverview;

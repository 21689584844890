export enum Actions {
  SetUser = 'set_user',
  RemoveUser = 'remove_user',
  SetRoles = 'set_roles',
  ShowMessage = 'show_message',
  HideMessage = 'hide_message',
  ShowConfirmation = 'show_confirmation',
  HideConfirmation = 'hide_confirmation',
  SetSelectedBrand = 'set_selected_brand',
  AddBrand = 'add_brand',
  DeleteBrand = 'delete_brand',
  UpdateBrand = 'update_brand',
  SetJourneyGroups = 'set_journey_groups',
  SetJourney = 'set_journey',
  ResetJourney = 'reset_journey',
  SetSelectedJourneyStage = 'set_selected_journey_stage'
}

export enum PromotionStage {
  Pending = 'Pending',
  Active = 'Active',
  Finished = 'Finished',
  Deactivated = 'Deactivated'
}

export enum PlayerStage {
  New = 'New',
  Onboarding = 'Onboarding',
  Offered = 'Offered',
  Closed = 'Closed'
}

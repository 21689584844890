import React from 'react';
import { Pagination } from '@mui/material';
import { colors } from '../../../utils/theme';

interface IProps {
  page: number;
  pages: number;
  handlePageChange: (_: unknown, value: number) => void;
}

const Paging: React.FunctionComponent<IProps> = ({ page, pages, handlePageChange }: IProps) => {
  return (
    <Pagination
      sx={{
        mt: 'auto',
        '& .MuiPaginationItem-root': {
          '&:hover': {
            border: `1px solid ${colors.gradients.pinkRgb}`,
            backgroundColor: colors.white,
            color: colors.pink.main
          }
        },
        '& .Mui-selected': {
          border: `1px solid ${colors.gradients.pinkRgb}`,
          backgroundColor: `${colors.gradients.pinkRgb} !important`,
          color: 'white !important',
          '&:hover': {
            border: `1px solid ${colors.gradients.pinkRgb}`,
            backgroundColor: colors.white,
            color: colors.pink.main
          }
        }
      }}
      page={page}
      count={pages}
      defaultPage={1}
      siblingCount={0}
      onChange={handlePageChange}
    />
  );
};

export default Paging;

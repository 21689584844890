import React, { useContext, useState } from 'react';
import DialogBox from '../../Common/Layout/DialogBox';
import { JourneyGroup } from '../../../models/journeys';
import InputComponent from '../../Common/Fields/InputComponent';
import { Box } from '@mui/material';
import CancelButton from '../../Common/Buttons/CancelButton';
import MainButton from '../../Common/Buttons/MainButton';
import { AppContext } from '../../../AppContext';
import { getBrandId, handleRequestError } from '../../../utils/ui';
import { CreateJourneyGroupMutation, UpdateJourneyGroupMutation } from '../../../queries/journey';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Actions } from '../../../enums/ActionEnums';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';

interface IProps {
  onClose: () => void;
  journeyGroup?: JourneyGroup;
}

const JourneyGroupForm: React.FunctionComponent<IProps> = ({ onClose, journeyGroup }: IProps) => {
  const { dispatch, state } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const [name, setName] = useState(journeyGroup?.name ?? '');
  const queryClient = useQueryClient();

  const createGroup = useMutation(CreateJourneyGroupMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.name} Journey Group created`
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.JourneyGroups, brandId])
  });

  const updateGroupMutation = useMutation(UpdateJourneyGroupMutation, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: `${data.name} Journey Group updated`
        }
      });
      onClose();
    },
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSettled: () => queryClient.invalidateQueries([QueryKey.JourneyGroups, brandId])
  });

  const handleSave = () => {
    if (journeyGroup) {
      updateGroupMutation.mutate({ ...journeyGroup, name });
    } else {
      createGroup.mutate({
        brandId,
        name
      });
    }
  };

  return (
    <DialogBox
      width={800}
      onClose={onClose}
      title={journeyGroup ? 'Edit Journey Group' : 'Create New Journey Group'}
    >
      <InputComponent
        id="journey-group-name"
        label="Journey Group Name"
        value={name}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <Box className="form-actions" sx={{ mt: '24px' }}>
        <Box className="form-group-buttons">
          <CancelButton
            id="journey-group-cancel"
            text="Cancel"
            onClick={onClose}
            sx={{ height: '36px', width: '48px' }}
          />
          <MainButton
            id="journey-group-create"
            text={journeyGroup ? 'Save' : 'Create'}
            onClick={handleSave}
          />
        </Box>
      </Box>
    </DialogBox>
  );
};

export default JourneyGroupForm;

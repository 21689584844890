import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Divider, SelectChangeEvent } from '@mui/material';
import SelectComponent from '../../../Common/Fields/SelectComponent';
import InputComponent from '../../../Common/Fields/InputComponent';
import { AppContext } from '../../../../AppContext';
import { getBrandId, handleRequestError } from '../../../../utils/ui';
import { useQuery, useMutation } from '@tanstack/react-query';
import { QueryKey } from '../../../../enums/HttpRequestKeyEnums';
import {
  PromotionEmailOptionsQuery,
  SendPromoTestEmail,
  TemplateDetailsQuery
} from '../../../../queries/promotion';
import {
  ITemplate,
  ITemplateDetails,
  PromoEmailOptions,
  PromoTestEmail,
  Sender
} from '../../../../models/promotion';
import Loader from '../../../Common/Global/Loader';
import './index.css';
import TestEmailForm from './TestEmailForm';
import { Actions } from '../../../../enums/ActionEnums';
import AutocompleteComponent from '../../../Common/Fields/AutocompleteComponent';
import HtmlPreview from '../../../Common/Layout/HtmlPreview';

interface IProps {
  emailSender: number | null;
  setEmailSender: (input: number) => void;
  template: string | null;
  setTemplate: (input: string | null) => void;
  isPreview?: boolean;
}

const EmailContent: React.FunctionComponent<IProps> = ({
  emailSender,
  setEmailSender,
  template,
  setTemplate,
  isPreview = false
}: IProps) => {
  const { dispatch, state } = useContext(AppContext);
  const fnKey = QueryKey.PromotionEmailOptions;
  const brandId = getBrandId(state.selectedBrand);

  const emailOptionsQuery = useQuery({
    queryKey: [fnKey, brandId],
    queryFn: () => PromotionEmailOptionsQuery(brandId),
    onSuccess: ({ data }) => {
      const options = data as PromoEmailOptions;
      setSenderOptions(options.senders);
      setTemplateOptions(options.templates);
      if (emailSender) {
        setSelectedSender(options.senders.find((s) => s.id === emailSender) ?? null);
      }
      if (template) {
        setSelectedTemplate(options.templates.find((t) => t.id === template) ?? null);
      }
    },
    onError: ({ response }) => handleRequestError(dispatch, response),
    staleTime: 1 * 60 * 1000
  });
  const [selectedSender, setSelectedSender] = useState<Sender | null>(null);
  const [senderOptions, setSenderOptions] = useState<Sender[]>(
    emailOptionsQuery.data?.data.senders ?? []
  );
  const [tempalteDetails, setTempalteDetails] = useState<ITemplateDetails | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<ITemplate | null>(null);
  const [templateOptions, setTemplateOptions] = useState<ITemplate[]>(
    emailOptionsQuery.data?.data.templates ?? []
  );
  const [openTest, setOpenTest] = useState(false);
  const templateWidth = '100%';

  useEffect(() => {
    if (emailSender) {
      setSelectedSender(senderOptions.find((s) => s.id === emailSender) ?? null);
    }
    if (template) {
      setSelectedTemplate(templateOptions.find((t) => t.id === template) ?? null);
    }
  }, [senderOptions, templateOptions]);

  const templateDetailsQuery = useMutation({
    mutationKey: [QueryKey.TemplateDetails, selectedTemplate?.id],
    mutationFn: () => TemplateDetailsQuery({ brandId, id: template as string }),
    onSuccess: ({ data }) => {
      setTempalteDetails(data);
    }
  });

  const sendTestEmail = useMutation(SendPromoTestEmail, {
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const handleSenderChange = (input: SelectChangeEvent<string[]>) => {
    const { value } = input.target;
    const newSender = senderOptions.find((s) => s.fromName === (value as string)) as Sender;
    setEmailSender(newSender.id);
    setSelectedSender(newSender);
  };

  const handleTemplateChange = (_: unknown, newValue: unknown) => {
    if (newValue) {
      const newTemplate = templateOptions.find((s) => s.name === (newValue as string)) as ITemplate;
      setTemplate(newTemplate.id);
      setSelectedTemplate(newTemplate);
    } else {
      setTemplate(null);
    }
  };

  // const handleDesktopButtonClick = () => {
  //   setTemplateWidth('100%');
  // };

  // const handleMobileButtonClick = () => {
  //   setTemplateWidth('360px');
  // };

  useEffect(() => {
    if (template) {
      console.log('template: ', template);
      templateDetailsQuery.mutate();
    } else {
      setTempalteDetails(null);
    }
  }, [template]);

  const handleSendTest = (email: string) => {
    setOpenTest(false);
    dispatch({
      type: Actions.ShowMessage,
      payload: {
        severity: 'info',
        text: `Email sent to ${email}`
      }
    });
    const emailReq = {
      brandId,
      sender: selectedSender,
      template: selectedTemplate,
      emailTo: email
    } as PromoTestEmail;
    sendTestEmail.mutate(emailReq);
  };

  const handleOpenTest = () => {
    if (emailSender && template) {
      setOpenTest(true);
    } else {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'warning',
          text: 'You should select sender and template'
        }
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: '24px',
        mb: '24px'
      }}
    >
      {!isPreview && (
        <>
          <SelectComponent
            label="Sender Name"
            value={[selectedSender?.fromName ?? '']}
            isSingle
            onChange={handleSenderChange}
            options={senderOptions.map((s) => s.fromName)}
            hideNoneValue
          />
          <InputComponent
            id="sender-email"
            value={selectedSender?.fromEmail ?? ''}
            disabled
            label="Sender email"
            onChange={() => true}
          />
          <Divider sx={{ gridColumn: 'span 2' }} />
          <AutocompleteComponent
            id="template-autocomplete"
            value={selectedTemplate?.name ?? ''}
            onChange={handleTemplateChange}
            options={templateOptions.map((t) => t.name)}
            label="Template"
          />
          <InputComponent
            id="template-subject"
            value={tempalteDetails?.versions.find((v) => v.active === true)?.subject ?? ''}
            disabled
            label="Subject"
            onChange={() => true}
          />
          <Divider sx={{ gridColumn: 'span 2' }} />
        </>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gridColumn: 'span 2'
        }}
      >
        <Button variant="outlined" sx={{ width: '80px' }} onClick={handleOpenTest}>
          Test
        </Button>
      </Box>
      <Box
        sx={{
          gridColumn: 'span 2',
          maxWidth: templateWidth,
          overflow: 'auto',
          width: '100%',
          margin: '0 auto'
        }}
      >
        <HtmlPreview
          htmlContent={tempalteDetails?.versions.find((v) => v.active === true)?.htmlContent ?? ''}
        />
      </Box>
      <Loader loading={emailOptionsQuery.isLoading || templateDetailsQuery.isLoading} />
      {openTest && <TestEmailForm onClose={() => setOpenTest(false)} handleSend={handleSendTest} />}
    </Box>
  );
};

export default EmailContent;

import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, Tooltip } from '@mui/material';
import Header from '../Common/Layout/Header';
import AddButton from '../Common/Buttons/AddButton';
import {
  CalendarViewWeek,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  TableRowsOutlined
} from '@mui/icons-material';
import CardsView from './FilterCard/CardsView';
import KanbanView from './Kanban/KanbanView';
import FilterCardForm from './FilterCard/FilterCardForm';
import StageForm from './Kanban/StageForm';
import { FilterCardEnums } from '../../enums/FilterCardEnums';
import { playerTemplateHeaders } from '../../models/fakeDb';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { IPlayer, PlayerKanbanStage } from '../../models/player';
import PlayerUpload from './PlayerUpload';
import { getBrandDisplayName, getBrandId } from '../../utils/ui';
import { useQuery } from '@tanstack/react-query';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';
import {
  GetBrandPlayersQuery,
  GetPlayersKanbanStages,
  GetPlayersPartialQuery
} from '../../queries/players';
import Loader from '../Common/Global/Loader';
import { IKanbanStage, PlayerKanbanItem } from '../../models/global';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

type StageItems = {
  [key: string]: PlayerKanbanItem[];
};

const index: React.FunctionComponent = () => {
  const { dispatch, state } = useContext(AppContext);
  const brand = getBrandDisplayName(state.selectedBrand);
  const brandId = getBrandId(state.selectedBrand);
  const [refetchPartial, setRefetchPartial] = useState(true);
  const playersPartialQuery = useQuery({
    queryKey: [QueryKey.PlayersPartial, brandId],
    queryFn: () => GetPlayersPartialQuery(brand),
    onSuccess: ({ data }) => {
      setPlayers(data);
    },
    refetchInterval: 1 * 60 * 1000,
    enabled: refetchPartial
  });
  const partialCount = playersPartialQuery.data?.data.length === 1000;
  const playersQuery = useQuery({
    queryKey: [QueryKey.Players, brandId],
    queryFn: () => GetBrandPlayersQuery(brand),
    onSuccess: ({ data }) => {
      setPlayers(data);
      setRefetchPartial(false);
    },
    enabled: partialCount,
    refetchInterval: 1 * 60 * 1000
  });
  const getKanbanStages = useQuery({
    queryKey: [QueryKey.KanbanStages, brandId],
    queryFn: GetPlayersKanbanStages,
    onSuccess: ({ data }) => {
      setKanbanStages(data);
    },
    refetchInterval: 1 * 60 * 1000
  });
  const tempalteUrl = URL.createObjectURL(new Blob([playerTemplateHeaders], { type: 'text/csv' }));
  const [players, setPlayers] = useState<IPlayer[]>([]);
  const [kanbanStages, setKanbanStages] = useState<PlayerKanbanStage[] | []>([]);
  const [isKanbanView, setIsKanbanView] = useState(false);
  const [openFilterCardForm, setOpenFilterCardForm] = useState(false);
  const [openKanbanStageForm, setOpenKanbanStageForm] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFilterCardOption, setSelectedFilterCardOption] = useState('');
  const [filePlayers, setFilePlayers] = useState<IPlayer[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [stages, setStages] = useState<IKanbanStage[]>([]);
  const [selectedKanbanStage, setSelectedKanbanStage] = useState<PlayerKanbanStage | null>(null);

  useEffect(() => {
    if (playersQuery.data?.data && playersQuery.data.data.length > 0) {
      setPlayers(playersQuery.data.data);
    } else if (playersPartialQuery.data?.data && playersPartialQuery.data.data.length > 0) {
      setPlayers(playersPartialQuery.data.data);
    }
  }, []);

  useEffect(() => {
    if (getKanbanStages.data?.data && getKanbanStages.data?.data.length > 0) {
      setKanbanStages(getKanbanStages.data?.data);
    }
  }, [getKanbanStages.data?.data]);

  useEffect(() => {
    const items: StageItems = kanbanStages.reduce((acc, stage) => {
      acc[stage.stageName] = [];
      return acc;
    }, {});
    players?.map((p) =>
      items[p.stage]?.push({
        id: p.id,
        name: `${p.firstName} ${p.lastName}`,
        vipStatus: p.vipStatus,
        withdraw: p.withdrawalAmount ?? 0,
        bets: 340.3,
        firstName: p.firstName,
        lastName: p.lastName,
        currency: p.currency,
        deposit: p.totalDepositAmount ?? 0,
        registered: p.registered,
        email: p.email
      } as PlayerKanbanItem)
    );

    const result = kanbanStages.map((stage) => ({
      id: stage.id,
      title: stage.stageName,
      items: items[stage.stageName].sort((a, b) => dayjs(a.registered).diff(dayjs(b.registered))),
      icon: null,
      isDeletable: stage.isDeletable,
      orderNumber: stage.orderNumber
    })) as IKanbanStage[];
    setStages(result);
  }, [players, kanbanStages]);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // useEffect(() => {
  //   setPlayers(palyersQuery.data?.data ?? []);
  // }, [palyersQuery.data]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePlayerKanbanStageEdit = (kanbanStage: PlayerKanbanStage) => {
    if (kanbanStage) {
      setSelectedKanbanStage(kanbanStage);
      setOpenKanbanStageForm(true);
    }
  };

  const handleFilterCardsOptionClick = (option: string) => {
    setSelectedFilterCardOption(option);
    setAnchorEl(null);
  };

  const handleOpenForm = () => {
    setOpenFilterCardForm(!isKanbanView);
    setOpenKanbanStageForm(isKanbanView);
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event && event.target && event.target.files) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e) => {
          if (e && e.target && e.target.result) {
            const rows = (e.target.result as string).split('\r\n');
            if (rows.length > 2) {
              const headers = playerTemplateHeaders.split(',');
              const data = rows.slice(1).map((row) => {
                const values = row.split(',');
                return headers.reduce((obj, key, i) => ({ ...obj, [key]: values[i] }), {});
              });
              const raw = data as IPlayer[];
              const filePlayers = raw
                .filter((p) => p.id.length > 0)
                .map((p) => {
                  return {
                    ...p,
                    brand,
                    firstName: p.firstName ?? '',
                    lastName: p.lastName ?? ''
                  };
                });
              setFilePlayers(filePlayers);
              setShowUpload(true);
            } else {
              dispatch({
                type: Actions.ShowMessage,
                payload: {
                  severity: 'info',
                  text: 'no players to upload'
                }
              });
            }
          }
        };
      }
    }
    event.target.value = '';
  };

  const filterOptions = Object.values(FilterCardEnums);

  const handleStageFormClose = () => {
    setOpenKanbanStageForm(false);
    setSelectedKanbanStage(null);
  };

  return (
    <Box className="nav-page">
      <Box sx={{ display: 'flex', flexFlow: 'row', gap: '8px' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Header text="Manage Players" />
          <Header
            text="Monitor players and create up to 6 different sets of filters"
            isSubHeader={true}
          />
        </Box>
        {!isKanbanView && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* <Box sx={{ mr: '20px' }}>
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
              >
                Filter Cards
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
              >
                {filterOptions.map((option) => (
                  <MenuItem key={option} onClick={() => handleFilterCardsOptionClick(option)}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </Box> */}
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0, gap: '8px' }}>
              <Tooltip title="Download player csv template">
                <a href={tempalteUrl} download="players.csv" target="_blank">
                  <Button variant="outlined" onClick={() => true}>
                    <CloudDownloadOutlined />
                  </Button>
                </a>
              </Tooltip>
              <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0 }}>
                <input
                  id="player-upload"
                  accept="text/csv"
                  type="file"
                  onChange={(e) => handleFileUpload(e)}
                  hidden
                />
                <Tooltip title="Upload Players">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      document.getElementById('player-upload')?.click();
                    }}
                  >
                    <CloudUploadOutlined />
                  </Button>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        )}
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0 }}>
          <Button variant="outlined" onClick={() => setIsKanbanView(!isKanbanView)}>
            {isKanbanView ? <TableRowsOutlined /> : <CalendarViewWeek />}
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 0 }}>
          <AddButton
            id="add-filter-overview-button"
            text={isKanbanView ? '+ Add Stage' : '+ Add Filter'}
            onClick={handleOpenForm}
          />
        </Box>
      </Box>
      {isKanbanView ? (
        <KanbanView handleKanbanStageEdit={handlePlayerKanbanStageEdit} stages={stages} />
      ) : (
        <CardsView
          selectedCardsOption={selectedFilterCardOption}
          openForm={handleOpenForm}
          players={players}
          isLoading={playersQuery.isLoading}
        />
      )}
      {openFilterCardForm && <FilterCardForm onClose={() => setOpenFilterCardForm(false)} />}
      {openKanbanStageForm && (
        <StageForm stageInput={selectedKanbanStage} onClose={handleStageFormClose} />
      )}
      {showUpload && (
        <PlayerUpload
          onClose={() => {
            setShowUpload(false);
            setFilePlayers([]);
          }}
          players={filePlayers}
        />
      )}
      <Loader loading={playersPartialQuery.isLoading} />
    </Box>
  );
};

export default index;

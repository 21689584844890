import { JourneyStage, JourneyStepDb, JourneyStepDbStored } from '../models/journeys';

export const getMilescondsToRefetchSegments = () => {
  const intervalInMinutes = 10;
  const now = new Date();
  const minutes = now.getMinutes();
  const millisecondsInMinute = 60 * 1000;
  const millisecondsInInterval = intervalInMinutes * millisecondsInMinute;
  const timeSinceLastInterval = minutes % intervalInMinutes;
  const timeUntilNextInterval =
    millisecondsInInterval -
    timeSinceLastInterval * millisecondsInMinute -
    now.getSeconds() * 1000 -
    now.getMilliseconds();
  return timeUntilNextInterval;
};

export const cutJourneyStepsTree = (stages: JourneyStage | null) => {
  const result = [] as JourneyStepDb[];
  if (!stages) return result;

  let parent: JourneyStage | null = null;
  const cut = (stage: JourneyStage) => {
    const entry = { ...stage, parentId: parent?.id ?? -1 } as JourneyStepDb;
    result.push(entry);
    const previousParent = parent;
    parent = entry;
    stage.children.forEach((child) => {
      cut(child);
    });
    parent = previousParent;
  };

  cut(stages);

  result.map((s) => {
    delete s.children;
    delete s.parent;
    return s;
  });

  return result;
};

export const restoreJourneyStepsTree = (input: JourneyStepDbStored[]): JourneyStage | null => {
  const steps = input.map((s) => ({ ...s, id: s.treeId, parentId: s.parentTreeId, dbId: s.id }));
  if (steps.length === 0) {
    return null;
  }

  const stepMap = new Map<number, JourneyStage>();
  const root: JourneyStage = { ...steps[0], children: [] };
  stepMap.set(root.id, root);

  for (let i = 1; i < steps.length; i++) {
    const step = steps[i];
    const parent = stepMap.get(step.parentId);
    if (parent) {
      const stage = { ...step, children: [] };
      parent.children.push(stage);
      stepMap.set(stage.id, stage);
    }
  }

  return root;
};

import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, FormControlLabel, IconButton } from '@mui/material';
import { colors } from '../../utils/theme';
import logo from '../../assets/logo.svg';
import InputComponent from '../Common/Fields/InputComponent';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AppContext } from '../../AppContext';
import { AuthRoutes } from '../../enums/RouteEnums';
import { Actions } from '../../enums/ActionEnums';
import { AppConsts } from '../../enums/AppConsts';
import { LoginQuery } from '../../queries/account';
import { isPasswordValid } from '../../utils/validators';
import { Email, Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { getIconColor } from '../../utils/ui';
import Loader from '../Common/Global/Loader';
import ForgotPasswordPage from './ForgotPasswordPage';
import MainButton from '../Common/Buttons/MainButton';

type IErrors = {
  email: string | null;
  password: string | null;
};

const defaultErrorsObj: IErrors = {
  email: null,
  password: null
};

const Login: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useContext(AppContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [visiblePass, setVisiblePass] = useState(false);
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const [openResetPass, setOpenResetPass] = useState(false);
  const { mutate, isLoading } = useMutation(LoginQuery, {
    onSuccess: ({ data }) => {
      dispatch({
        type: Actions.SetUser,
        payload: {
          token: data.accessToken,
          data
        }
      });
      dispatch({
        type: Actions.SetSelectedBrand,
        payload: {
          brand: data.brands[0]
        }
      });
      localStorage.setItem(AppConsts.AccessToken, data.accessToken);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      navigate(AuthRoutes.Home);
    },
    onError: ({ response }) => {
      const message = response.status === 400 ? response.data.error : 'An error occurred';
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          autoHide: 6000,
          severity: 'error',
          text: message
        }
      });
    }
  });

  useEffect(() => {
    if (state.user.data) {
      navigate(AuthRoutes.Home);
    }
  }, []);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (value.length < 2) {
      setFormErrors({ ...formErrors, email: 'Email not valid!' });
    } else {
      setFormErrors({ ...formErrors, email: null });
    }
    setEmail(value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({ ...formErrors, password: 'Password not valid' });
    } else {
      setFormErrors({ ...formErrors, password: null });
    }
    setPassword(value);
  };

  const isPageValid = () => {
    if (email.length < 2) {
      return false;
    }
    if (!isPasswordValid(password)) {
      return false;
    }

    return true;
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isPageValid()) {
      mutate({ email, password });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        minHeight: '100vh',
        width: '100%',
        background: '#CBCCE3',
        mt: '-80px',
        backgroundImage: `url(${require('../../assets/login.png')})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <Box
          className="centralize"
          sx={{
            width: '75%',
            borderRadius: '100px',
            border: `1px solid ${colors.white}`,
            boxShadow: colors.bohShadows.grey,
            backdropFilter: colors.backdropFilter,
            background: colors.grey.secondary,
            padding: '11px 0'
          }}
        >
          <Box component="img" src={logo} alt="logo" sx={{ width: 'auto' }} />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { md: 'left', xs: 'center' },
          paddingX: { md: '19.84%', xs: '0' },
          paddingTop: '10.1%'
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{
            borderRadius: '48px',
            border: `1px solid ${colors.white}`,
            boxShadow: colors.bohShadows.grey,
            backdropFilter: colors.backdropFilter,
            background: colors.grey.secondary,
            padding: '48px',
            width: '506px'
          }}
        >
          <Box className="centralize" sx={{ gap: '40px', flexFlow: 'column' }}>
            <Box
              sx={{
                padding: '0 24px 30px 24px',
                color: colors.gradients.pinkRgb,
                borderBottom: `2px solid ${colors.gradients.pinkRgb}`,
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '34px'
              }}
            >
              Login
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: '24px',
                flexFlow: 'column',
                width: '100%'
              }}
            >
              <InputComponent
                id="login-email"
                label="Email"
                placeholder="Enter your email address"
                value={email}
                onChange={handleEmailChange}
                disabled={isLoading}
                errorMsg={formErrors.email}
                startIcon={
                  <Email
                    sx={{
                      fill: getIconColor(email, formErrors.email),
                      zIndex: 2
                    }}
                  />
                }
              />
              <InputComponent
                id="login-password"
                label="Password"
                type={visiblePass ? 'text' : 'password'}
                placeholder="Enter password"
                value={password}
                onChange={handlePasswordChange}
                disabled={isLoading}
                errorMsg={formErrors.password}
                startIcon={
                  <Lock
                    sx={{
                      fill: getIconColor(password, formErrors.password),
                      zIndex: 2
                    }}
                  />
                }
                endIcon={
                  <IconButton
                    aria-label="toggle new password visibility"
                    onClick={() => setVisiblePass(!visiblePass)}
                    sx={{ marginRight: '-8px' }}
                  >
                    {visiblePass ? (
                      <VisibilityOff
                        sx={{
                          fill: getIconColor(password, formErrors.password),
                          zIndex: 2
                        }}
                      />
                    ) : (
                      <Visibility
                        sx={{
                          fill: getIconColor(password, formErrors.password),
                          zIndex: 2
                        }}
                      />
                    )}
                  </IconButton>
                }
              />
              <Box className="centralize">
                <Box
                  sx={{
                    flexGrow: 1
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox value="remember" color="info" sx={{ marginRight: '-5px' }} />
                    }
                    label="Remember me"
                    sx={{
                      color: 'primary.main',
                      fontWeight: 400,
                      fontSize: '14px !important',
                      lineHeight: '20px'
                    }}
                  />
                </Box>
                <Box
                  onClick={() => setOpenResetPass(true)}
                  sx={{
                    cursor: 'pointer',
                    fontWeight: 700,
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: colors.gradients.pinkRgb
                  }}
                >
                  Forgot Your Password?
                </Box>
              </Box>
            </Box>
            <MainButton id="login-button" isSubmit={true} text="Login" />
          </Box>
        </Box>
      </Box>
      {openResetPass && <ForgotPasswordPage handleClose={() => setOpenResetPass(false)} />}
      <Loader loading={isLoading} />
    </Box>
  );
};

export default Login;

import React from 'react';
import { ChartSerie } from '../../models/chart';
import ReactApexChart from 'react-apexcharts';

interface IProps {
  series: ChartSerie[];
  labels: string[];
}

const StackedChart: React.FunctionComponent<IProps> = ({ series, labels }: IProps) => {
  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '13px',
              fontWeight: 900
            }
          }
        }
      }
    },
    xaxis: {
      categories: labels,
      labels: {
        rotate: -45,
        tickPlacement: 'on',
        rotateAlways: true,
        style: {
          colors: '#ACAABA',
          fontSize: '12px',
          fontWeight: 400
        }
      },
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      },
      tooltip: {
        enabled: true
      }
    }
  };

  return <ReactApexChart options={options} series={series} type="bar" height={350} />;
};

export default StackedChart;

import React from 'react';
import { Box, SvgIconTypeMap, SxProps } from '@mui/material';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import './index.css';
import { BarLoader } from 'react-spinners';
import { AccountBalanceRounded } from '@mui/icons-material';

interface IProps {
  title: string;
  percentValue: number;
  trend: 'increasing' | 'decreasing';
  value: string;
  valueLegend: string;
  image?: React.ReactNode;
  sx?: SxProps;
  loading?: boolean;
  pinkValue?: boolean;
  iconBackground?: string;
  hasIcon?: boolean;
  icon?: any;
}

const InfoBox: React.FunctionComponent<IProps> = ({
  title,
  image,
  value,
  valueLegend,
  percentValue,
  trend,
  sx,
  loading = false,
  pinkValue = false,
  iconBackground,
  hasIcon = false,
  icon
}: IProps) => {
  const trendSize = '16px';

  const getTrendIcon = () => {
    if (trend === 'decreasing') {
      return <TrendingDown sx={{ width: trendSize, height: trendSize }} />;
    } else {
      return <TrendingUp sx={{ width: trendSize, height: trendSize }} />;
    }
  };
  const classExt = pinkValue ? '-pink' : '';
  return (
    <Box className="info-box" {...(sx && { sx: { ...sx } })}>
      {image}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '4px', flex: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'start',
            width: '100%'
          }}
        >
          <Box sx={{ display: 'flex', gap: '24px' }}>
            {hasIcon && (
              <Box
                className="info-icon-box"
                sx={{
                  backgroundColor: iconBackground
                }}
              >
                {icon}
              </Box>
            )}
            <Box>
              <Box className="info-box-title">{title}</Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                {loading ? (
                  <BarLoader
                    color="rgb(236,26,88)"
                    height={2}
                    speedMultiplier={1}
                    cssOverride={{ top: '20px' }}
                  />
                ) : (
                  <>
                    <Box className={'info-box-value' + classExt}>{value}</Box>
                    <Box className="info-box-legend">{valueLegend}</Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="info-box-percent-trend"
            sx={{ color: trend === 'decreasing' ? '#EF2929' : '#36CD33  ' }}
          >
            {getTrendIcon()} {percentValue.toFixed(2)}%
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InfoBox;

import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { Search } from '@mui/icons-material';

interface IProps {
  placeholder: string;
  onSearch: (text: string) => void;
}

const SearchComponent: React.FunctionComponent<IProps> = ({ placeholder, onSearch }: IProps) => {
  const [value, setValue] = useState<string | null>(null);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    setValue(text);
    onSearch(text);
  };

  return (
    <TextField
      value={value}
      onChange={handleValueChange}
      placeholder={placeholder}
      sx={{
        // width: { sm: 200, md: 300 },
        '& .MuiInputBase-root': {
          height: '36px'
        }
      }}
      InputProps={{
        endAdornment: <Search sx={{ color: (t) => t.palette.grey[50] }} />
      }}
    />
  );
};

export default SearchComponent;

import { InfoRow } from '../Common/Layout/InfoCard';

export const financialInfoRows = (location, currency) => {
  return [
    {
      title: 'First Deposit Date',
      field: 'firstDepositDate',
      value: location.state.playerCard.firstDepositDate
    },
    {
      title: 'Total deposit amount',
      field: 'totalDeposit',
      value: `${currency}${location.state.playerCard.totalDepositAmount?.toFixed(2) ?? 0}`
    },
    {
      title: 'Withdrawal amount',
      field: 'withdrawalAmount',
      value: `${currency}${location.state.playerCard.withdrawalAmount?.toFixed(2) ?? 0}`
    },
    {
      title: 'Total GGR',
      field: 'totalGgr',
      value: ''
    },
    {
      title: 'Total NGR',
      field: 'totalGgr',
      value: ''
    },
    {
      title: 'Total Bets',
      field: 'totalBets',
      value: ''
    },
    {
      title: 'Total Margin',
      field: 'totalMargin',
      value: ''
    },
    {
      title: 'Total Bonuses',
      field: 'totalBonuses',
      value: ''
    }
  ] as InfoRow[];
};

export const basicRows = (location, playerId) => {
  return [
    {
      title: 'Player ID',
      field: 'id',
      value: playerId
    },
    {
      title: 'First Name',
      field: 'firstName',
      value: location.state.playerCard.firstName
    },
    {
      title: 'Last Name',
      field: 'lastName',
      value: location.state.playerCard.lastName
    },
    {
      title: 'Email',
      field: 'email',
      value: location.state.playerCard.email
    },
    {
      title: 'Phone Number',
      field: 'phoneNumber',
      value: location.state.playerCard.phone
    },
    {
      title: 'Country',
      field: 'country',
      value: location.state.playerCard.country
    },
    {
      title: 'Date of birth',
      field: 'dateOfBirth',
      value: location.state.playerCard.birthDate
    },
    {
      title: 'Address',
      field: 'address',
      value: location.state.playerCard.address
    }
  ] as InfoRow[];
};

import React, { useState } from 'react';
import { Draggable } from '@hello-pangea/dnd';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { KanbanItem } from '../../../models/global';
import {
  MoreVertOutlined,
  FolderOpenOutlined,
  QueryBuilderOutlined,
  Delete,
  Edit,
  Download,
  SignalCellularAlt
} from '@mui/icons-material';

import dayjs from 'dayjs';
import './index.css';

interface IProps {
  item: KanbanItem;
  index: unknown;
}
const dateFormat = 'YYYY/MM/DD, HH:mm:ss';

const KanbanCard: React.FunctionComponent<IProps> = ({ item, index }: IProps) => {
  const [anchorElBrand, setAnchorElBrand] = useState<null | HTMLElement>(null);

  return (
    <Draggable key={item.id} draggableId={item.id.toString()} index={index as number}>
      {(provided) => (
        <Box ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
          <Box className="kanban-card hover-box-border">
            <Box className="kanban-header">
              <Box className="kanban-title">{item.title}</Box>
              <IconButton
                onClick={(e) => setAnchorElBrand(e.currentTarget)}
                sx={{ width: '24px', height: '24px' }}
              >
                <MoreVertOutlined sx={{ color: (t) => t.palette.primary.main }} />
              </IconButton>
              <Menu
                sx={{ mt: '28px' }}
                id="menu-brands"
                anchorEl={anchorElBrand}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                keepMounted
                open={Boolean(anchorElBrand)}
                onClose={() => setAnchorElBrand(null)}
                MenuListProps={{ sx: { p: '4px 0', minWidth: '160px' } }}
              >
                <MenuItem
                  key={`edit-${index}`}
                  onClick={() => {
                    setAnchorElBrand(null);
                    item.handleDetails(item.id);
                  }}
                >
                  <Box className="kanban-item-action-row">
                    <SignalCellularAlt className="kanban-action-icon" /> stats
                  </Box>
                </MenuItem>
                <MenuItem
                  key={`details-${index}`}
                  onClick={() => {
                    setAnchorElBrand(null);
                    item.handleEdit(item.id);
                  }}
                >
                  <Box className="kanban-item-action-row">
                    <Edit className="kanban-action-icon" /> edit
                  </Box>
                </MenuItem>
                <MenuItem
                  key={`delete-${index}`}
                  onClick={() => {
                    setAnchorElBrand(null);
                    item.handleDelete(item.id);
                  }}
                >
                  <Box className="kanban-item-action-row">
                    <Delete className="kanban-action-icon" /> delete
                  </Box>
                </MenuItem>
                <MenuItem
                  key={`download-${index}`}
                  onClick={() => {
                    setAnchorElBrand(null);
                    item.handleDownload(item.id);
                  }}
                >
                  <Box className="kanban-item-action-row">
                    <Download className="kanban-action-icon" /> stats csv
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
            <Box className="kanban-secondary-details">
              <FolderOpenOutlined /> {item.group}
            </Box>
            <Box className="kanban-secondary-details">
              <QueryBuilderOutlined />
              {dayjs(item.startDate).format(dateFormat)} - {dayjs(item.endDate).format(dateFormat)}
            </Box>
          </Box>
        </Box>
      )}
    </Draggable>
  );
};

export default KanbanCard;

import axios from 'axios';
import { baseUrl, getHttpHeaders } from '../utils/rights';
import {
  CreateJourneyGroup,
  JourneyDetailsModel,
  JourneyGroup,
  JourneyModel
} from '../models/journeys';

const controller = 'journey';

export const JourneyGroupsQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/${controller}/GetGroups/${brandId}`, getHttpHeaders());
};

export const CreateJourneyGroupMutation = async (input: CreateJourneyGroup) => {
  const { brandId, name } = input;
  return await axios.post(
    `${baseUrl}/${controller}/createGroup/${brandId}`,
    { input: name },
    getHttpHeaders()
  );
};

export const UpdateJourneyGroupMutation = async (input: JourneyGroup) => {
  return await axios.put(
    `${baseUrl}/${controller}/updateGroup/${input.id}`,
    { input: input.name },
    getHttpHeaders()
  );
};

export const CreateJourneyMutation = async ({
  brandId,
  input
}: {
  brandId: number;
  input: JourneyModel;
}) => {
  return await axios.post(`${baseUrl}/${controller}/create/${brandId}`, input, getHttpHeaders());
};

export const UpdateJourneyMutation = async ({ id, input }: { id: number; input: JourneyModel }) => {
  return await axios.put(`${baseUrl}/${controller}/update/${id}`, input, getHttpHeaders());
};

export const SetJourneyDetailsMutation = async ({
  id,
  input
}: {
  id: number;
  input: JourneyDetailsModel;
}) => {
  return await axios.put(`${baseUrl}/${controller}/SetDetails/${id}`, input, getHttpHeaders());
};

import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { PlayerKanbanItem, IKanbanStage } from '../../../models/global';
import { Actions } from '../../../enums/ActionEnums';
import PlayerKanbanCard from './PlayerCard/PlayerKanbanCard';
import KanbanBoard from '../../Common/Kanban/KanbanBoard';
import { IPlayer, PlayerKanbanStage } from '../../../models/player';
import { AppContext } from '../../../AppContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKey } from '../../../enums/HttpRequestKeyEnums';
import { getBrandId, handleRequestError } from '../../../utils/ui';
import { UpdatePlayerStage } from '../../../queries/players';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface IProps {
  stages: IKanbanStage[];
  handleKanbanStageEdit: (kanbanStage: PlayerKanbanStage) => void;
}

const KanbanView: React.FunctionComponent<IProps> = ({ stages, handleKanbanStageEdit }) => {
  const { state, dispatch } = useContext(AppContext);
  const queryClient = useQueryClient();
  const brandId = getBrandId(state.selectedBrand);

  const updateStage = useMutation(UpdatePlayerStage, {
    onError: ({ response }) => {
      handleRequestError(dispatch, response);
    },
    onSuccess: ({ data }) => {
      const { firstName, lastName } = data as IPlayer;
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'info',
          text: `Player ${firstName} ${lastName} updated`
        }
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries([QueryKey.PlayersPartial, brandId]);
      queryClient.invalidateQueries([QueryKey.Players, brandId]);
    }
  });

  const renderCard = ({ item, index }: { item: any; index: number }): React.ReactNode => {
    return <PlayerKanbanCard key={`card-${index}`} item={item} index={index} />;
  };

  const handleItemStageChange = (sourceName: string, destinationName: string, item: unknown) => {
    if (sourceName !== destinationName) {
      const { id } = item as PlayerKanbanItem;
      updateStage.mutate({
        playerId: id.toString(),
        stage: destinationName
      });
    }
  };

  return (
    <Box>
      <KanbanBoard
        key={`${brandId}-players-kanban`}
        stages={stages}
        handleStageChange={handleItemStageChange}
        renderCard={renderCard}
        handleKanbanStageEdit={handleKanbanStageEdit}
      />
    </Box>
  );
};

export default KanbanView;

import React from 'react';
import { Box } from '@mui/material';
import './StatDetails.scss';

interface IProps {
  key: React.Key | null | undefined;
  name: string;
  value: string;
  handleClick?: (input: string) => void;
  selected?: boolean;
  total?: string;
}

const StatDetails: React.FunctionComponent<IProps> = ({
  key,
  name,
  value,
  handleClick,
  selected = false,
  total
}: IProps) => {
  const getIndicatorColor = () => {
    switch (name) {
      case 'spamreport':
        return 'red';
      case 'delivered':
        return '#6FFECD';
      case 'processed':
        return '#FEB019';
      case 'open':
        return '#775DD0';
      default:
        if (name === 'click' || name.startsWith('https')) {
          return '#008FFB';
        } else {
          return 'grey';
        }
    }
  };

  const generatePercent = (total: string, value: string) => {
    const percent = ((Number(value) / Number(total)) * 100).toFixed(2);
    return `(${percent.replace('.', ',')}%)`;
  };

  return (
    <Box
      key={key}
      className={`stat-container  ${selected ? 'stat-container--clicked' : ''}`}
      onClick={() => (handleClick ? handleClick(name) : true)}
    >
      <Box
        className="stat-indicator"
        sx={{
          backgroundColor: getIndicatorColor()
        }}
      />
      {name} {value} {total ? generatePercent(total, value) : ''}
    </Box>
  );
};

export default StatDetails;

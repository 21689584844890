import { Box, IconButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { AppContext } from '../../AppContext';
import { Actions } from '../../enums/ActionEnums';
import { PublicRoutes } from '../../enums/RouteEnums';
import { ResetPassQuery } from '../../queries/account';
import { isPasswordValid, isValidEmail } from '../../utils/validators';
import DialogBox from '../Common/Layout/DialogBox';
import ModalHeader from '../Common/Layout/ModalHeader';
import InputComponent from '../Common/Fields/InputComponent';
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { getIconColor } from '../../utils/ui';
import CancelButton from '../Common/Buttons/CancelButton';
import MainButton from '../Common/Buttons/MainButton';
import Loader from '../Common/Global/Loader';

type IErrors = {
  password: string | null;
  confirmPassword: string | null;
  passwordsMismatch: string | null;
};

const defaultErrorsObj: IErrors = {
  password: null,
  confirmPassword: null,
  passwordsMismatch: null
};

const ErrorTexts = {
  passwordNotValid: 'Password not valid',
  confirmPasswordNotValid: 'Confirm password not valid',
  passwordsMismatch: 'Password and confirm password do not match'
};

const ResetPassword: React.FunctionComponent = () => {
  const { dispatch } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleConfirmPass, setVisibleConfirmPass] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [formErrors, setFormErrors] = useState(defaultErrorsObj);
  const { mutate, isLoading } = useMutation(ResetPassQuery, {
    onSuccess: () => {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'success',
          text: 'Password successfully changed'
        }
      });
      navigate(PublicRoutes.Login);
    },
    onError: ({ response }) => {
      const message = response.status === 400 ? response.data.error : 'An error occurred';
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          autoHide: 6000,
          severity: 'error',
          text: message
        }
      });
    }
  });

  const handlePasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({
        ...formErrors,
        password: ErrorTexts.passwordNotValid,
        passwordsMismatch: null
      });
    } else {
      if (confirmPass) {
        if (value !== confirmPass) {
          setFormErrors({
            ...formErrors,
            password: null,
            passwordsMismatch: ErrorTexts.passwordsMismatch
          });
        } else {
          setFormErrors({
            ...formErrors,
            password: null,
            passwordsMismatch: null
          });
        }
      } else {
        setFormErrors({
          ...formErrors,
          password: null,
          passwordsMismatch: null
        });
      }
    }
    setPassword(value);
  };

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { value } = event.currentTarget;
    if (!isPasswordValid(value)) {
      setFormErrors({
        ...formErrors,
        confirmPassword: ErrorTexts.confirmPasswordNotValid,
        passwordsMismatch: null
      });
    } else {
      if (password) {
        if (value !== password) {
          setFormErrors({
            ...formErrors,
            confirmPassword: null,
            passwordsMismatch: ErrorTexts.passwordsMismatch
          });
        } else {
          setFormErrors({
            ...formErrors,
            confirmPassword: null,
            passwordsMismatch: null
          });
        }
      } else {
        setFormErrors({
          ...formErrors,
          confirmPassword: null,
          passwordsMismatch: null
        });
      }
    }
    setConfirmPass(value);
  };

  const handleClose = () => {
    navigate(PublicRoutes.Login);
  };

  const handleReset = () => {
    let hasErrors = false;
    const errors: IErrors = {
      password: null,
      confirmPassword: null,
      passwordsMismatch: null
    };

    if (!isPasswordValid(password)) {
      hasErrors = true;
      errors.password = formErrors.password;
    }
    if (!isPasswordValid(confirmPass)) {
      hasErrors = true;
      errors.confirmPassword = formErrors.confirmPassword;
    }
    if (!hasErrors && password !== confirmPass) {
      hasErrors = true;
      errors.passwordsMismatch = formErrors.passwordsMismatch;
    }
    if (hasErrors) {
      setFormErrors(errors);
    } else {
      const parsed = queryString.parse(location.search);
      if (
        parsed.token &&
        parsed.token.length > 0 &&
        parsed.email &&
        isValidEmail(parsed.email as string)
      ) {
        mutate({
          token: parsed.token as string,
          email: parsed.email as string,
          password,
          confirmPassword: confirmPass
        });
      }
    }
  };

  const isFormValid = () => {
    if (isPasswordValid(password) && isPasswordValid(confirmPass) && password === confirmPass) {
      return true;
    }
    return false;
  };

  return (
    <DialogBox width={600} onClose={handleClose}>
      <Box sx={{ display: 'flex', flexFlow: 'column', gap: '24px' }}>
        <ModalHeader
          title="Reset password"
          subtitle="Enter your new password that should be different from previous used."
        />
        <InputComponent
          id="reset-password-new-password"
          label="New Password"
          type={visiblePass ? 'text' : 'password'}
          placeholder="Enter new password"
          value={password}
          onChange={handlePasswordChange}
          disabled={isLoading}
          errorMsg={formErrors.password || formErrors.passwordsMismatch}
          startIcon={
            <Lock
              sx={{
                fill: getIconColor(password, formErrors.password || formErrors.passwordsMismatch),
                zIndex: 2
              }}
            />
          }
          endIcon={
            <IconButton
              aria-label="toggle new password visibility"
              onClick={() => setVisiblePass(!visiblePass)}
              sx={{ marginRight: '-8px' }}
            >
              {visiblePass ? (
                <VisibilityOff
                  sx={{
                    fill: getIconColor(
                      password,
                      formErrors.password || formErrors.passwordsMismatch
                    ),
                    zIndex: 2
                  }}
                />
              ) : (
                <Visibility
                  sx={{
                    fill: getIconColor(
                      password,
                      formErrors.password || formErrors.passwordsMismatch
                    ),
                    zIndex: 2
                  }}
                />
              )}
            </IconButton>
          }
        />
        <InputComponent
          id="reset-password-confirm-password"
          label="Confirm Password"
          type={visibleConfirmPass ? 'text' : 'password'}
          placeholder="Enter new password"
          value={confirmPass}
          onChange={handleConfirmPasswordChange}
          disabled={isLoading}
          errorMsg={formErrors.confirmPassword ?? formErrors.passwordsMismatch}
          startIcon={
            <Lock
              sx={{
                fill: getIconColor(
                  confirmPass,
                  formErrors.confirmPassword || formErrors.passwordsMismatch
                ),
                zIndex: 2
              }}
            />
          }
          endIcon={
            <IconButton
              aria-label="toggle new password visibility"
              onClick={() => setVisibleConfirmPass(!visibleConfirmPass)}
              sx={{ marginRight: '-8px' }}
            >
              {visibleConfirmPass ? (
                <VisibilityOff
                  sx={{
                    fill: getIconColor(
                      confirmPass,
                      formErrors.confirmPassword || formErrors.passwordsMismatch
                    ),
                    zIndex: 2
                  }}
                />
              ) : (
                <Visibility
                  sx={{
                    fill: getIconColor(
                      password,
                      formErrors.confirmPassword || formErrors.confirmPassword
                    ),
                    zIndex: 2
                  }}
                />
              )}
            </IconButton>
          }
        />
        <Box sx={{ display: 'flex', gap: '16px' }}>
          <CancelButton id="reset-password-cancel" text="Cancel" onClick={handleClose} />
          <MainButton
            id="reset-password-send"
            text="Reset Password"
            onClick={handleReset}
            disabled={!isFormValid()}
          />
        </Box>
      </Box>
      <Loader loading={isLoading} />
    </DialogBox>
  );
};

export default ResetPassword;

import React from 'react';
import { Breadcrumbs } from '@mui/material';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link as RouterLink } from 'react-router-dom';
import { IRouteItem } from '../../models/global';

interface IProps {
  current: string;
  items?: IRouteItem[];
}

const BreadcrumbsNav: React.FunctionComponent<IProps> = ({ current, items = [] }: IProps) => {
  return (
    <Breadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon sx={{ color: (t) => t.palette.grey[50] }} fontSize="small" />}
    >
      {items.map((value, index) => {
        return (
          <Link
            key={index}
            color="inherit"
            component={RouterLink}
            to={value.path}
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              color: (t) => t.palette.grey[50]
            }}
            state={{ showDetails: false }}
          >
            {value.name}
          </Link>
        );
      })}
      <Typography
        sx={{
          color: (t) => t.palette.primary.main,
          fontWeight: 400,
          fontSize: '14px'
        }}
      >
        {current}
      </Typography>
    </Breadcrumbs>
  );
};

export default BreadcrumbsNav;

import React, { useContext } from 'react';
import { Box, Grow } from '@mui/material';
import { ArrowDownward } from '@mui/icons-material';
import { JourneyStage } from '../../../../models/journeys';
import './index.css';
import { AppContext } from '../../../../AppContext';
import { Actions } from '../../../../enums/ActionEnums';
import StepIcon from './StepIcon';

interface IProps {
  node: JourneyStage;
  index: number;
}

const JourneyStep: React.FunctionComponent<IProps> = ({ node, index }: IProps) => {
  const { dispatch, state } = useContext(AppContext);

  const setStageSelected = (stage: JourneyStage) => {
    dispatch({
      type: Actions.SetSelectedJourneyStage,
      payload: {
        stage: state.selectedJourneyStage?.id === stage.id ? null : stage
      }
    });
  };

  return (
    <Grow in timeout={500 * (index + 1)}>
      <Box className="step-box">
        <Box
          className={`${
            node.id === state.selectedJourneyStage?.id ? 'step-box-name-selected' : 'step-box-name'
          }   hover-box-border `}
          onClick={() => setStageSelected(node)}
          sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}
        >
          <StepIcon type={node.type} />
          {node.name}
        </Box>
        {node.children && node.children.length > 1 && (
          <Box
            sx={{
              borderLeft: '2px solid #DFDEE7',
              minHeight: '25px'
            }}
          ></Box>
        )}
        {node.children && (
          <Box
            className={node.children.length > 1 ? 'border-step' : ''}
            sx={{
              display: 'flex',
              gap: '8px'
            }}
          >
            {node.children.map((childNode, childIndex) => (
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: '1px'
                  }}
                >
                  <ArrowDownward
                    sx={{
                      color: '#DFDEE7',
                      mt: (node.children?.length ?? 0) > 1 ? '-22px' : '-5px'
                    }}
                  />
                </Box>
                <JourneyStep key={childNode.id} node={childNode} index={index + childIndex} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Grow>
  );
};
export default JourneyStep;

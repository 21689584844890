import React, { useContext, useState } from 'react';
import { Box, Button } from '@mui/material';
import InputComponent from '../../../Common/Fields/InputComponent';
import { AppContext } from '../../../../AppContext';
import { Actions } from '../../../../enums/ActionEnums';
import TestSmsForm from './TestSmsForm';
import { PromoTestSms } from '../../../../models/promotion';
import { getBrandId, handleRequestError } from '../../../../utils/ui';
import { useMutation } from '@tanstack/react-query';
import { SendTestSms } from '../../../../queries/promotion';

interface IProps {
  smsText: string | null;
  setSmsText: (input: string | null) => void;
}

const SmsContent: React.FunctionComponent<IProps> = ({ setSmsText, smsText }: IProps) => {
  const { dispatch, state } = useContext(AppContext);
  const brandId = getBrandId(state.selectedBrand);
  const [content, setContent] = useState(smsText ?? '');
  const [openTest, setOpenTest] = useState(false);

  const sendTestSms = useMutation(SendTestSms, {
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setContent(value);
    setSmsText(value);
  };

  const handleOpenTest = () => {
    if (content.length > 0) {
      setOpenTest(true);
    } else {
      dispatch({
        type: Actions.ShowMessage,
        payload: {
          severity: 'warning',
          text: 'You should fill sms message'
        }
      });
    }
  };

  const handleSendTest = (number: string) => {
    setOpenTest(false);
    dispatch({
      type: Actions.ShowMessage,
      payload: {
        severity: 'info',
        text: `Sms sent to ${number}`
      }
    });
    const smsReq = {
      brandId,
      phoneNumber: number,
      text: content
    } as PromoTestSms;
    sendTestSms.mutate(smsReq);
  };

  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <InputComponent
        id="sms-content"
        label="SMS message"
        value={content}
        onChange={handleContentChange}
        helperText={`Max 1600, recommended 160, ${content.length} used`}
      />
      <Button variant="outlined" sx={{ width: '80px' }} onClick={handleOpenTest}>
        Test
      </Button>
      {openTest && <TestSmsForm onClose={() => setOpenTest(false)} handleSend={handleSendTest} />}
    </Box>
  );
};

export default SmsContent;

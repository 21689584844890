import React, { useContext } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@mui/material';
import { AppContext } from '../../../AppContext';
import { Actions } from '../../../enums/ActionEnums';
import CancelButton from '../Buttons/CancelButton';

const Confirmation = () => {
  const { state, dispatch } = useContext(AppContext);

  const handleAgree = () => {
    if (state.confirmation.agreeAction) {
      state.confirmation.agreeAction(state.confirmation.params);
    }
  };

  return (
    <Dialog
      id="alert-dialog"
      open={state.confirmation.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      BackdropProps={{
        style: {
          background: 'rgba(0, 0, 0, 0.2)',
          backdropFilter: 'blur(4px)'
        }
      }}
    >
      {state.confirmation.title && (
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.info.main}`,
            textAlign: 'center',
            paddingTop: '15px',
            paddingBottom: '13px'
          }}
        >
          <span
            style={{
              margin: 'auto',
              letterSpacing: '-0.01px',
              fontSize: '22px',
              lineHeight: '33px',
              opacity: '1px',
              fontWeight: 'bold',
              width: '100%'
            }}
          >
            {state.confirmation.title}
          </span>
        </DialogTitle>
      )}
      <DialogContent style={{ marginTop: 44, marginLeft: 61, marginRight: 60 }}>
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            color: (theme) => theme.palette.primary.main,
            fontSize: '18px',
            fontWeight: 600
          }}
        >
          {state.confirmation.text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Box className="centralize" sx={{ gap: '10px', m: '20px' }}>
          <Button
            id="yes-dialog-button"
            onClick={handleAgree}
            sx={{ width: '85px' }}
            variant="contained"
            autoFocus
          >
            Yes
          </Button>
          <CancelButton
            id="no-dialog-button"
            onClick={() => dispatch({ type: Actions.HideConfirmation })}
            text="No"
            sx={{ width: '85px' }}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default Confirmation;

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { colors } from '../../utils/theme';
import { ChartSerie } from '../../models/chart';

interface IProps {
  series: ChartSerie;
  markSymbol?: string;
  labels: string[];
}

const LineChart: React.FunctionComponent<IProps> = ({
  series,
  markSymbol = '',
  labels
}: IProps) => {
  const min = Math.min(...series.data);
  const max = Math.max(...series.data);

  const options = {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false
      }
    },
    colors: [colors.gradients.pinkRgb],
    dataLabels: {
      enabled: true,
      offsetY: -10,
      position: 'top',
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: [colors.gradients.pinkRgb]
      },
      background: {
        enabled: false
      },
      formatter: function (val: number) {
        return `${markSymbol}${val}`;
      }
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#ffffff', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
      padding: {
        left: 25,
        right: 30
      }
    },
    markers: {
      size: 8, // Adjust the size as needed
      colors: [colors.gradients.pinkRgb],
      shape: 'circle',
      hover: {
        size: undefined,
        sizeOffset: 2
      }
    },
    xaxis: {
      categories: labels,
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          colors: '#ACAABA',
          fontSize: '12px',
          fontWeight: 400
        }
      }
    },
    yaxis: {
      min: min < 5 ? 0 : min - 5,
      max: max + 10,
      labels: {
        style: {
          colors: '#ACAABA',
          fontSize: '10px',
          fontWeight: 400
        }
      }
    }
  };

  return <ReactApexChart options={options} series={[series]} type="line" height={350} />;
};

export default LineChart;

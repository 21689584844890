import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import { colors } from '../../../utils/theme';

interface IProps {
  title: string;
  subtitle: string;
  onClose?: () => void;
}

const ModalHeader: React.FunctionComponent<IProps> = ({ title, subtitle, onClose }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', flexFlow: 'row', alignItems: 'start' }}>
        <Box
          sx={{
            flexGrow: 1,
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '34px',
            color: colors.primary.main
          }}
        >
          {title}
        </Box>
        <Box sx={{ flexGrow: 0 }}>
          {onClose && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
                margin: '-8px'
              }}
            >
              <Close />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: '8px',
          marginBottom: '12px',
          fontSize: '16px',
          lineHeight: '22px',
          color: colors.grey.subheader
        }}
      >
        {subtitle}
      </Box>
    </Box>
  );
};

export default ModalHeader;

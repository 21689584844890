import axios from 'axios';
import { ICard, IFilterCard } from '../models/card';
import { baseUrl, getHttpHeaders } from '../utils/rights';

export const createFilterCardMutation = async ({
  brandId,
  data
}: {
  brandId: number;
  data: ICard;
}) => {
  return await axios.post(`${baseUrl}/FilterCard/create/${brandId}`, data, getHttpHeaders());
};

export const UpdateFilterCardMutation = async ({
  cardId,
  data
}: {
  cardId: number;
  data: ICard;
}) => {
  return await axios.put(`${baseUrl}/FilterCard/update/${cardId}`, data, getHttpHeaders());
};

export const GetFilterCardsQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/FilterCard/GetAll/${brandId}`, getHttpHeaders());
};

export const GetAllAssignedFilterCardsQuery = async (brandId: number) => {
  return await axios.get(`${baseUrl}/FilterCard/GetAllAssigned/${brandId}`, getHttpHeaders());
};

export const GetFilterCardsWithoutAssignedQuery = async (brandId: number) => {
  return await axios.get(
    `${baseUrl}/FilterCard/GetAllWithoutAssigned/${brandId}`,
    getHttpHeaders()
  );
};

export const DeleteFilterCardMutation = async (id: number) => {
  return await axios.delete(`${baseUrl}/FilterCard/delete/${id}`, getHttpHeaders());
};

import { Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { QueryKey } from '../../enums/HttpRequestKeyEnums';
import { getBrandId, handleRequestError } from '../../utils/ui';
import { AppContext } from '../../AppContext';
import { useQuery } from '@tanstack/react-query';
import { EmailLogsQuery } from '../../queries/email';
import CustomTable, { IHeadCell } from '../Common/Tables/CustomTable';
import dayjs from 'dayjs';
import Loader from '../Common/Global/Loader';

const LogsOverview: React.FunctionComponent = () => {
  const { state, dispatch } = useContext(AppContext);
  const fiveMinutes = 5 * 60 * 1000;

  const fnKey = QueryKey.EmailLogs;
  const brandId = getBrandId(state.selectedBrand);
  const emailLogsQuery = useQuery({
    queryKey: [fnKey, brandId],
    queryFn: () => EmailLogsQuery(brandId),
    onSuccess: ({ data }) => {
      setLogs(data);
    },
    refetchInterval: fiveMinutes,
    onError: ({ response }) => handleRequestError(dispatch, response)
  });

  const columns = [
    {
      title: 'Event',
      field: 'event'
    },
    {
      title: 'Email',
      field: 'email'
    },
    {
      title: 'Template',
      field: 'template',
      render: (data) =>
        data.template === 'Untitled Version'
          ? 'Successful Deposit'
          : data.template ?? 'Challenge completed'
    },
    {
      title: 'Template Id',
      field: 'templateId'
    },
    {
      title: 'Timestamp',
      render: (data) => dayjs.unix(data.timestamp).format('YYYY/MM/DD HH:mm:ss')
    }
  ] as IHeadCell[];

  const [logs, setLogs] = useState<[]>(emailLogsQuery.data?.data ?? []);

  return (
    <Box className="nav-page">
      <Box sx={{ pt: '50px' }}>
        <CustomTable toolbarTitle="Log Emails" data={logs} columns={columns} showSearch />
      </Box>
      <Loader loading={emailLogsQuery.isLoading} />
    </Box>
  );
};

export default LogsOverview;

import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import DayTimePicker from '../../../Common/Tables/DayTimePicker/DayTimePicker';
import { DateTimeItem, TimeZone } from '../../../../models/global';
import moment from 'moment-timezone';
import AutocompleteComponent from '../../../Common/Fields/AutocompleteComponent';

interface IProps {
  timezone: TimeZone | null;
  setTimezone: (input: TimeZone | null) => void;
  dateHours: DateTimeItem[];
  setDateHours: (input: DateTimeItem[]) => void;
}

const StepTimeFrame: React.FunctionComponent<IProps> = ({
  timezone,
  setTimezone,
  dateHours,
  setDateHours
}: IProps) => {
  const [timeZones, setTimeZones] = useState<TimeZone[]>([]);

  useEffect(() => {
    // Fetch all time zones from moment-timezone
    const allTimeZones = moment.tz.names();

    // Create an array of objects with timezone and offset
    const timeZonesWithOffset = (allTimeZones as string[]).map(
      (tz) =>
        ({
          timeZone: tz,
          offset: moment.tz(tz).format('Z')
        } as TimeZone)
    );
    setTimeZones(timeZonesWithOffset);
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
      <AutocompleteComponent
        id="time-zones-autocomplete"
        label="Timezone"
        value={timezone}
        options={timeZones}
        getOptionLabel={(option) => `${option.timeZone} (GMT${option.offset})`}
        onChange={(_: any, newValue: any) => {
          setTimezone(newValue);
        }}
      />

      <DayTimePicker dateHours={dateHours} setDateHours={setDateHours} />
    </Box>
  );
};

export default StepTimeFrame;
